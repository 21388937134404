import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { ApprovalMedia } from "../ExternalMediaApprovals";
import { Campaign } from "../../campaigns/Campaign";
import axios from "axios";
import { colors } from "../../constants";

const CampaignName = styled.span`
    display: block;
    color: ${colors.white};
    font-weight: 500;
    font-size: 20px;
    justify-content: center;
`;

const Count = styled.div`
    color: ${colors.white};
    width: 235px;
    justify-content: flex-end;
    display: flex;
    font-weight: 700;
    margin-bottom: 5px;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
`;

const Header = styled.div`
    display: flex;
    align-items: left;
    width: 300px;
`;

const Media = styled.img`
    width: 235px;
    height: 100%;
    border-radius: 8px;
    margin-bottom: 14px;
    object-fit: contain;
`;

const AppIcon = styled.img`
    width: 66px;
    height: 66px;
    margin-right: 15px;
    border-radius: 12px;
`;

const AppMeta = styled.div`
    text-align: left;
    line-height: 1.4em;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const VidContainer = styled.div`
    width: 235px;
    height: 100%;
    background: black;
    border-radius: 8px;
    margin-bottom: 14px;
    overflow: hidden;
    position: relative;
`;

const Video = styled.video`
    display: block;
    width: 235px;
    height: 100%;
    cursor: pointer;
`;

const PlayButton = styled.img`
    position: absolute;
    top: calc(50% - 13px);
    left: calc(50% - 13px);
    width: 26px;
    height: 26px;
`;

interface Props {
    count: string;
    media: ApprovalMedia;
}

const ExternalMediaDetails = ({ media, count }: Props) => {
    const [play, setPlay] = useState(false);
    return (
        <Container>
            <Header>
                <AppIcon src={media.image_url} alt={media.campaign_name} />
                <AppMeta>
                    <CampaignName>{media.campaign_name}</CampaignName>
                </AppMeta>
            </Header>
            <Count>{count}</Count>
            {media.type === "video" ? (
                <VidContainer>
                    <Video
                        style={{ opacity: play ? 1 : 0.65 }}
                        playsInline={true}
                        src={media.media_url}
                        poster={media.cover_photo_url}
                        onClick={(e: any) => {
                            setPlay(!play);
                            const target = e.target as HTMLVideoElement;
                            target.paused ? target.play() : target.pause();
                        }}
                    />
                    {!play && <PlayButton src="/play.svg" alt="" />}
                </VidContainer>
            ) : (
                <Media src={media.media_url} alt="Error Loading Media" />
            )}
        </Container>
    );
};

export default ExternalMediaDetails;
