import React from "react";
import { connect } from "react-redux";
import { User } from "../dashboard/Profile";
import { colors } from "../constants";
import styled from "styled-components/macro";
import { RoundedWhiteButton } from "../components/styledComponents";
import { usersEditModal } from "./actions";

const MainRow = styled.div`
    display: flex;
    font-size: 13px;
`;

const TitleRow = styled(MainRow)`
    border-bottom: 1px solid ${colors.neutral300};
    color: ${colors.darkGray};
`;

const Data = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    padding: 15px 0;
    overflow: hidden;
`;

const ButtonCell = styled.div`
    flex: 0.5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 0;
`;

const EllipsisContainer = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const RedText = styled.span`
    color: ${colors.error900};
`;

interface Props {
    user?: User;
    usersEditModal: (currentUser: User) => void;
}

const UserRow = ({ user, usersEditModal }: Props) => {
    function renderName(user: User) {
        if (user.firstname && user.lastname) {
            return `${user.firstname} ${user.lastname} `;
        } else if (user.firstname) {
            return `${user.firstname} `;
        } else if (user.lastname) {
            return `${user.lastname} `;
        } else {
            return null;
        }
    }

    if (!user) {
        return (
            <TitleRow>
                <Data>
                    <EllipsisContainer>Name</EllipsisContainer>
                </Data>
                <Data>
                    <EllipsisContainer>Email</EllipsisContainer>
                </Data>
                <Data>
                    <EllipsisContainer>Role</EllipsisContainer>
                </Data>
                <div style={{ flex: 0.5 }} />
            </TitleRow>
        );
    } else {
        return (
            <MainRow>
                <Data>
                    <EllipsisContainer>
                        {renderName(user)}
                        {!user.has_claimed && <RedText>(unclaimed)</RedText>}
                    </EllipsisContainer>
                </Data>
                <Data>
                    <EllipsisContainer>{user.email}</EllipsisContainer>
                </Data>
                <Data>
                    <EllipsisContainer>
                        {user.is_admin && "Admin"}
                    </EllipsisContainer>
                </Data>
                <ButtonCell>
                    <RoundedWhiteButton onClick={() => usersEditModal(user)}>
                        Edit
                    </RoundedWhiteButton>
                </ButtonCell>
            </MainRow>
        );
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    usersEditModal: (currentUser: User) => dispatch(usersEditModal(currentUser))
});

export default connect(null, mapDispatchToProps)(UserRow);
