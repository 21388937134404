import { Action } from "../types";
import { GET_ADVERTISER_API_TOKEN } from "./actions";

export interface APIState {
    token?: string;
}

const initialAPIState: APIState = {};

const reducer = (state = initialAPIState, action: Action) => {
    switch (action.type) {
        case GET_ADVERTISER_API_TOKEN.SUCCESS:
            return { ...state, ...action.payload };
        case GET_ADVERTISER_API_TOKEN.FAILURE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default reducer;
