import { combineReducers } from "redux";
import creativesReducer from "./creatives/slice";
import { EntityState } from "@reduxjs/toolkit";
import { Creative } from "./types/Creative";
import { Media } from "./types/Media";
import mediaReducer from "./media/slice";
import { RuleSetResponse } from "./types/challenge/RuleSetResponse";
import ruleSetsReducer from "./rule-set/slice";
import challengesReducer from "./challenges/slice";
import { Challenge } from "./types/challenge/Challenge";
import { Script } from "./types/Script";
import scriptsReducer from "./scripts/slice";
import { Asset } from "./types/challenge/Asset";
import assetsReducer from "./assets/slice";

export interface EntitiesState {
    media: EntityState<Media>;
    creatives: EntityState<Creative>;
    ruleSets: EntityState<RuleSetResponse>;
    challenges: EntityState<Challenge>;
    scripts: EntityState<Script>;
    assets: EntityState<Asset>;
}

const entitiesReducer = combineReducers({
    media: mediaReducer,
    creatives: creativesReducer,
    ruleSets: ruleSetsReducer,
    challenges: challengesReducer,
    scripts: scriptsReducer,
    assets: assetsReducer
});

export default entitiesReducer;
