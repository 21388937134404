import { all, fork } from "redux-saga/effects";
import authSaga from "./auth/saga";
import profileSaga from "./dashboard/saga";
import campaignsSaga from "./campaigns/saga";
import apiSaga from "./reportingAPI/saga";
import usersSaga from "./users/saga";
import creativesSaga from "./creatives/saga";
import ruleSetsSaga from "./rule-set/saga";
import { firestore } from "./FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import challengesSaga from "./challenges/saga";
import scriptsSaga from "./scripts/saga";
import assetsSaga from "./assets/saga";

export async function get<T extends { id: string }>(
    collectionName: string,
    documentId: string
) {
    const documentRef = doc(firestore, collectionName, documentId);

    const snapshot = await getDoc(documentRef);
    const data = snapshot.data() as T;

    if (data) return { ...data, id: snapshot.id };
    return null;
}

export default function* root() {
    yield all([
        fork(authSaga),
        fork(profileSaga),
        fork(campaignsSaga),
        fork(creativesSaga),
        fork(challengesSaga),
        fork(scriptsSaga),
        fork(assetsSaga),
        fork(ruleSetsSaga),
        fork(apiSaga),
        fork(usersSaga)
    ]);
}
