export enum ChallengeStatus {
    draft = "draft",
    live = "live",
    ended = "ended"
}

export interface Challenge {
    id: number;
    name: string;
    status: ChallengeStatus;
    startsAt: string | null;
    endsAt: string | null;
    targetLaunchDate: string | null;
    scripts?: number[];
    creatives?: number[];
}
