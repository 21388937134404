import { all, call, put, takeLeading } from "redux-saga/effects";
import { deleteReq } from "../Api";
import { PayloadAction } from "@reduxjs/toolkit";
import { deleteAsset, deleteSuccess } from "./slice";

function* deleteAssetRequest(
    action: PayloadAction<{ assetId: number; scriptId: number }>
) {
    try {
        const { assetId, scriptId } = action.payload;

        yield call(deleteReq, `/dashboard/api/asset/${assetId}`);

        yield put(deleteSuccess({ assetId, scriptId }));
    } catch (error) {
        console.log(error);
    }
}

export default function* assetsSaga() {
    yield all([takeLeading(deleteAsset, deleteAssetRequest)]);
}
