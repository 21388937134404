import {
    PayloadAction,
    createAction,
    createEntityAdapter,
    createSlice
} from "@reduxjs/toolkit";
import { RuleSetResponse } from "../types/challenge/RuleSetResponse";
import { NormalizedSchema } from "normalizr";
import { GlobalState } from "../reducers";

const ruleSetsAdapter = createEntityAdapter<RuleSetResponse>();

const ruleSetsSlice = createSlice({
    name: "ruleSets",
    initialState: ruleSetsAdapter.getInitialState(),
    reducers: {
        fetchSuccess: (state, action: PayloadAction<RuleSetResponse>) => {
            ruleSetsAdapter.addOne(state, action.payload);
        }
    }
});

const ruleSetsReducer = ruleSetsSlice.reducer;

export const { fetchSuccess } = ruleSetsSlice.actions;
export const fetch = createAction<number>("ruleSets/fetch");

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectById
} = ruleSetsAdapter.getSelectors(
    (state: GlobalState) => state.entities.ruleSets
);

export default ruleSetsReducer;
