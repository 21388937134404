import { Action } from "../types";

export const GET_ADVERTISER_API_TOKEN = {
    REQUEST: "GET_ADVERTISER_API_TOKEN_REQUEST",
    SUCCESS: "GET_ADVERTISER_API_TOKEN_SUCCESS",
    FAILURE: "GET_ADVERTISER_API_TOKEN_FAILURE"
};

export const getAdvertiserAPIToken = (): Action => ({
    type: GET_ADVERTISER_API_TOKEN.REQUEST,
    payload: null
});

export const getAdvertiserAPITokenSuccess = (payload: {
    token: string;
}): Action => ({
    type: GET_ADVERTISER_API_TOKEN.SUCCESS,
    payload
});

export const getAdvertiserAPITokenFailure = (error: string): Action => ({
    type: GET_ADVERTISER_API_TOKEN.FAILURE,
    payload: { error }
});
