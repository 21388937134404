import React, { useState } from "react";
import styled from "styled-components";
import {
    FloatingPortal,
    flip,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions
} from "@floating-ui/react";
import { RedPillButton } from "../../components/styledComponents";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import {
    isContentEmpty,
    serializeContent
} from "../../components/text-editor/utils";
import ButtonLoader from "../../components/ButtonLoader";
import { Descendant } from "slate";
import { patch } from "../slice";
import { colors } from "../../constants";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "../../components/commonStyles";

const ActionBox = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
`;

const ActionButton = styled(RedPillButton)<{ $disabled: boolean }>`
    height: 36px;
    margin-top: 20px;
    align-self: flex-end;
    overflow: hidden;

    ${props =>
        props.$disabled &&
        `
			background-color: ${colors.neutral300};
			cursor: default;
			pointer-events: none;

			&:not([disabled]):hover {
				background-color: ${colors.neutral300};
			}

		`}
`;

const CaretWrapper = styled.div`
    margin-left: 8px;
    ${FlexCentered};
    width: 36px;
    height: 36px;
    border-radius: 18px 0px 0px 18px;
    padding-right: 40px;
    margin-right: -50px;
    pointer-events: all;
    cursor: pointer;

    &:hover {
        background-color: ${colors.primary700};
    }
`;

const UpCaret = styled.img<{ isOpen: boolean }>`
    transform: ${props => (props.isOpen ? "rotate(0turn)" : "rotate(0.5turn)")};
    transition: 0.3s;
`;

const Dropdown = styled.div`
    border: 1px solid ${colors.neutral300};
    font-family: Poppins;
    background-color: ${colors.white};
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 16px 0px;
    border-radius: 18px;
    font-size: 14px;
    font-weight: 500;
    z-index: 4;
`;

const Row = styled.div<{ active: boolean }>`
    height: 36px;
    padding: 0px 16px;
    ${FlexVerticallyCentered};
    cursor: pointer;
    user-select: none;

    &:hover {
        background-color: ${colors.primary100};
        color: ${colors.neutral700};
    }

    color: ${props => !props.active && colors.neutral300};
`;

const RowText = styled.div`
    font-size: 14px;
    text-transform: capitalize;
`;

interface Props {
    editorContent: Descendant[];
    creativeId: number;
}

const CreativeActionBox = ({ editorContent, creativeId }: Props) => {
    const dispatch = useDispatch();
    const patchLoading = useSelector(
        (state: GlobalState) => state.ui.creatives.patchCreativeLoading
    );
    const [action, setAction] = useState<"reject" | "revision_request">(
        "revision_request"
    );
    const [isOpen, setIsOpen] = useState(false);
    const { refs, floatingStyles, context } = useFloating({
        placement: "top-end",
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [offset({ mainAxis: 10 }), shift()]
    });
    const click = useClick(context);
    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss
    ]);

    const handleSubmit = (
        reviewAction: "reject" | "revision_request" | "approve"
    ) => {
        const markdownStr = serializeContent(editorContent);
        const isEmpty = isContentEmpty(markdownStr);

        if (reviewAction === "approve" || !isEmpty) {
            dispatch(
                patch({
                    advertiserMessage: markdownStr,
                    creativeId,
                    reviewAction
                })
            );
        }
    };

    return (
        <ActionBox>
            <ActionButton
                $disabled={
                    patchLoading ||
                    isContentEmpty(serializeContent(editorContent))
                }
                onClick={() => handleSubmit(action)}
            >
                {patchLoading ? (
                    <ButtonLoader />
                ) : (
                    <>
                        {action === "revision_request"
                            ? "Request Revision"
                            : "Reject"}
                        <CaretWrapper
                            ref={refs.setReference}
                            {...getReferenceProps({
                                onClick: e => e.stopPropagation()
                            })}
                        >
                            <UpCaret src={"/white_caret.svg"} isOpen={isOpen} />
                        </CaretWrapper>
                    </>
                )}
            </ActionButton>

            <ActionButton
                $disabled={patchLoading}
                onClick={() => handleSubmit("approve")}
            >
                {patchLoading ? <ButtonLoader /> : "Approve"}
            </ActionButton>
            {isOpen && (
                <FloatingPortal>
                    <Dropdown
                        ref={refs.setFloating}
                        style={floatingStyles}
                        {...getFloatingProps()}
                    >
                        <Row active onClick={() => setAction("reject")}>
                            <RowText>Reject</RowText>
                        </Row>
                        <Row
                            active
                            onClick={() => setAction("revision_request")}
                        >
                            <RowText>Request Revision</RowText>
                        </Row>
                    </Dropdown>
                </FloatingPortal>
            )}
        </ActionBox>
    );
};
export default CreativeActionBox;
