import React from "react";
import styled from "styled-components";
import { ChallengeStatus } from "../../types/challenge/Challenge";
import { colors } from "../../constants";

const Status = styled.div`
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid ${colors.primaryGray};
    border-radius: 4px;
    background-color: ${colors.neutral300};
`;

const Live = styled.div`
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid ${colors.secondaryGreen};
    border-radius: 4px;
    background-color: ${colors.tertiaryGreen};
`;

interface Props {
    status: ChallengeStatus;
}

const ChallengeStatusBadge = ({ status }: Props) => {
    if (status === ChallengeStatus.draft) return <Status>Draft</Status>;
    if (status === ChallengeStatus.live) return <Live>Live</Live>;
    return <Status>Ended</Status>;
};
export default ChallengeStatusBadge;
