import { hot } from "react-hot-loader/root";
import React from "react";
import AppRouter from "./AppRouter";
import "./App.css";
import { Provider } from "react-redux";
import { history } from "./configureStore";

const App = ({ store }: { store: any }) => {
    return (
        <Provider store={store}>
            <AppRouter history={history} />
        </Provider>
    );
};

export default hot(App);
