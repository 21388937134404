import React from "react";
import styled from "styled-components/macro";
import { Creative } from "../../types/Creative";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { selectById } from "../../media/slice";
import { colors } from "../../constants";
import CreativeStatusIcon from "./CreativeStatusIcon";
import moment from "moment";
import { push } from "connected-react-router";
import CreativeIconRow from "./CreativeIconRow";
import { useRouteMatch } from "react-router";

const Main = styled.div<{ borderColor: string; backgroundColor: string }>`
    cursor: pointer;

    border-radius: 20px;
    background-color: ${props => props.backgroundColor};
    border: 1px solid ${props => props.borderColor};
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.08));

    padding: 10px;

    &:hover {
        border-color: ${colors.primary700};
    }
`;

const Media = styled.div`
    position: relative;
    &:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        padding-bottom: calc(16 / 9 * 100%);
    }

    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CoverPhoto = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const Duration = styled.div`
    position: absolute;
    color: ${colors.white};
    bottom: 7px;
    right: 16px;
`;

const Info = styled.div`
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    color: ${colors.neutral700};
    font-size: 10px;
    font-weight: 400;
    font-family: Poppins;

    & > * ~ * {
        margin-top: 4px;
    }
`;

const ChallengeName = styled.div`
    color: ${colors.primary700};

    font-size: 14px;
    font-weight: 500;

    margin-bottom: 4px;
`;

const StyledCreativeStatus = styled(CreativeStatusIcon)`
    position: absolute;
    top: 8px;
    right: 8px;
`;

const PlayIcon = styled.img`
    width: 24px;
    height: 24px;
    z-index: 1;
`;

interface Props {
    creative: Creative;
}

const CreativeCard = ({ creative }: Props) => {
    const dispatch = useDispatch();
    const { url } = useRouteMatch();
    const media = useSelector((state: GlobalState) =>
        selectById(state, creative.media)
    );

    const moreThanFiveDays =
        moment().diff(moment(creative.events[0].createdAt), "days") >= 5;

    const renderStatus = () => {
        switch (creative.advertiserReviewStatus) {
            case "pending":
                return {
                    borderColor: moreThanFiveDays
                        ? colors.error900
                        : colors.neutral300,
                    backgroundColor: colors.neutral0
                };
            case "rejected":
                return {
                    borderColor: colors.neutral300,
                    backgroundColor: colors.error100
                };

            default:
                return {
                    borderColor: colors.neutral300,
                    backgroundColor: colors.primary100
                };
        }
    };

    return (
        <Main
            backgroundColor={renderStatus().backgroundColor}
            borderColor={renderStatus().borderColor}
            onClick={() => dispatch(push(`${url}/${creative.id}`))}
        >
            <Media>
                <PlayIcon src="/play.svg" />
                <CoverPhoto src={media?.coverPhotoUrl} />
                <Duration>
                    {moment
                        .utc(
                            moment
                                .duration(media?.time, "seconds")
                                .asMilliseconds()
                        )
                        .format("mm:ss")}
                </Duration>
                <StyledCreativeStatus
                    status={creative.advertiserReviewStatus}
                    latestEventTime={creative.events[0].createdAt}
                />
            </Media>
            <Info>
                <ChallengeName>{creative.openBuy.name}</ChallengeName>
                <CreativeIconRow
                    imgSrc={creative.campaign.imageUrl}
                    text={creative.campaign.name}
                />
                <CreativeIconRow
                    imgSrc={
                        creative.socialAccount.profilePhotoUrl ||
                        "/account_circle.svg"
                    }
                    text={"@" + creative.socialAccount.username}
                />
            </Info>
        </Main>
    );
};
export default CreativeCard;
