import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    LOGIN_USER,
    loginUserFailure,
    loginUserSuccess,
    SEND_RESET_PASSWORD,
    sendResetPasswordFailure,
    sendResetPasswordSuccess,
    SET_PASSWORD,
    setPasswordFailure,
    setPasswordSuccess,
    SUBMIT_AUTH_EVENT,
    submitAuthEvent,
    SubmitAuthEventParams
} from "./actions";
import { Action } from "../types";
import { post, setToken } from "../Api";
import { push } from "connected-react-router";
import { errorToString } from "../util";
import { submitEvent } from "../dashboard/actions";
import { setFirebaseToken } from "../FirebaseConfig";
import { isAxiosError } from "axios";

function* loginUserRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/dashboard/auth/login_user",
            payload
        );

        if (status === 200) {
            localStorage.setItem("token", data.token);
            localStorage.setItem("firebaseToken", data.firebaseToken);
            localStorage.setItem("userid", data.advertiser_user_id);

            setToken();
            yield call(setFirebaseToken, data.firebaseToken);

            yield put(loginUserSuccess(data.advertiser_user_id));
            yield put(push("/"));
            yield put(submitEvent("advertiser_logged_in"));
        } else {
            yield put(loginUserFailure(data));
        }
    } catch (error) {
        if (isAxiosError(error)) {
            const eventParams: SubmitAuthEventParams = {
                event_extra: {
                    userAgent: navigator.userAgent,
                    errorMessage: error.toString
                        ? error.toString()
                        : errorToString(error)
                }
            };
            yield put(submitAuthEvent(eventParams));
            yield put(loginUserFailure(errorToString(error)));
        }
    }
}

function* setPasswordRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/dashboard/auth/set_password",
            payload
        );

        if (status === 200) {
            localStorage.setItem("token", data.token);
            localStorage.setItem("firebaseToken", data.firebaseToken);
            localStorage.setItem("userid", data.advertiser_user_id);

            setToken();
            yield call(setFirebaseToken, data.firebaseToken);

            yield put(setPasswordSuccess(data.advertiser_user_id));
            yield put(push("/"));
            yield put(submitEvent("advertiser_set_password"));
        } else {
            yield put(setPasswordFailure(data));
        }
    } catch (error) {
        if (isAxiosError(error)) {
            const eventParams: SubmitAuthEventParams = {
                event_extra: {
                    userAgent: navigator.userAgent,
                    errorMessage: error.toString
                        ? error.toString()
                        : errorToString(error)
                }
            };
            yield put(submitAuthEvent(eventParams));
            yield put(setPasswordFailure(errorToString(error)));
        }
    }
}

function* sendResetPasswordRequest({ payload }: Action) {
    try {
        const { status } = yield call(
            post,
            "/dashboard/auth/send_reset_password_url",
            payload
        );

        if (status === 200) {
            yield put(sendResetPasswordSuccess());
        } else {
            yield put(sendResetPasswordFailure());
        }
    } catch (error) {
        if (isAxiosError(error)) {
            const eventParams: SubmitAuthEventParams = {
                event_extra: {
                    userAgent: navigator.userAgent,
                    errorMessage: error.toString
                        ? error.toString()
                        : errorToString(error)
                }
            };
            yield put(submitAuthEvent(eventParams));
            yield put(sendResetPasswordFailure());
        }
    }
}

function* submitAuthEventRequest({ payload }: Action) {
    try {
        yield call(post, "/dashboard/auth/submit_event", {
            ...payload,
            event_name: "advertiser_auth_error"
        });
    } catch (error) {}
}

export default function* authSaga() {
    yield all([
        takeEvery(LOGIN_USER.REQUEST, loginUserRequest),
        takeEvery(SET_PASSWORD.REQUEST, setPasswordRequest),
        takeEvery(SEND_RESET_PASSWORD.REQUEST, sendResetPasswordRequest),
        takeEvery(SUBMIT_AUTH_EVENT, submitAuthEventRequest)
    ]);
}
