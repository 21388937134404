import React from "react";
import styled from "styled-components";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "../../components/commonStyles";
import { ReactComponent as ConceptIcon } from "../../components/icons/concept_icon.svg";
import { colors } from "../../constants";

const Main = styled.div`
    padding: 43px 36px;
    border-radius: 20px;
    ${FlexVerticallyCentered};
    border: 1px dashed ${colors.neutral500};
`;

const Icon = styled.div`
    width: 72px;
    height: 72px;
    border: 1px dashed ${colors.neutral500};
    border-radius: 36px;
    ${FlexCentered};
    color: ${colors.neutral500};
`;

const Info = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 45px;
`;

const Title = styled.div`
    color: ${colors.neutral500};
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
`;

const Description = styled.div`
    color: ${colors.neutral500};
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
`;

interface Props {}

const ConceptEmptyCard = ({}: Props) => {
    return (
        <Main>
            <Icon>
                <ConceptIcon />
            </Icon>
            <Info>
                <Title>You don’t have any concepts</Title>
                <Description>
                    Get started adding your first concept by clicking the add
                    button above
                </Description>
            </Info>
        </Main>
    );
};
export default ConceptEmptyCard;
