import React, { useState } from "react";
import { connect } from "react-redux";
import { inviteNewUser, InviteNewUserParams } from "./actions";
import styled from "styled-components/macro";
import Modal from "../components/Modal";
import { InputBlock, ModalButton } from "../components/styledComponents";

const Main = styled.div`
    padding: 0 30px;
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    font-size: 14px;
    margin-left: 15px;
    user-select: none;
`;

const Checkbox = styled.input`
    margin-left: 6px;
`;

const ButtonContainer = styled.div`
    margin: 15px 0 30px auto;
`;

const InviteModal = ({
    inviteNewUser
}: {
    inviteNewUser: (params: InviteNewUserParams) => void;
}) => {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [admin, setAdmin] = useState(false);

    function emailIsValid(): boolean {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    function allowSubmit() {
        return firstname.length > 0 && lastname.length > 0 && emailIsValid();
    }

    return (
        <Modal>
            <Main>
                <h2>Invite New User</h2>
                <InputBlock
                    placeholder="First name"
                    value={firstname}
                    onChange={(e: any) => setFirstname(e.currentTarget.value)}
                />
                <InputBlock
                    placeholder="Last name"
                    value={lastname}
                    onChange={(e: any) => setLastname(e.currentTarget.value)}
                />
                <InputBlock
                    placeholder="Email"
                    value={email}
                    type="email"
                    onChange={e => setEmail(e.currentTarget.value)}
                />
                <Label>
                    Make admin?
                    <Checkbox
                        type="checkbox"
                        checked={admin}
                        onChange={() =>
                            admin ? setAdmin(false) : setAdmin(true)
                        }
                    />
                </Label>
                <ButtonContainer>
                    <ModalButton
                        disabled={!allowSubmit()}
                        onClick={() => {
                            inviteNewUser({
                                firstname,
                                lastname,
                                email,
                                is_admin: admin
                            });
                        }}
                    >
                        Invite
                    </ModalButton>
                </ButtonContainer>
            </Main>
        </Modal>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    inviteNewUser: (params: InviteNewUserParams) =>
        dispatch(inviteNewUser(params))
});

export default connect(null, mapDispatchToProps)(InviteModal);
