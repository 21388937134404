import { createSelector } from "@reduxjs/toolkit";
import { selectAll, selectEntities } from "./slice";
import { GlobalState } from "../reducers";
import { selectById } from "./slice";
import * as challenges from "../challenges/slice";
import moment from "moment";
import { Creative } from "../types/Creative";

export const getLatestMessageFromAdmin = createSelector(
    selectById,
    creative => {
        let latestAdvertiserMessageEvent;
        const adminMessageEvent = creative?.events.find(
            event =>
                event.eventName ===
                "open_buy_submission_advertiser_review_request"
        );
        const advertiserMessageEvent = creative?.events.find(
            event =>
                event.eventName ===
                "open_buy_submission_advertiser_revision_request"
        );
        if (
            moment(advertiserMessageEvent?.createdAt) >
            moment(adminMessageEvent?.createdAt)
        ) {
            latestAdvertiserMessageEvent = advertiserMessageEvent;
        }
        return { adminMessageEvent, latestAdvertiserMessageEvent };
    }
);

export const getAllMessageEvents = createSelector(selectById, creative => {
    return creative?.events.filter(
        event =>
            event.eventName ===
                "open_buy_submission_advertiser_review_request" ||
            "open_buy_submission_advertiser_revision_request"
    );
});

export const getFilteredCreatives = createSelector(
    challenges.selectById,
    selectEntities,
    (state: GlobalState) => state.ui.creatives.statusFilter,
    (state: GlobalState) => state.ui.creatives.challengeFilter,
    (openBuy, creativeEntities, statusFilter, challengeFilter) => {
        if (openBuy && openBuy.creatives) {
            const allCreatives = openBuy.creatives.map(
                creativeId => creativeEntities[creativeId]
            ) as Creative[];

            return allCreatives.filter(
                creative =>
                    creative !== undefined &&
                    statusFilter[creative.advertiserReviewStatus] &&
                    challengeFilter[creative.openBuy.name]
            );
        }

        return [];
    }
);
