import React from "react";
import styled from "styled-components/macro";
import SimpleLoader from "./SimpleLoader";

const Main = styled.div<{ center?: Boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    //position above everything and center
    ${(props: any) =>
        props.center &&
        `
        z-index: 999;
        position: fixed;
        top: calc(50% - 62.5px);
        left calc(50% - 43.5px);
    `}
`;

const RocketLoader = ({ center }: { center?: Boolean }) => {
    return (
        <Main center={center}>
            <SimpleLoader />
        </Main>
    );
};

export default RocketLoader;
