import { Action } from "../types";

export const LOGIN_USER = {
    REQUEST: "LOGIN_USER_REQUEST",
    SUCCESS: "LOGIN_USER_SUCCESS",
    FAILURE: "LOGIN_USER_FAILURE"
};

export interface LoginUserParams {
    email: string;
    password: string;
}

export const loginUser = (loginUserParams: LoginUserParams): Action => ({
    type: LOGIN_USER.REQUEST,
    payload: loginUserParams
});

export const loginUserSuccess = (userid: number): Action => ({
    type: LOGIN_USER.SUCCESS,
    payload: { userid }
});

export const loginUserFailure = (error: string): Action => ({
    type: LOGIN_USER.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const SET_PASSWORD = {
    REQUEST: "SET_PASSWORD_REQUEST",
    SUCCESS: "SET_PASSWORD_SUCCESS",
    FAILURE: "SET_PASSWORD_FAILURE"
};

export interface SetPasswordParams {
    token: string;
    password: string;
}

export const setPassword = (setPasswordParams: SetPasswordParams): Action => ({
    type: SET_PASSWORD.REQUEST,
    payload: setPasswordParams
});

export const setPasswordSuccess = (userid: number): Action => ({
    type: SET_PASSWORD.SUCCESS,
    payload: { userid }
});

export const setPasswordFailure = (error: string): Action => ({
    type: SET_PASSWORD.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const SEND_RESET_PASSWORD = {
    REQUEST: "SEND_RESET_PASSWORD_REQUEST",
    SUCCESS: "SEND_RESET_PASSWORD_SUCCESS",
    FAILURE: "SEND_RESET_PASSWORD_FAILURE"
};

export interface SendResetPasswordParams {
    email: string;
}

export const sendResetPassword = (
    sendResetPasswordParams: SendResetPasswordParams
): Action => ({
    type: SEND_RESET_PASSWORD.REQUEST,
    payload: sendResetPasswordParams
});

export const sendResetPasswordSuccess = (): Action => ({
    type: SEND_RESET_PASSWORD.SUCCESS,
    payload: null
});

export const sendResetPasswordFailure = (): Action => ({
    type: SEND_RESET_PASSWORD.FAILURE,
    payload: null
});

// -------------------------------------------------------------------

export const SUBMIT_AUTH_EVENT = "SUBMIT_AUTH_EVENT";

export interface SubmitAuthEventParams {
    event_extra?: any;
}

export const submitAuthEvent = (params: SubmitAuthEventParams): Action => ({
    type: SUBMIT_AUTH_EVENT,
    payload: params
});
