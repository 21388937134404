import { all, call, put, takeLeading } from "redux-saga/effects";
import { get } from "../Api";
import { fetch, fetchSuccess } from "./slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";

function* fetchChallengesRequest(
    action: PayloadAction<{ onRootPage?: boolean }>
) {
    try {
        const { onRootPage } = action.payload;

        const { data } = yield call(get, "/dashboard/api/open_buy", {});

        yield put(fetchSuccess(data));

        // redirect to challenge page since we have no list page (MainContent) for challenges
        if (onRootPage && data.length > 0) {
            yield put(push(`/challenge/${data[0].id}/concepts`));
        }
    } catch (error) {}
}

export default function* challengesSaga() {
    yield all([takeLeading(fetch, fetchChallengesRequest)]);
}
