import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { removeNotification } from "./actions";
import styled from "styled-components/macro";

interface StyleProps {
    active: boolean;
}

const Container = styled.div<StyleProps>`
    position: fixed;
    top: ${props => (props.active ? "86px" : "-50px")};
    left: 0;
    right: 0;
    margin-left: 220px;
    display: flex;
    justify-content: center;
    z-index: 30;
    user-select: none;
    pointer-events: none;
    transition: top 0.5s;
`;

const Pill = styled.div`
    height: 40px;
    border-radius: 20px;
    font-size: 13px;
    color: white;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 28px;
`;

interface Props {
    notification: string | null;
    removeNotification: () => void;
}

const Notification = ({ notification, removeNotification }: Props) => {
    useEffect(() => {
        let id: any;
        if (notification) {
            id = setTimeout(() => {
                removeNotification();
            }, 5000);
        }

        return () => clearTimeout(id);
    }, [notification]);

    const [active, setActive] = useState(false);

    useEffect(() => {
        let id: any;
        if (notification) {
            setActive(true);
            id = setTimeout(() => {
                setActive(false);
            }, 3000);
        }

        return () => clearTimeout(id);
    }, [notification]);

    if (notification) {
        return (
            <Container active={active}>
                <Pill>{notification}</Pill>
            </Container>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state: GlobalState) => ({
    notification: state.profile.notification
});

const mapDispatchToProps = (dispatch: any) => ({
    removeNotification: () => dispatch(removeNotification())
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
