import { all, call, put, takeLeading } from "redux-saga/effects";
import { deleteReq, get, patch, post } from "../Api";
import {
    CreateScriptAction,
    ScriptAssetNormalizedEntities,
    UpdateScriptAction,
    create,
    createSuccess,
    deleteScript,
    deleteSuccess,
    fetch,
    fetchSuccess,
    update,
    updateSuccess
} from "./slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { script, scriptList } from "./schema";

function* createScriptRequest(action: PayloadAction<CreateScriptAction>) {
    try {
        const { openBuyId, mediaUploadData, text, title } = action.payload;

        const { data } = yield call(post, "/dashboard/api/script", {
            openBuyId,
            mediaUploadData,
            title,
            text
        });

        const normalized = normalize<any, ScriptAssetNormalizedEntities>(
            data.data,
            script
        );

        yield put(createSuccess({ openBuyId, normalized }));
    } catch (error) {
        console.log(error);
    }
}

function* fetchScriptsRequest(action: PayloadAction<{ openBuyId: number }>) {
    try {
        const { openBuyId } = action.payload;

        const { data } = yield call(get, "/dashboard/api/script", {
            openBuyId
        });

        const normalized = normalize<any, ScriptAssetNormalizedEntities>(
            data.data,
            scriptList
        );

        yield put(fetchSuccess({ openBuyId, normalized }));
    } catch (error) {
        console.log(error);
    }
}

function* deleteScriptRequest(action: PayloadAction<{ scriptId: number }>) {
    try {
        const { scriptId } = action.payload;

        yield call(deleteReq, `/dashboard/api/script/${scriptId}`);

        yield put(deleteSuccess({ scriptId }));
    } catch (error) {
        console.log(error);
    }
}

function* updateScriptRequest(action: PayloadAction<UpdateScriptAction>) {
    try {
        const { id, mediaUploadData, text, title } = action.payload;

        const { data } = yield call(patch, `/dashboard/api/script/${id}`, {
            title,
            text,
            mediaUploadData
        });

        const normalized = normalize<any, ScriptAssetNormalizedEntities>(
            data.data,
            script
        );

        yield put(updateSuccess(normalized));
    } catch (error) {
        console.log(error);
    }
}

export default function* scriptsSaga() {
    yield all([
        takeLeading(create, createScriptRequest),
        takeLeading(fetch, fetchScriptsRequest),
        takeLeading(deleteScript, deleteScriptRequest),
        takeLeading(update, updateScriptRequest)
    ]);
}
