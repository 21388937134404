import styled, { keyframes, css } from "styled-components/macro";

const animation = keyframes`
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
`;

export const Shimmer = css`
    background: #f3f6f7
        linear-gradient(
            to right,
            #f3f6f7 0%,
            #eef1f2 20%,
            #f3f6f7 40%,
            #f3f6f7 100%
        )
        no-repeat;
    background-size: 800px 1200px;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${animation};
    animation-timing-function: linear;
`;

export const TitleLoading = styled.div`
    ${Shimmer}
    height: 24px;
    border-radius: 6px;
    width: 100px;
`;

export const SubtitleLoading = styled.div`
    ${Shimmer}
    height: 20px;
    margin-top: 4px;
    border-radius: 6px;
    width: 80px;
`;

export const TextLoading = styled.div<{ width?: number; height?: number }>`
    ${Shimmer}
    width: ${props => (props.width ? props.width : 100)}%;
    height: ${props => (props.height ? props.height : 20)}px;
    border-radius: 6px;
`;

export const MediaLoading = styled.div<{ margin?: number }>`
    ${Shimmer}
    width: 106px;
    height: 192px;
    border-radius: 10px;
    margin: ${props => (props.margin !== undefined ? props.margin : 5)}px;
`;

export const CustomLoading = styled.div<{
    width?: string;
    height?: string;
    borderRadius?: string;
}>`
    ${Shimmer}
    width: ${props => (props.width ? props.width : "100%")};
    height: ${props => (props.height ? props.height : "20px")};
    border-radius: ${props =>
        props.borderRadius ? props.borderRadius : "6px"};
`;
