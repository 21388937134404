import React from "react";
import styled from "styled-components/macro";
import { colors, container } from "../constants";

const Container = styled.div`
    padding: 25px 20px 30px;
    ${container};
`;

const Title = styled.div`
    margin-bottom: 15px;
`;

const Description = styled.div`
    margin-bottom: 15px;
    font-size: 13px;
`;

const Link = styled.a`
    font-size: 13px;
    color: ${colors.linkBlue};
`;

const FAQ = () => {
    return (
        <div>
            <h2>FAQ</h2>

            <Container>
                <Title>FAQ</Title>
                <Description>
                    If you have any questions, please first reference this doc
                    here:
                </Description>
                <Link
                    href="https://docs.google.com/document/d/1PxMzCrDaTAzsgJEheh1cbPV_aB4Pt24xfvUnzS3CaTk/edit"
                    target="_blank"
                >
                    JetFuel Standard Practices and FAQ
                </Link>
            </Container>
        </div>
    );
};

export default FAQ;
