import React from "react";
import styled from "styled-components/macro";

const IconRow = styled.div`
    display: flex;
    align-items: center;

    & > * ~ * {
        margin-left: 4px;
    }
`;

const Icon = styled.img`
    width: 14px;
    height: 14px;
    border-radius: 7px;
`;

const Text = styled.div``;

interface Props {
    imgSrc: string;
    text: string;
}

const CreativeIconRow = ({ imgSrc, text }: Props) => {
    return (
        <IconRow>
            <Icon src={imgSrc} />
            <Text>{text}</Text>
        </IconRow>
    );
};
export default CreativeIconRow;
