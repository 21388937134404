import React, { useEffect } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { Advertiser, User } from "./Profile";
import { ProfileModal } from "./reducer";
import { getAdvertiserUserData, submitEvent } from "./actions";
import { push } from "connected-react-router";
import { isLoggedIn } from "../util";
import styled from "styled-components/macro";
import Navbar from "../components/Navbar";
import Sidebar from "./Sidebar";
import MainContent from "./MainContent";
import Notification from "./Notification";
import RocketLoader from "../components/RocketLoader";
import SupportModal from "./SupportModal";
import LoginAsAdvertiserModal from "./LoginAsAdvertiserModal";

const LoadingContainer = styled.div`
    margin-top: 66px;
    height: calc(85vh - 66px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalPortal = styled.div`
    & > * {
        isolation: isolate;
        z-index: 5;
    }
`;

interface Props {
    user?: User;
    advertiser?: Advertiser;
    modal: ProfileModal;
    getAdvertiserUserData: () => void;
    submitEvent: (eventName: string) => void;
    push: (route: string) => void;
}

const Dashboard = ({
    user,
    advertiser,
    modal,
    getAdvertiserUserData,
    submitEvent,
    push
}: Props) => {
    useEffect(() => {
        if (
            isLoggedIn() &&
            !localStorage.getItem("firebaseToken") &&
            !localStorage.getItem("performedReset")
        ) {
            localStorage.clear();
            localStorage.setItem("performedReset", "true");
            window.location.reload();
        }
        if (isLoggedIn()) {
            getAdvertiserUserData();
            submitEvent("advertiser_launched_app");
        }
    }, []);

    if (!isLoggedIn()) {
        const queryParameters = new URLSearchParams(window.location.search);
        const token = queryParameters.get("token");

        if (token) {
            localStorage.setItem("token", token);
            // The following items need to be set for other parts of the app to work
            localStorage.setItem("firebaseToken", "null");
            localStorage.setItem("userid", "null");
        } else {
            push("/login");
            return null;
        }
    }

    if (user && advertiser) {
        return (
            <div>
                <Navbar advertiser={advertiser} />
                <Sidebar user={user} advertiser={advertiser} />
                <MainContent user={user} />
                <Notification />
                {modal === ProfileModal.support && <SupportModal />}
                {modal === ProfileModal.loginAsAdvertiser && (
                    <LoginAsAdvertiserModal />
                )}
                <ModalPortal id="portal-modals" />
            </div>
        );
    } else {
        return (
            <div>
                <Navbar />
                <LoadingContainer>
                    <RocketLoader />
                </LoadingContainer>
            </div>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    user: state.profile.user,
    advertiser: state.profile.advertiser,
    modal: state.profile.modal
});

const mapDispatchToProps = (dispatch: any) => ({
    getAdvertiserUserData: () => dispatch(getAdvertiserUserData()),
    submitEvent: (eventName: string) => dispatch(submitEvent(eventName)),
    push: (route: string) => dispatch(push(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
