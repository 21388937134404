import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    CreativesMediaNormalizedAction,
    fetch,
    fetchError,
    fetchSuccess,
    patch,
    patchSuccess
} from "../../creatives/slice";
import { AdvertiserReviewStatus } from "../../types/Creative";

export type StatusFilterState = {
    [key in AdvertiserReviewStatus]?: boolean;
};

export interface ChallengeFilterState {
    [challengeName: string]: boolean;
}

export interface CreativesUIState {
    creativesLoading: boolean;
    patchCreativeLoading: boolean;
    statusFilter: StatusFilterState;
    challengeFilter: ChallengeFilterState;
}

const initialState: CreativesUIState = {
    creativesLoading: false,
    patchCreativeLoading: false,
    statusFilter: {},
    challengeFilter: {}
};

const creativesUISlice = createSlice({
    name: "creativesUI",
    initialState,
    reducers: {
        setFilter(state, action: PayloadAction<StatusFilterState>) {
            state.statusFilter = action.payload;
        },
        setChallenge(state, action: PayloadAction<ChallengeFilterState>) {
            state.challengeFilter = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(patch, state => {
                state.patchCreativeLoading = true;
            })
            .addCase(patchSuccess, state => {
                state.patchCreativeLoading = false;
            })
            .addCase(fetch, state => {
                state.creativesLoading = true;
            })
            .addCase(
                fetchSuccess,
                (
                    state,
                    action: PayloadAction<CreativesMediaNormalizedAction>
                ) => {
                    const initialChallengeState: ChallengeFilterState = {};
                    const initialStatusFiltersState: ChallengeFilterState = {};
                    const allCreatives = action.payload.normalized.result.map(
                        id => action.payload.normalized.entities.creatives[id]
                    );
                    allCreatives.forEach(creative => {
                        initialChallengeState[creative.openBuy.name] = true;
                        initialStatusFiltersState[
                            creative.advertiserReviewStatus
                        ] = true;
                    });
                    state.challengeFilter = initialChallengeState;
                    state.statusFilter = initialStatusFiltersState;
                    state.creativesLoading = false;
                }
            )
            .addCase(fetchError, state => {
                state.creativesLoading = false;
            });
    }
});

export const { setFilter, setChallenge } = creativesUISlice.actions;

const creativesUIReducer = creativesUISlice.reducer;
export default creativesUIReducer;
