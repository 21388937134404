import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import axios from "../Api";
import RocketLoader from "../components/RocketLoader";
import { colors } from "../constants";
import ExternalNavBar from "./components/ExternalNavBar";
import MediaApprovalFlow from "./components/MediaApprovalFlow";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: ${colors.modalShade};
    min-height: calc(100vh - 77px);
    padding-top: 77px;
    align-items: center;
    justify-content: center;
`;

const ShadeScreen = styled.div`
    display: flex;
    position: fixed;
    z-index: 90;
    background: rgba(0, 0, 0, 0.3);
    min-height: calc(100vh - 65px);
    top: 65px;
    width: 100%;
    cursor: default;
`;
const Error = styled.div`
    display: flex;
    color: ${colors.indicatorRed};
    background: ${colors.white};
    border-radius: 20px;
    width: 400px;
    text-align: center;
    justify-content: center;
    padding: 10px;
`;

export interface ApprovalMedia {
    advertiser_id: number;
    campaign_id: number;
    media_url: string;
    campaign_name: string;
    cover_photo: string;
    cover_photo_url: string;
    type: string;
    created_at: string;
    id: number;
    profile_photo_url: string;
    publisher_id: number;
    username: string;
    image_url: string;
    reward_type: string;
}

const ExternalMediaApprovals = ({ token }: { token: string }) => {
    const [media, setMedia] = useState<ApprovalMedia[] | undefined>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    useEffect(() => {
        axios.defaults.headers["Authorization"] = "bearer " + token;
        axios.get("/dashboard/temp/get_media_for_approval").then(res => {
            if (res.data && res.data.media) {
                setMedia(res.data.media);
            }
        });
    }, []);
    return (
        <>
            <ExternalNavBar title="Media Approvals" />
            <Container>
                {error && <Error>{error}</Error>}
                {media && (
                    <MediaApprovalFlow
                        media={media}
                        total={media.length}
                        setLoading={setLoading}
                        setError={setError}
                    />
                )}
                {(!media || loading) && (
                    <ShadeScreen>
                        <RocketLoader center />
                    </ShadeScreen>
                )}
            </Container>
        </>
    );
};

export default ExternalMediaApprovals;
