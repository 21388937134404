import React from "react";
import styled from "styled-components/macro";
import { colors } from "../../constants";
import { useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { selectById } from "../../media/slice";

const Main = styled.div`
    display: flex;
    flex-direction: column;
`;

const Player = styled.div`
    width: 100%;
    overflow: hidden;
    background-color: ${colors.white};
    border-radius: 4px;
`;

const Video = styled.video`
    display: block;
    width: 100%;
`;

interface Props {
    mediaId: number;
}

const CreativeMediaPlayer = ({ mediaId }: Props) => {
    const media = useSelector((state: GlobalState) =>
        selectById(state, mediaId)
    );
    if (!media) return null;

    return (
        <Main>
            <Player>
                <Video controls src={media.url} />
            </Player>
        </Main>
    );
};
export default CreativeMediaPlayer;
