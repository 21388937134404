import { combineReducers } from "redux";
import creativesUIReducer, { CreativesUIState } from "./creatives/slice";
import rulesUIReducer, { RulesUIState } from "./rules/slice";

export interface UIState {
    creatives: CreativesUIState;
    rules: RulesUIState;
}

const uiReducer = combineReducers({
    creatives: creativesUIReducer,
    rules: rulesUIReducer
});

export default uiReducer;
