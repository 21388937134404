import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "../../components/commonStyles";
import { ReactComponent as OpenInNew } from "../../components/icons/open_in_new.svg";

import { colors } from "../../constants";
import { InputBlock, RedPillButton } from "../../components/styledComponents";
import withModalPortal from "../../components/withModalPortal";
import { Descendant } from "slate";
import TextEditor, { TextEditorRef } from "../../components/text-editor";
import MessagePreview from "../../creatives/components/MessagePreview";
import ConceptUpload from "./ConceptUpload";
import { useDispatch, useSelector } from "react-redux";
import { create, selectById, update } from "../slice";
import {
    isContentEmpty,
    serializeContent
} from "../../components/text-editor/utils";
import { GlobalState } from "../../reducers";
import UploadedAssetItem from "../../assets/components/UploadedAssetItem";
import ButtonLoader from "../../components/ButtonLoader";

const Main = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0%;
    ${FlexCentered};
    flex-direction: column;
    background-color: ${colors.modalShade};
    font-family: Poppins;
`;

const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1080px;
    width: 100%;
    grid-gap: 10px;
    overflow: hidden;
    margin: 60px 0;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: ${colors.white};
    overflow: hidden;
`;

const FormContent = styled.div`
    flex: 1;
    padding: 24px;
    overflow-y: auto;
    overflow-x: hidden;
    & > * ~ * {
        margin-top: 24px;
    }
`;

const FormInput = styled(InputBlock)`
    border-radius: 10px;
    border: 1px solid ${colors.neutral300};
    background: ${colors.neutral100};
    color: ${colors.neutral1000};
    margin: 0px;
    font-size: 14px;
`;

const FormLabel = styled.div`
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
`;

const FormHeader = styled.div`
    font-size: 24px;
    font-weight: 500;
    font-family: Poppins;

    display: flex;
    justify-content: space-between;

    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.primary300};
`;

const StepFooter = styled.div`
    min-height: 0px;
    padding: 24px;
    display: flex;
    justify-content: flex-end;

    border-top: 1px solid ${colors.primary300};

    & > * ~ * {
        margin-left: 10px;
    }
`;

const ActionButton = styled(RedPillButton)`
    height: 36px;
`;

const Section = styled.div`
    & > * ~ * {
        margin-top: 10px;
    }
`;

const CancelButton = styled(RedPillButton)`
    height: 36px;
    border: 1px solid ${colors.primary700};
    color: ${colors.primary700};
    background-color: ${colors.white};
`;

const SampleLink = styled.div`
    ${FlexVerticallyCentered};
    color: ${colors.primary700};
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;

    & > * ~ * {
        margin-left: 6px;
    }
`;

const toolbarStyles = css`
    margin-bottom: 10px;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 2;
    font-size: 14px;
`;

const editableStyles = css`
    height: 10em;
    overflow-y: auto;
    margin-bottom: 8px;
    padding: 2px 6px;
`;

const StyledScriptPreview = styled(MessagePreview)`
    background-color: white;
    @media (max-width: 1120px) {
        width: 100%;
    }
`;

export interface CustomAdminMediaUpload {
    open_buy_id: number;
    cover_photo_url: string;
    type: string;
    uploading: boolean;
    url: string;
    filename: string;
    original_filename: string;
}

interface Props {
    closeModal: () => void;
    openBuyId: number;
    scriptId?: number;
}

const ConceptModal = ({ closeModal, openBuyId, scriptId }: Props) => {
    const dispatch = useDispatch();
    const script = useSelector((state: GlobalState) => {
        if (!scriptId) return;
        return selectById(state, scriptId);
    });
    const [title, setTitle] = useState(script?.title || "");
    const [editorContent, setEditorContent] = useState<Descendant[]>([]);
    const [uploadData, setUploadData] = useState<CustomAdminMediaUpload[]>([]);
    const textEditorRef = useRef<TextEditorRef>(null);
    const [isError, setIsError] = useState(false);

    const handleSubmit = () => {
        const markdownStr = serializeContent(editorContent);
        const isEmpty = isContentEmpty(markdownStr);

        if (!isEmpty) {
            dispatch(
                create({
                    title,
                    text: markdownStr,
                    mediaUploadData: uploadData,
                    openBuyId
                })
            );
            closeModal();
        }
    };

    const handleUpdate = () => {
        const markdownStr = serializeContent(editorContent);
        const isEmpty = isContentEmpty(markdownStr);

        if (!isEmpty && script) {
            dispatch(
                update({
                    id: script.id,
                    title,
                    text: markdownStr,
                    mediaUploadData: uploadData
                })
            );
            setUploadData([]);
        }
    };

    const isUploading = uploadData.filter(data => data.uploading).length > 0;

    return (
        <Main onMouseDown={closeModal}>
            <ModalWrapper onMouseDown={e => e.stopPropagation()}>
                <Content>
                    <FormContent>
                        <FormHeader>
                            <div>Concept Details</div>
                            <SampleLink>
                                <div>View Sample Concept</div>
                                <OpenInNew />
                            </SampleLink>
                        </FormHeader>
                        <Section>
                            <FormLabel>Concept Title</FormLabel>
                            <FormInput
                                value={title}
                                placeholder="Enter a title"
                                type="text"
                                onChange={e => setTitle(e.currentTarget.value)}
                            />
                        </Section>
                        <Section>
                            <FormLabel>Concept Content</FormLabel>
                            <TextEditor
                                ref={textEditorRef}
                                initialText={script?.text || ""}
                                autoFocus={true}
                                editorContent={editorContent}
                                setEditorContent={setEditorContent}
                                handleChange={(newContent: Descendant[]) => {
                                    setEditorContent(newContent);
                                }}
                                setIsError={setIsError}
                                toolbarStyles={toolbarStyles}
                                editableStyles={editableStyles}
                                placeholder={""}
                                PreviewComponent={StyledScriptPreview}
                            />
                        </Section>
                        <Section>
                            <FormLabel>Upload Concept Example Media</FormLabel>
                            <ConceptUpload
                                openBuyId={openBuyId}
                                uploadData={uploadData}
                                setUploadData={setUploadData}
                                scriptId={script?.id}
                                existingAssetIds={script?.Assets}
                            />
                        </Section>
                    </FormContent>
                    <StepFooter>
                        <CancelButton onClick={closeModal}>Cancel</CancelButton>
                        {script ? (
                            <ActionButton
                                onClick={handleUpdate}
                                disabled={isUploading}
                            >
                                {isUploading ? <ButtonLoader /> : "Update"}
                            </ActionButton>
                        ) : (
                            <ActionButton
                                onClick={handleSubmit}
                                disabled={isUploading}
                            >
                                {isUploading ? <ButtonLoader /> : "Save"}
                            </ActionButton>
                        )}
                    </StepFooter>
                </Content>
            </ModalWrapper>
        </Main>
    );
};
export default withModalPortal(ConceptModal);
