import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import { colors } from "../../constants";
import { ApprovalMedia } from "../ExternalMediaApprovals";
import ExternalMediaDetails from "./ExternalMediaDetails";

const Message = styled.span`
    display: block;
    color: ${colors.white};
    font-weight: 500;
    font-size: 30px;
    margin: 40px auto;
    text-align: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    gap: 8px;
`;

const Content = styled.div`
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    width: 300px;
    align-items: center;
    color: white;
    border-radius: 8px;
    min-height: 48px;
    padding: 8px 18px;
    box-sizing: border-box;
    font-size: 12px;
`;

const ActionRow = styled.div`
    display: flex;
    width: 100%;
    gap: 10px;
`;
const ConfirmButton = styled.div`
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 6px;
    background: ${colors.brightRed};
    color: ${colors.white};
    align-items: center;
    cursor: pointer;
    margin-bottom: 14px;
`;

const DeclineButton = styled(ConfirmButton)`
    background: ${colors.white};
    color: ${colors.linkBlue};
`;

const ActionIcon = styled.img`
    height: 24px;
    width: 24px;
    margin-right: 8px;
`;

const ReasonInput = styled.textarea`
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: white;
    background: none;
    resize: none;
    font-size: 12px;
    width: 100%;
    font-family: San Francisco, -apple-system, BlinkMacSystemFont,
        Helvetica Neue, Helvetica, sans-serif;
    &::placeholder {
        color: white;
        opacity: 1;
    }
`;

interface Props {
    media: ApprovalMedia[];
    total: number;
    setLoading: (val: boolean) => void;
    setError: (val: string) => void;
}
const MediaApprovalFlow = ({ media, total, setLoading, setError }: Props) => {
    const [current, setCurrent] = useState(media.length > 0 ? 0 : null);
    const [step, setStep] = useState("approve");
    const history = useHistory();
    const [reason, setReason] = useState(null);

    const next = () => {
        if (current !== null) setCurrent(current + 1);
        setStep("approve");
    };

    const accept = () => {
        setLoading(true);
        if (current !== null)
            axios
                .post("/dashboard/temp/approve_user_submitted_media", {
                    media_id: media[current].id
                })
                .then(res => {
                    setLoading(false);
                    setStep("approved");
                    setError("");
                })
                .catch(err => {
                    setLoading(false);
                    setError("Error approving media try again");
                });
    };

    const decline = () => {
        setLoading(true);
        if (current !== null)
            axios
                .post("/dashboard/temp/reject_user_submitted_media", {
                    media_id: media[current].id,
                    rejection_reason: reason,
                    publisher_id: media[current].publisher_id
                })
                .then(res => {
                    setLoading(false);
                    setStep("declined");
                    setError("");
                })
                .catch(err => {
                    setLoading(false);
                    setError("Error declining media try again");
                });
    };

    const RenderButtons = () => {
        if (current === null) return null;
        else if (step === "approve")
            return (
                <ActionRow>
                    <DeclineButton onClick={() => setStep("confirmDecline")}>
                        Decline
                    </DeclineButton>
                    <ConfirmButton onClick={accept}>Accept</ConfirmButton>
                </ActionRow>
            );
        else if (step === "confirmDecline")
            return <ConfirmButton onClick={decline}>Decline</ConfirmButton>;
        else
            return (
                <ConfirmButton
                    onClick={() => {
                        current + 1 === media.length
                            ? history.push("/login")
                            : next();
                    }}
                >
                    {current + 1 === media.length
                        ? "Login to Dashboard"
                        : "Next"}
                </ConfirmButton>
            );
    };

    return current === null ? (
        <>
            <Message>You have no media left to approve</Message>

            <Content
                style={{
                    textAlign: "center",
                    gap: 20,
                    fontSize: "14px",
                    flexDirection: "column"
                }}
            >
                To view and manage all media for your campaigns:
                <ConfirmButton onClick={() => history.push("/login")}>
                    Login To Dashboard
                </ConfirmButton>
            </Content>
        </>
    ) : (
        <Container>
            <ExternalMediaDetails
                media={media[current || 0]}
                count={`
                ${current + 1} of ${total}`}
            />
            <Content>
                Media: {media[current || 0].id}
                <br />
                By: {media[current || 0].username}
            </Content>
            {step === "confirmDecline" && (
                <Content>
                    <ReasonInput
                        placeholder="click here to add a reason"
                        onChange={(e: any) => setReason(e.target.value)}
                    />
                </Content>
            )}
            {(step === "approved" || step === "declined") && (
                <Content>
                    <ActionIcon
                        src={
                            step === "approved"
                                ? "/approvals/green_check.svg"
                                : "/approvals/red_x.svg"
                        }
                        alt=""
                    />
                    <>
                        This media has been {step} <br />
                        {current + 1 === media.length &&
                            "No more media left to review"}
                    </>
                </Content>
            )}
            {RenderButtons()}
        </Container>
    );
};

export default MediaApprovalFlow;
