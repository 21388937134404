import { all, call, delay, put, takeLeading } from "redux-saga/effects";
import { get, post } from "../Api";
import {
    CreativesMediaNormalizedEntities,
    PatchCreativesAction,
    fetch,
    fetchError,
    fetchSuccess,
    patch,
    patchSuccess
} from "./slice";
import { normalize } from "normalizr";
import { creativeList } from "./schema";
import { PayloadAction } from "@reduxjs/toolkit";

function* fetchCreativeSubmissionsRequest(
    action: PayloadAction<{ openBuyId: number }>
) {
    const { openBuyId } = action.payload;
    try {
        const { data } = yield call(get, "/dashboard/api/submissions", {
            open_buy_id: action.payload.openBuyId
        });

        const normalized = normalize<any, CreativesMediaNormalizedEntities>(
            data,
            creativeList
        );
        yield put(fetchSuccess({ normalized, openBuyId }));
    } catch (error) {
        yield put(fetchError());
    }
}

function* patchCreativeSubmissionRequest(
    action: PayloadAction<PatchCreativesAction>
) {
    try {
        const { advertiserMessage, creativeId, reviewAction } = action.payload;
        const { data } = yield call(
            post,
            `/dashboard/api/submissions/${creativeId}/${reviewAction}`,
            {
                advertiserMessage
            }
        );

        const normalized = normalize<any, CreativesMediaNormalizedEntities>(
            data,
            creativeList
        );

        yield put(patchSuccess(normalized));
    } catch (error) {
        console.log(error);
    }
}

export default function* creativesSaga() {
    yield all([
        takeLeading(fetch, fetchCreativeSubmissionsRequest),
        takeLeading(patch, patchCreativeSubmissionRequest)
    ]);
}
