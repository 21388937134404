import styled, { css } from "styled-components/macro";

export const FlexCentered = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FlexVerticallyCentered = css`
    display: flex;
    align-items: center;
`;

export const FlexVCSpaceBetween = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const TextEllipsis = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const FlexContent = styled.div`
    flex: 1;
    overflow-y: auto;
`;
