import styled from "styled-components/macro";
import { colors } from "../../../constants";

export interface BtnProps {
    isActive?: boolean;
    isLinkActive?: boolean;
}
export const BtnWrapper = styled.button<BtnProps>`
    width: 32px;
    height: 32px;
    padding: 0px;
    margin: 0px 4px;
    display: inline-grid;
    place-items: center;
    border-radius: 8px;
    background-color: ${props =>
        props.isActive ? colors.primary100 : "transparent"};

    /* &:first-child {
        margin-left: 0px;
    }
    &:last-child {
        margin-right: 0px;
    } */
`;
