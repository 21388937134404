import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { rebrandColors } from "../constants";
import { logout, supportModal } from "../dashboard/actions";
import { Advertiser } from "../dashboard/Profile";
import ProductItem from "./ProductItem";

const Wrapper = styled.div`
    position: relative;
    margin-right: 20px;
`;

const Main = styled.div`
    position: absolute;
    top: 60px;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: ${rebrandColors["neutral-0"]};
    padding: 16px;
    box-shadow: 0px 0px 42px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
`;

const List = styled.div`
    & > * ~ * {
        border-top: 1px solid ${rebrandColors["primary-300"]};
    }
`;

const menuItemCss = css`
    display: flex;
    align-items: center;
    height: 40px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 8px;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        background: ${rebrandColors["neutral-100"]};
    }
`;

const MenuItemLink = styled(Link)`
    ${menuItemCss}
`;

const MenuItem = styled.div`
    ${menuItemCss}
`;

const DropdownArea = styled.div`
    color: ${rebrandColors["primary-700"]};
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
`;

const DropdownText = styled.div`
    font-family: Poppins;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
`;

const DropdownArrow = styled.img<{ active: boolean }>`
    margin-left: 12px;
    width: 22px;
    transform: rotate(360deg);
    transition: 0.3s transform;
    ${props => props.active && "transform: rotate(180deg);"}
`;

interface Props {
    advertiser?: Advertiser;
}

const AccountMenu = ({ advertiser }: Props) => {
    const [open, toggleOpen] = useState(false);
    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (open && ref.current && !ref.current.contains(event.target)) {
                toggleOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    return (
        <Wrapper ref={ref}>
            {advertiser && (
                <DropdownArea onClick={() => toggleOpen(!open)}>
                    <DropdownText>
                        Hello, {advertiser.company_name}
                    </DropdownText>
                    <DropdownArrow src="/arrow_dropdown.svg" active={open} />
                </DropdownArea>
            )}
            {open && (
                <Main>
                    <List>
                        {/* <MenuItemLink
                            to="/api"
                            onClick={() => toggleOpen(false)}
                        >
                            API
                        </MenuItemLink>
                        <MenuItemLink
                            to="/faq"
                            onClick={() => toggleOpen(false)}
                        >
                            FAQ
                        </MenuItemLink> */}
                        <MenuItem onClick={() => dispatch(supportModal())}>
                            Help
                        </MenuItem>
                        <MenuItem onClick={() => dispatch(logout())}>
                            Sign out
                        </MenuItem>
                        <ProductItem
                            title="Accelerate"
                            legacyTitle="Liftoff"
                            src="/rebrand/lv-accelerate.svg"
                            href="https://app.liftoff.io"
                        />
                        <ProductItem
                            title="Monetize"
                            legacyTitle="Vungle"
                            src="/rebrand/lv-monetize.svg"
                            href="http://publisher.vungle.com/"
                        />
                        <ProductItem
                            title="Direct"
                            legacyTitle="Vungle"
                            src="/rebrand/lv-direct.svg"
                            href="http://advertiser.vungle.com/"
                        />
                        <ProductItem
                            title="Influence"
                            legacyTitle="JetFuel"
                            src="/rebrand/lv-influence.svg"
                            href="https://dash.jetfuel.it/login"
                        />
                        <ProductItem
                            title="Intelligence"
                            legacyTitle="GameRefinery"
                            src="/rebrand/lv-intelligence.svg"
                            href="https://saas.gamerefinery.com/"
                        />
                    </List>
                </Main>
            )}
        </Wrapper>
    );
};
export default AccountMenu;
