import axios from "axios";
import qs from "querystring";
import { useFirebaseToken } from "./FirebaseConfig";

if (process.env.BASE_URL) {
    axios.defaults.baseURL = process.env.BASE_URL;
} else if (window.location.hostname === "jetfuel-dashboard-dev.herokuapp.com") {
    axios.defaults.baseURL = "https://theplug-prod.herokuapp.com/";
} else if (
    window.location.hostname === "dash.jetfuel.it" ||
    window.location.hostname === "jetfuel-dashboard-prod.herokuapp.com"
) {
    axios.defaults.baseURL = "https://theplug-prod.herokuapp.com/";
} else {
    axios.defaults.baseURL = "http://localhost:3003/";
}

axios.defaults.withCredentials = false;
axios.defaults.headers.common["Content-Type"] =
    "application/x-www-form-urlencoded";
axios.defaults.headers.common = {
    Authorization: `bearer ${localStorage.getItem("token")}`
};
axios.defaults.timeout = 30000;

axios.interceptors.request.use(useFirebaseToken, error => {
    return Promise.reject(error);
});

export const setToken = () => {
    axios.defaults.headers.common = {
        Authorization: `bearer ${localStorage.getItem("token")}`
    };
};

export const get = (path: string, params: any) => {
    return axios.get(path, { params });
};

export const post = (path: string, params: any) => {
    return axios.request({
        method: "post",
        url: path,
        data: params
    });
};

export function patch<Params>(path: string, params?: Params) {
    return axios.request({
        method: "patch",
        url: path,
        data: params
    });
}

export function deleteReq<Params>(path: string, params?: Params) {
    return axios.request({
        method: "delete",
        url: path,
        data: params
    });
}

export default axios;
