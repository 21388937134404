import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { ReactComponent as FileUploadIcon } from "../../components/icons/file_upload_icon.svg";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "../../components/commonStyles";
import { colors } from "../../constants";
import { useDispatch } from "react-redux";
import { makeId } from "../../util";
import { storage } from "../../FirebaseConfig";

const Link = styled.button`
    ${FlexVerticallyCentered};
    color: ${colors.primary700};
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;

    & > * ~ * {
        margin-left: 6px;
    }
`;

const UploadInstructions = styled.div`
    color: ${colors.neutral700};
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
`;

const UploadContainer = styled.div`
    border-radius: 8px;
    border: 2px dashed ${colors.neutral300};
    background: ${colors.neutral100};

    padding: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > ${UploadInstructions} ~ ${UploadInstructions} {
        margin-top: 6px;
    }

    & > ${Link} {
        margin-top: 20px;
    }

    &:hover {
        border: 2px dashed ${colors.primary700};
    }
`;

interface Props {
    openBuyId: number;
    onUploadBegin?: (
        id: string,
        type: string,
        original_filename: string
    ) => void;
    onUpload?: (id: string, url: string) => void;
    onCoverPhoto?: (coverPhotoId: string, id: string) => void;
}

const DragUploadCard = ({
    openBuyId,
    onUploadBegin,
    onUpload,
    onCoverPhoto
}: Props) => {
    const dispatch = useDispatch();
    const [fileQueue, setFileQueue] = useState<File[] | null>(null);

    useEffect(() => {
        if (fileQueue) {
            fileUpload(fileQueue[0]);
        }
    }, [fileQueue]);

    function createCoverPhoto(file: File, id: string) {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");

        video.preload = "metadata";
        video.crossOrigin = "Anonymous";
        video.src = URL.createObjectURL(file);
        if (false) {
            video.load();
        } else {
            video.muted = true;
            video.play();
        }

        video.onloadeddata = () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext("2d");

            if (!context) {
                return null;
            }

            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            canvas.toBlob(blob => {
                if (!blob) return null;

                const coverPhotoId = `${localStorage.getItem(
                    "userid"
                )}_${makeId(50)}.jpeg`;

                // const ref = storage.ref().child(`assets/${path}/${coverPhotoId}`)
                const fileRef = ref(
                    storage,
                    `assets/${openBuyId}/${coverPhotoId}`
                );

                uploadBytes(fileRef, blob)
                    .then(() => {
                        getDownloadURL(fileRef).then(url => {
                            onCoverPhoto && onCoverPhoto(url, id);
                        });
                    })
                    .catch((error: string) => {
                        window.alert("Something went wrong. Please Refresh.");
                    });
            }, "image/jpeg");
        };
    }

    const handleClick = (e: any) => {
        e.preventDefault();
        const files: File[] = Object.values(e.currentTarget.files);
        setFileQueue(files);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        const files = Object.values(e.dataTransfer.files);
        setFileQueue(files);
    };

    const fileUpload = (file: any) => {
        if (file) {
            const allowedTypes = [
                "image/jpeg",
                "image/png",
                "video/mp4",
                "video/quicktime"
            ];

            if (file && allowedTypes.includes(file.type)) {
                let fileExtension = file.type.split("/")[1];
                const extensionIdx = file.name.lastIndexOf(".");
                const original_filename = file.name.substring(0, extensionIdx);
                const fileType =
                    file.type.split("/")[0] === "image" ? "photo" : "video";

                if (fileExtension === "quicktime") {
                    fileExtension = "mov";
                }
                const id = `${localStorage.getItem("userid")}_${makeId(
                    50
                )}.${fileExtension}`;

                onUploadBegin && onUploadBegin(id, fileType, original_filename);

                // const ref = storage.ref().child(`assets/${path}/${id}`);
                const fileRef = ref(storage, `assets/${openBuyId}/${id}`);

                uploadBytes(fileRef, file)
                    .then(() => {
                        getDownloadURL(fileRef).then(url => {
                            if (fileType === "video") {
                                onUpload && onUpload(id, url);
                                createCoverPhoto(file, id);
                            } else {
                                onUpload && onUpload(id, url);
                            }
                        });
                    })
                    .catch(error =>
                        console.log("Something went wrong. Please Refresh.")
                    );
            } else {
                console.log(
                    "File type must be .jpeg, .jpg, .png, .mp4, or quicktime/.mov"
                );
            }
            if (fileQueue && fileQueue.length > 0) {
                const newQueue = fileQueue.slice(1);
                setFileQueue(newQueue);
            } else {
                setFileQueue(null);
            }
        }
    };

    return (
        <>
            <UploadContainer
                onDrop={handleDrop}
                onDragOver={e => e.preventDefault()}
            >
                <UploadInstructions>
                    Upload a <strong>Media</strong> file for{" "}
                    <strong>App Name Goes Here</strong>
                </UploadInstructions>
                <UploadInstructions>
                    Drag and drop here from your local drive or
                </UploadInstructions>
                <Link as={"label"}>
                    <FileUploadIcon />
                    <div>Browse</div>
                    <input
                        type={"file"}
                        accept={
                            "image/png, image/jpeg, video/mp4, video/quicktime"
                        }
                        style={{ display: "none" }}
                        onChange={handleClick}
                        disabled={false}
                        multiple
                    />
                </Link>
            </UploadContainer>
        </>
    );
};
export default DragUploadCard;
