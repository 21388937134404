import styled from "styled-components/macro";
import { colors } from "../constants";

const Button = styled.button`
    border-style: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Helvetica Neue",
        "Helvetica", sans-serif;
    box-sizing: border-box;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s;
`;

// -------------------------------------------------------------------

export const RedButton = styled(Button)`
    background-color: ${colors.primary700};
    color: ${colors.neutral0};

    &:not([disabled]):hover {
        background-color: ${colors.primary500};
    }

    &:disabled {
        background-color: ${colors.neutral300};
        cursor: default;
    }

    height: 36px;
`;

export const RedPillButton = styled(RedButton)`
    padding: 0 32px;
    border-radius: 18px;
    font-size: 14px;
`;

export const BigRedPillButton = styled(RedButton)`
    width: 200px;
    height: 60px;
    border-radius: 30px;
    font-size: 16px;
`;

export const BigModalButton = styled(RedPillButton)`
    width: 220px;
`;

export const ModalButton = styled(RedPillButton)`
    width: 136px;
`;

export const RestartButton = styled(RedButton)`
    width: 100%;
    border-radius: 18px;
    max-width: 100px;
`;

export const AuthButton = styled(RedButton)`
    width: 100%;
    border-radius: 18px;
`;

// -------------------------------------------------------------------

export const GrayButton = styled(Button)`
    background-color: ${colors.neutral100};

    &:not([disabled]):hover {
        background-color: ${colors.neutral300};
    }
`;

export const CopyButton = styled(GrayButton)`
    width: 76px;
    border-radius: 6px;
    padding: 15px;
`;

export const UsersButton = styled(GrayButton)`
    width: 76px;
    border-radius: 6px;
    padding: 9px;
`;

export const GrayMediaModalButton = styled(GrayButton)`
    width: 100%;
    border-radius: 6px;
    padding: 10px;
`;

export const GrayMediaTableButton = styled(GrayButton)`
    width: 72px;
    border-radius: 6px;
    padding: 10px;
`;

// -------------------------------------------------------------------

export const WhiteButton = styled(Button)`
    border: 1px solid ${colors.neutral300};
    background-color: white;
`;

export const WhitePillButton = styled(WhiteButton)`
    height: 36px;
    padding: 0 16px;
    border-radius: 20px;
`;

export const WhitePillSpacedButton = styled(WhiteButton)`
    height: 36px;
    padding: 0 16px;
    border-radius: 18px;
    & > * ~ * {
        margin-left: 10px;
    }
`;

export const EditButton = styled(WhitePillButton)`
    margin-right: 10px;
`;

export const DateButton = styled(WhitePillButton)`
    min-width: 130px;
`;

export const BidButton = styled(WhitePillButton)`
    margin-left: 10px;
`;

export const UploadCSVButton = styled(BidButton)`
    position: relative;
    width: 160px;
`;

export const RoundedWhiteButton = styled(WhiteButton)`
    height: 28px;
    padding: 0 16px;
    border-radius: 14px;
    white-space: nowrap;
    font-size: 12px;
`;

// -------------------------------------------------------------------

export const ClearButton = styled(Button)`
    background-color: inherit;
    color: ${colors.error900};

    &:not([disabled]):hover {
        color: ${colors.error900};
    }
`;

export const ClearModalButton = styled(ClearButton)`
    height: 36px;
    padding: 0 20px;
`;

// -------------------------------------------------------------------

export const Input = styled.input`
    border: none;
    outline: none;
    font-weight: 400;
    box-sizing: border-box;
    font-family: "San Francisco", -apple-system, BlinkMacSystemFont,
        "Helvetica Neue", "Helvetica", sans-serif;

    &::placeholder {
        color: ${colors.neutral500};
        font-weight: 400;
    }
`;

const GrayInput = styled(Input)`
    border-radius: 14px;
    background-color: ${colors.neutral100};
    color: ${colors.neutral500};
    font-size: 12px;
    padding: 15px;
`;

export const InputBlock = styled(GrayInput)`
    width: 100%;
    margin-bottom: 15px;
`;

export const CopyInput = styled(GrayInput)`
    width: 50%;
    margin-right: 15px;
    text-overflow: ellipsis;
`;

export const CampaignInput = styled(GrayInput)<{ error?: boolean }>`
    height: 60px;
    width: 100%;
    font-size: 16px;
    ${props => props.error && `border: 1px solid ${colors.error900}`}
`;

export const ClearInput = styled(Input)`
    background-color: transparent;
    font-size: 24px;
`;

export const BidInput = styled(ClearInput)`
    width: 70%;
    padding: 12px 8px;
`;

// -------------------------------------------------------------------

export const VerticallyCentered = styled.div`
    display: flex;
    align-items: center;
`;

export const Cell = styled(VerticallyCentered)`
    height: 100%;
`;

// Links --------------------------------------------------------------

export const BlankLink = styled.a`
    text-decoration: none;
    outline: none;
    color: inherit;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        color: inherit;
    }
`;
