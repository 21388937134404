import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Advertiser, User } from "./Profile";
import { loginAsAdvertiserModal } from "./actions";
import { colors } from "../constants";
import styled from "styled-components/macro";
import { RedPillButton } from "../components/styledComponents";
import { ReactComponent as UserIcon } from "../components/icons/users_icon.svg";
import { ReactComponent as ChallengesIcon } from "../components/icons/challenges_icon.svg";
import SidebarLink from "./components/SidebarLink";
import LinkContainer from "./components/LinkContainer";
import { fetch } from "../challenges/slice";
import { getBucketedChallenges } from "../challenges/selectors";
import { useLocation } from "react-router";

const Main = styled.div`
    position: fixed;
    top: 66px;
    left: 0px;
    bottom: 0px;
    width: 252px;
    background-color: white;
    border-right: 1px solid ${colors.neutral300};
    box-sizing: border-box;
    font-family: Poppins;
`;

const ActionButton = styled(RedPillButton)`
    height: 36px;
    margin: 0 14px;
    margin-bottom: 20px;
`;

const TopLinkGroup = styled.div`
    padding: 24px 0 40px;
    display: flex;
    flex-direction: column;

    & > * ~ * {
        margin-top: 10px;
    }
`;

const BottomLinkGroup = styled.div`
    padding: 30px 0;
    border-top: 1px solid ${colors.neutral300};
`;

const MiddleLinkContainer = styled(LinkContainer)`
    margin-bottom: 20px;
    opacity: 0.3;

    &:hover {
        opacity: 1;
        transition: opacity 0.2s;
    }
`;

const AddIcon = styled.img`
    margin-right: 6px;
`;

const Link = styled(SidebarLink)`
    line-height: 16px;
`;

const BucketSection = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 12px;

    & > ${Link} ~ ${Link} {
        margin-top: 6px;
    }
`;

const Label = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: ${colors.neutral700};
    padding: 18px 0 12px 22px;
`;

interface Props {
    user: User;
    advertiser: Advertiser;
}

const Sidebar = ({ user, advertiser }: Props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { live, draft, ended } = useSelector(getBucketedChallenges);

    useEffect(() => {
        dispatch(fetch({ onRootPage: location.pathname === "/" }));
    }, []);

    function renderLoginAsAdvertiser() {
        if (advertiser.id === 1 && user.id !== 863) {
            return (
                <MiddleLinkContainer
                    onClick={() => dispatch(loginAsAdvertiserModal())}
                >
                    <div>Login As Advertiser</div>
                </MiddleLinkContainer>
            );
        }
    }

    return (
        <Main>
            {live && (
                <BucketSection>
                    <Label>LIVE</Label>
                    {live.map(challenge => (
                        <Link
                            key={challenge.id}
                            Icon={<ChallengesIcon />}
                            to={`/challenge/${challenge.id}/concepts`}
                            text={challenge.name}
                        />
                    ))}
                </BucketSection>
            )}

            {draft && (
                <BucketSection>
                    <Label>DRAFTS</Label>
                    {draft.map(challenge => (
                        <Link
                            key={challenge.id}
                            Icon={<ChallengesIcon />}
                            to={`/challenge/${challenge.id}/concepts`}
                            text={challenge.name}
                        />
                    ))}
                </BucketSection>
            )}
            {ended && (
                <BucketSection>
                    <Label>ENDED</Label>
                    {ended.map(challenge => (
                        <Link
                            key={challenge.id}
                            Icon={<ChallengesIcon />}
                            to={`/challenge/${challenge.id}/concepts`}
                            text={challenge.name}
                        />
                    ))}
                </BucketSection>
            )}
            <TopLinkGroup>
                {user.is_admin && (
                    <SidebarLink Icon={<UserIcon />} to="/users" text="Users" />
                )}
            </TopLinkGroup>
            <BottomLinkGroup>{renderLoginAsAdvertiser()}</BottomLinkGroup>
        </Main>
    );
};

export default Sidebar;
