import { createSelector } from "@reduxjs/toolkit";
import { selectAll } from "./slice";
import { Challenge, ChallengeStatus } from "../types/challenge/Challenge";

type ChallengeStatusBucket = {
    [key in ChallengeStatus]?: Challenge[];
};

export const getBucketedChallenges = createSelector(selectAll, challenges => {
    const buckets: ChallengeStatusBucket = {};
    challenges.forEach(challenge => {
        const bucket = buckets[challenge.status];
        if (bucket) {
            bucket.push(challenge);
        } else {
            buckets[challenge.status] = [challenge];
        }
    });
    return buckets;
});
