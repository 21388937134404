import React from "react";
import styled from "styled-components/macro";
import { rebrandColors } from "../constants";

const Main = styled.a`
    height: 62px;
    width: 306px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        background: ${rebrandColors["neutral-100"]};
    }
`;

const LeftContainer = styled.div`
    display: flex;
    align-items: center;
`;
const ProductIcon = styled.img`
    width: 40px;
`;

const ProductName = styled.div`
    margin-left: 16px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    justify-self: flex-start;
`;

const LegacyName = styled.div`
    margin-left: 6px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    justify-self: flex-start;
`;

const ChevronRight = styled.img`
    width: 20px;
`;

interface Props {
    title: string;
    legacyTitle: string;
    src: string;
    href: string;
}

const ProductItem = ({ title, legacyTitle, src, href }: Props) => {
    return (
        <Main href={href}>
            <LeftContainer>
                <ProductIcon src={src} />
                <ProductName>{title} /</ProductName>
                <LegacyName>{legacyTitle}</LegacyName>
            </LeftContainer>
            <ChevronRight src="/chevron_right.svg" />
        </Main>
    );
};
export default ProductItem;
