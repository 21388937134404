import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import authReducer, { AuthState } from "./auth/reducer";
import profileReducer, { ProfileState } from "./dashboard/reducer";
import campaignsReducer, { CampaignsState } from "./campaigns/reducer";
import apiReducer, { APIState } from "./reportingAPI/reducer";
import usersReducer, { UsersState } from "./users/reducer";
import entitiesReducer, { EntitiesState } from "./entitiesReducer";
import uiReducer, { UIState } from "./ui/reducers";

export interface GlobalState {
    ui: UIState;
    entities: EntitiesState;
    auth: AuthState;
    profile: ProfileState;
    campaigns: CampaignsState;
    api: APIState;
    users: UsersState;
    router: any;
}

export default (history: any) =>
    combineReducers<GlobalState>({
        ui: uiReducer,
        entities: entitiesReducer,
        auth: authReducer,
        profile: profileReducer,
        campaigns: campaignsReducer,
        api: apiReducer,
        users: usersReducer,
        router: connectRouter(history)
    } as any);
