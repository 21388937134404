import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    GET_ADVERTISER_API_TOKEN,
    getAdvertiserAPITokenFailure,
    getAdvertiserAPITokenSuccess
} from "./actions";
import { get } from "../Api";

function* getAdvertiserAPITokenRequest() {
    try {
        const { data, status } = yield call(
            get,
            "/dashboard/api/get_advertiser_api_token",
            {}
        );

        if (status === 200) {
            yield put(getAdvertiserAPITokenSuccess(data));
        } else {
            yield put(getAdvertiserAPITokenFailure("Fetch api token failure"));
        }
    } catch (error) {
        yield put(getAdvertiserAPITokenFailure("Fetch api token failure"));
    }
}

export default function* apiSaga() {
    yield all([
        takeEvery(
            GET_ADVERTISER_API_TOKEN.REQUEST,
            getAdvertiserAPITokenRequest
        )
    ]);
}
