import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DragUploadCard from "./DragUploadCard";
import { CustomAdminMediaUpload } from "./ConceptModal";
import UploadedAssetItem from "../../assets/components/UploadedAssetItem";
import { FlexCentered } from "../../components/commonStyles";
import { colors } from "../../constants";

const List = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 18px;
`;

const Video = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const MediaPreview = styled.div`
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.08));

    &:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        padding-bottom: calc(16 / 9 * 100%);
    }
`;

const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const RemoveContainer = styled.div`
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: ${colors.neutral0};
    width: 26px;
    height: 26px;
    border-radius: 18px;
    user-select: none;
    cursor: pointer;

    ${FlexCentered};
`;

const RemoveIcon = styled.img``;

interface Props {
    openBuyId: number;
    scriptId?: number;
    uploadData: CustomAdminMediaUpload[];
    setUploadData: (uploadData: any) => void;
    existingAssetIds?: number[];
}

const ConceptUpload = ({
    openBuyId,
    scriptId,
    uploadData,
    setUploadData,
    existingAssetIds
}: Props) => {
    const initialUploadData = {
        open_buy_id: openBuyId,
        filename: "",
        original_filename: "",
        cover_photo_url: "",
        type: "",
        uploading: true,
        url: ""
    };

    const handleUploadBegin = (
        id: string,
        type: string,
        original_filename: string
    ) => {
        setUploadData([
            ...uploadData,
            { ...initialUploadData, type, filename: id, original_filename }
        ]);
    };

    const handleUpload = (id: string, url: string) => {
        setUploadData((prevState: any) =>
            prevState.map((data: any) =>
                data.filename === id
                    ? {
                          ...data,
                          url,
                          uploading: data.type === "photo" ? false : true
                      }
                    : data
            )
        );
    };
    const handleRemove = (idx: number) => {
        setUploadData(uploadData.filter((data, dataIdx) => idx !== dataIdx));
    };

    const handleCoverPhoto = (coverPhotoUrl: string, id: string) => {
        setUploadData((prevState: any) => {
            const newData = prevState.map((data: any) =>
                data.filename === id
                    ? {
                          ...data,
                          cover_photo_url: coverPhotoUrl,
                          uploading: false
                      }
                    : data
            );
            return newData;
        });
    };

    const count = uploadData.filter(data => !data.uploading).length;

    return (
        <>
            <DragUploadCard
                openBuyId={openBuyId}
                onUploadBegin={handleUploadBegin}
                onUpload={handleUpload}
                onCoverPhoto={handleCoverPhoto}
            />
            <List>
                {scriptId &&
                    existingAssetIds &&
                    existingAssetIds.map(assetId => (
                        <UploadedAssetItem
                            key={assetId}
                            assetId={assetId}
                            scriptId={scriptId}
                        />
                    ))}
                {uploadData.map((mediaItem, index) => (
                    <MediaPreview key={mediaItem.filename}>
                        {mediaItem.type === "video" ? (
                            <Video src={mediaItem.url} controls />
                        ) : (
                            <Image src={mediaItem.url} />
                        )}
                        <RemoveContainer onClick={() => handleRemove(index)}>
                            <RemoveIcon src="/close.svg" />
                        </RemoveContainer>
                    </MediaPreview>
                ))}
            </List>
        </>
    );
};
export default ConceptUpload;
