import React, { useState } from "react";
import styled from "styled-components/macro";
import {
    FloatingPortal,
    flip,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions
} from "@floating-ui/react";
import { colors } from "../constants";
import { ReactComponent as SelectedIcon } from "./icons/checkbox_selected.svg";
import { ReactComponent as UnselectedIcon } from "./icons/checkbox_unselected.svg";
import { FlexVerticallyCentered } from "./commonStyles";
import { WhitePillButton } from "./styledComponents";

const UpCaret = styled.img<{ isOpen: boolean }>`
    transform: ${props => (props.isOpen ? "rotate(0.5turn)" : "rotate(0turn)")};
    transition: 0.3s;
`;

const Button = styled(WhitePillButton)`
    text-transform: capitalize;
    & > * ~ * {
        margin-left: 15px;
    }
`;

const Dropdown = styled.div`
    border: 1px solid ${colors.neutral300};
    font-family: Poppins;
    background-color: ${colors.white};
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 16px 0px;
    border-radius: 18px;
    font-size: 14px;
    font-weight: 500;
`;

const Row = styled.div<{ active: boolean }>`
    height: 36px;
    padding: 0px 16px;
    ${FlexVerticallyCentered};
    cursor: pointer;
    user-select: none;

    &:hover {
        background-color: ${colors.primary100};
        color: ${colors.neutral700};
    }

    color: ${props => !props.active && colors.neutral300};
`;

const Selection = styled.div`
    height: 18px;
    & > svg {
        width: 18px;
        height: 18px;
    }
    margin-right: 8px;
`;

const RowText = styled.div`
    font-size: 14px;
    text-transform: capitalize;
`;

interface Props<T, R> {
    className?: string;
    handleSelectionGenerator: (key: T) => VoidFunction;
    options: R;
    filterName: string;
}

const FilterDropdown = <T extends keyof R, R extends object>({
    className,
    options,
    handleSelectionGenerator,
    filterName
}: Props<T, R>) => {
    const [isOpen, setIsOpen] = useState(false);
    const { refs, floatingStyles, context } = useFloating({
        placement: "bottom-start",
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [offset({ mainAxis: 10 }), flip({ padding: 66 }), shift()]
    });
    const click = useClick(context);
    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss
    ]);

    const renderButtonText = () => {
        const full = Object.keys(options);
        const selected = Object.entries(options)
            .filter(([key, value]) => !!value)
            .map(([key, value]) => key);

        if (full.length === selected.length) return `All ${filterName}`;
        if (selected.length === 0) return `No ${filterName} Selected`;
        return selected.join(", ").replace(/_/g, " ");
    };

    return (
        <>
            <Button
                className={className}
                ref={refs.setReference}
                {...getReferenceProps()}
            >
                <div>{renderButtonText()}</div>
                <UpCaret src={"/caret.svg"} isOpen={isOpen} />
            </Button>
            {isOpen && (
                <FloatingPortal>
                    <Dropdown
                        ref={refs.setFloating}
                        style={floatingStyles}
                        {...getFloatingProps()}
                    >
                        {Object.entries(options).map(([option, active]) => (
                            <Row
                                key={option}
                                active={active}
                                onClick={handleSelectionGenerator(option as T)}
                            >
                                <Selection>
                                    {active ? (
                                        <SelectedIcon />
                                    ) : (
                                        <UnselectedIcon />
                                    )}
                                </Selection>
                                <RowText>{option.replace(/_/g, " ")}</RowText>
                            </Row>
                        ))}
                    </Dropdown>
                </FloatingPortal>
            )}
        </>
    );
};
export default FilterDropdown;
