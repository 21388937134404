import { NormalizedSchema } from "normalizr";

export interface Action {
    type: string;
    payload: any;
}

export type NormalizedEntity<T> = { [key: string]: { [key: string]: T } };

export type NormalizedResponse<E, R> = NormalizedSchema<NormalizedEntity<E>, R>;

export interface NormalizedActionGeneric<E, R> {
    type: string;
    response: NormalizedResponse<E, R>;
    payload?: any;
}

export const LOCATION_CHANGE = "@@router/LOCATION_CHANGE";
