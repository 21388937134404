import React from "react";
import styled from "styled-components/macro";
import { colors } from "../constants";

interface TabProps {
    active: boolean;
}

const Main = styled("div")<TabProps>`
    flex: 1;
    padding: 20px 0px;
    color: ${props => (props.active ? "black" : colors.primaryGray)};
    font-size: 14px;
    line-height: 14px;
    transition: 0.1s ease-in;
    user-select: none;
    cursor: pointer;

    @media (pointer: fine) {
        background-color: ${props => props.active && colors.lightTeal};
    }

    @media (hover: hover) {
        :hover {
            background-color: ${props => !props.active && colors.lightGray};
        }
    }

    @media (max-width: 910px) {
        border-bottom: ${props => props.active && `${colors.black} solid 1px;`};
    }
`;

interface Props {
    name: string;
    active: boolean;
    onClick: () => void;
    className?: string;
}

const Tab = ({ name, active, onClick, className }: Props) => {
    return (
        <Main active={active} onClick={onClick} className={className}>
            {name}
        </Main>
    );
};

export default Tab;
