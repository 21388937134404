import React, {
    Ref,
    forwardRef,
    useImperativeHandle,
    useRef,
    useState
} from "react";
import styled, { css } from "styled-components/macro";
import { colors } from "../../constants";
import TextEditor, { TextEditorRef } from "../../components/text-editor";
import { Descendant, Editor } from "slate";
import MessagePreview from "./MessagePreview";
import { useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { getLatestMessageFromAdmin } from "../selectors";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { AdvertiserReviewStatus } from "../../types/Creative";
import RuleSet from "../../rule-set";
import CustomEditor from "../../components/text-editor/CustomEditor";
import CreativeRevisionHistory from "./CreativeRevisionHistory";
import CreativeActionBox from "./CreativeActionBox";

const Main = styled.div`
    font-size: 14px;
    border-radius: 4px;
    background-color: ${colors.white};

    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const Scroll = styled.div`
    margin: 24px 24px 0 24px;
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    & > * ~ * {
        padding-top: 20px;
        border-top: 1px solid ${colors.neutral300};
        margin-top: 20px;
    }
`;

const StyledScriptPreview = styled(MessagePreview)`
    background-color: white;
    @media (max-width: 1120px) {
        width: 100%;
    }
`;

const toolbarStyles = css`
    margin-bottom: 10px;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 2;
    font-size: 14px;
`;

const editableStyles = css`
    height: 15em;
    overflow-y: auto;
    margin-bottom: 8px;
    padding: 2px 6px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
`;

const MessageSection = styled(Section)`
    padding: 24px;
    border-top: 1px solid ${colors.neutral300};
    padding-top: 20px;
`;

const Title = styled.div`
    font-size: 14px;
    color: ${colors.neutral900};
    font-weight: 700;
`;

const AdminMessage = styled.div`
    font-weight: 500;
    color: ${colors.neutral1000};
    margin-top: 10px;

    p {
        margin-bottom: 8px;
    }

    a {
        color: ${colors.primary700};
        cursor: pointer;
    }
`;

const Notice = styled.div`
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 16px;
`;

const Tabs = styled.div`
    margin: 24px 24px 0 24px;
    display: flex;
    gap: 16px;
`;

const TabItem = styled.div<{ active?: boolean }>`
    text-align: center;
    flex: 1;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 700;
    padding: 6px 0;
    color: ${colors.neutral500};
    cursor: pointer;

    &:hover {
        background-color: ${colors.primary100};
        color: ${colors.neutral1000};
    }

    ${props =>
        props.active &&
        `
        border-bottom: 1px solid ${colors.neutral1000};
        color: ${colors.neutral1000}
    `}
`;

export type MessageFormRef = {
    controlEditor: (callback: (editor: Editor) => void) => void;
};

enum DisplayTab {
    Feedback = "Feedback",
    RevisionHistory = "RevisionHistory"
}

interface Props {
    className?: string;
    creativeId: number;
    ruleSetId: number | null;
    status: AdvertiserReviewStatus;
}

const CreativeMessageForm = (
    { className, creativeId, status, ruleSetId }: Props,
    ref: Ref<MessageFormRef>
) => {
    const {
        adminMessageEvent,
        latestAdvertiserMessageEvent
    } = useSelector((state: GlobalState) =>
        getLatestMessageFromAdmin(state, creativeId)
    );

    const [tab, setTab] = useState<DisplayTab>(DisplayTab.Feedback);
    const [editorContent, setEditorContent] = useState<Descendant[]>([]);
    const [isError, setIsError] = useState(false);

    const textEditorRef = useRef<TextEditorRef>(null);
    useImperativeHandle(ref, () => ({
        controlEditor: (callback: (editor: Editor) => void) => {
            textEditorRef.current?.controlEditor(callback);
        }
    }));

    const handleAddRule = (rule: string, remove?: boolean) => {
        textEditorRef.current?.controlEditor((editor: Editor) => {
            CustomEditor.insertRule(editor, rule);
        });
    };

    return (
        <Main className={className}>
            <Tabs>
                <TabItem
                    active={tab === DisplayTab.Feedback}
                    onClick={() => setTab(DisplayTab.Feedback)}
                >
                    FEEDBACK
                </TabItem>
                <TabItem
                    active={tab === DisplayTab.RevisionHistory}
                    onClick={() => setTab(DisplayTab.RevisionHistory)}
                >
                    REVISION HISTORY
                </TabItem>
            </Tabs>
            {tab === DisplayTab.RevisionHistory && (
                <CreativeRevisionHistory creativeId={creativeId} />
            )}
            {tab === DisplayTab.Feedback && (
                <Scroll>
                    {adminMessageEvent && (
                        <Section>
                            <Title>Message from Liftoff Influence</Title>
                            <AdminMessage>
                                <ReactMarkdown>
                                    {adminMessageEvent.eventExtra
                                        .advertiserReviewRequest || ""}
                                </ReactMarkdown>
                            </AdminMessage>
                        </Section>
                    )}
                    <RuleSet
                        handleAddRule={handleAddRule}
                        ruleSetId={ruleSetId}
                        actionable={status === "pending"}
                    />

                    {status !== "pending" && latestAdvertiserMessageEvent && (
                        <Section>
                            <Title>Your Message</Title>
                            <AdminMessage>
                                <ReactMarkdown>
                                    {latestAdvertiserMessageEvent.eventExtra
                                        .advertiserMessage || ""}
                                </ReactMarkdown>
                            </AdminMessage>
                        </Section>
                    )}
                </Scroll>
            )}
            {status === "pending" && (
                <MessageSection>
                    <Title>Your message</Title>
                    <Notice>
                        Please give comments that are related to the do’s and
                        don’ts
                    </Notice>
                    <TextEditor
                        ref={textEditorRef}
                        initialText={""}
                        autoFocus={true}
                        editorContent={editorContent}
                        setEditorContent={setEditorContent}
                        handleChange={(newContent: Descendant[]) => {
                            setEditorContent(newContent);
                        }}
                        setIsError={setIsError}
                        toolbarStyles={toolbarStyles}
                        editableStyles={editableStyles}
                        placeholder={""}
                        PreviewComponent={StyledScriptPreview}
                    />

                    <CreativeActionBox
                        creativeId={creativeId}
                        editorContent={editorContent}
                    />
                </MessageSection>
            )}
        </Main>
    );
};
export default forwardRef(CreativeMessageForm);
