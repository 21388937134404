import React, { useState } from "react";
import {
    FloatingPortal,
    flip,
    offset,
    safePolygon,
    shift
} from "@floating-ui/react";
import { useFloating, useHover, useInteractions } from "@floating-ui/react";
import styled from "styled-components/macro";
import { colors } from "../../constants";
import { ReactComponent as PendingIcon } from "../../components/icons/creatives_pending_review_icon.svg";
import { ReactComponent as FeedbackSubmittedIcon } from "../../components/icons/creatives_feedback_submitted.svg";
import { ReactComponent as ApprovedIcon } from "../../components/icons/creatives_approved_icon.svg";
import { ReactComponent as RejectedIcon } from "../../components/icons/creatives_rejected_icon.svg";
import { AdvertiserReviewStatus } from "../../types/Creative";
import moment from "moment";

const PendingReview = styled.div<{
    iconColor: string;
    backgroundColor: string;
}>`
    border-radius: 5px;
    background-color: ${props => props.backgroundColor};
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.iconColor};
    user-select: none;
    min-height: 21px;
    min-width: 21px;
    box-sizing: border-box;
`;

const PendingText = styled.div`
    font-family: Poppins;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    margin-left: 4px;
    margin-right: 2px;
    color: ${colors.neutral1000};
`;

const Tooltip = styled.div`
    background-color: ${colors.white};
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 8px 16px;
    border-radius: 10px;
    font-size: 10px;
    font-weight: 500;
`;

interface Props {
    status: AdvertiserReviewStatus;
    latestEventTime: string;
    className?: string;
}

const CreativeStatusIcon = ({ status, latestEventTime, className }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const { refs, floatingStyles, context } = useFloating({
        placement: "top-start",
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            offset({ mainAxis: -5, crossAxis: 5 }),
            flip({ padding: 66 }),
            shift()
        ]
    });
    const hover = useHover(context, { handleClose: safePolygon() });
    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

    const renderStatus = () => {
        switch (status) {
            case "pending":
                const days = moment().diff(moment(latestEventTime), "days");
                return {
                    icon: <PendingIcon />,
                    tooltip: "Days pending review",
                    backgroundColor:
                        days >= 5 ? colors.error100 : colors.pending100,
                    iconColor: days >= 5 ? colors.error900 : colors.pending900,
                    text: days
                };
            case "revision_requested":
                return {
                    icon: <FeedbackSubmittedIcon />,
                    tooltip: "Feedback submitted",
                    backgroundColor: colors.mediumTeal,
                    iconColor: colors.neutral1000,
                    text: null
                };
            case "approved":
                return {
                    icon: <ApprovedIcon />,
                    tooltip: "Approved",
                    backgroundColor: colors.success700,
                    iconColor: colors.neutral0,
                    text: null
                };

            default:
                return {
                    icon: <RejectedIcon />,
                    tooltip: "Rejected",
                    backgroundColor: colors.error900,
                    iconColor: colors.neutral0,
                    text: null
                };
        }
    };

    return (
        <>
            <PendingReview
                className={className}
                ref={refs.setReference}
                {...getReferenceProps()}
                iconColor={renderStatus().iconColor}
                backgroundColor={renderStatus().backgroundColor}
            >
                {renderStatus().icon}
                {renderStatus().text && (
                    <PendingText>{renderStatus().text}</PendingText>
                )}
            </PendingReview>
            {isOpen && (
                <FloatingPortal>
                    <Tooltip
                        ref={refs.setFloating}
                        style={floatingStyles}
                        {...getFloatingProps()}
                    >
                        {renderStatus().tooltip}
                    </Tooltip>
                </FloatingPortal>
            )}
        </>
    );
};
export default CreativeStatusIcon;
