import styled from "styled-components/macro";
import { rebrandColors } from "../../constants";
import LinkText from "./LinkText";

const LinkContainer = styled.div<{ active?: boolean }>`
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding-left: 15px;
    border-left: 4px solid transparent;
    height: 48px;
    font-family: Poppins;
    font-weight: 500;
    color: ${rebrandColors["neutral-500"]};

    ${props =>
        props.active &&
        `border-left: 4px solid ${rebrandColors["primary-700"]};
        color: ${rebrandColors["primary-700"]};
        & > ${LinkText} {
            color: ${rebrandColors["primary-700"]};
        }
        `};

    &:hover {
        background-color: ${rebrandColors["neutral-100"]};
    }
`;

export default LinkContainer;
