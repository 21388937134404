import React, { useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { setPassword, SetPasswordParams } from "./actions";
import styled from "styled-components/macro";
import { getUrlParameter } from "../util";
import { AuthButton, InputBlock } from "../components/styledComponents";
import AuthModal from "../components/ResponseModal";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface Props {
    setPassword: (setPasswordParams: SetPasswordParams) => void;
    error: string | null;
}

const SetPassword = ({ setPassword, error }: Props) => {
    const [firstPassword, setFirstPassword] = useState("");
    const [secondPassword, setSecondPassword] = useState("");

    function allowSubmit() {
        return firstPassword === secondPassword && firstPassword.length > 7;
    }

    return (
        <Main>
            <h2>Set your password</h2>
            <InputBlock
                value={firstPassword}
                placeholder="Password"
                type="password"
                onChange={e => setFirstPassword(e.currentTarget.value)}
                onKeyDown={e => {
                    if (e.key === "Enter" && allowSubmit()) {
                        e.preventDefault();
                        setPassword({
                            token: getUrlParameter("token"),
                            password: firstPassword
                        });
                    }
                }}
            />
            <InputBlock
                value={secondPassword}
                placeholder="Confirm Password"
                type="password"
                onChange={e => setSecondPassword(e.currentTarget.value)}
                onKeyDown={e => {
                    if (e.key === "Enter" && allowSubmit()) {
                        e.preventDefault();
                        setPassword({
                            token: getUrlParameter("token"),
                            password: firstPassword
                        });
                    }
                }}
            />
            <AuthButton
                disabled={!allowSubmit()}
                onClick={() => {
                    if (allowSubmit()) {
                        setPassword({
                            token: getUrlParameter("token"),
                            password: firstPassword
                        });
                    }
                }}
            >
                Submit
            </AuthButton>
            {error && <AuthModal error={error} />}
        </Main>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    error: state.auth.error
});

const mapDispatchToProps = (dispatch: any) => ({
    setPassword: (setPasswordParams: SetPasswordParams) =>
        dispatch(setPassword(setPasswordParams))
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
