import {
    PayloadAction,
    createAction,
    createEntityAdapter,
    createSlice
} from "@reduxjs/toolkit";
import { GlobalState } from "../reducers";
import { Script } from "../types/Script";
import { CustomAdminMediaUpload } from "../scripts/components/ConceptModal";
import { Asset } from "../types/challenge/Asset";
import { deleteSuccess as assetDeleteSuccess } from "../assets/slice";
import { NormalizedSchema } from "normalizr";

const scriptsAdapter = createEntityAdapter<Script>();

export interface CreateScriptAction {
    openBuyId: number;
    mediaUploadData: CustomAdminMediaUpload[];
    title: string;
    text: string;
}

export interface UpdateScriptAction {
    id: number;
    mediaUploadData: CustomAdminMediaUpload[];
    title: string;
    text: string;
}

export interface ScriptAssetNormalizedEntities {
    assets: { [id: number]: Asset };
    scripts: { [id: number]: Script };
}

export interface ScriptAssetNormalizedAction<T> {
    normalized: NormalizedSchema<ScriptAssetNormalizedEntities, T>;
    openBuyId: number;
}

const scriptsSlice = createSlice({
    name: "scripts",
    initialState: scriptsAdapter.getInitialState(),
    reducers: {
        fetchSuccess: (
            state,
            action: PayloadAction<ScriptAssetNormalizedAction<number[]>>
        ) => {
            if (action.payload.normalized.result.length) {
                scriptsAdapter.addMany(
                    state,
                    action.payload.normalized.entities.scripts
                );
            }
        },
        createSuccess: (
            state,
            action: PayloadAction<ScriptAssetNormalizedAction<number>>
        ) => {
            scriptsAdapter.upsertMany(
                state,
                action.payload.normalized.entities.scripts
            );
        },
        deleteSuccess: (state, action: PayloadAction<{ scriptId: number }>) => {
            scriptsAdapter.removeOne(state, action.payload.scriptId);
        },
        updateSuccess: (
            state,
            action: PayloadAction<
                NormalizedSchema<ScriptAssetNormalizedEntities, number[]>
            >
        ) => {
            scriptsAdapter.upsertMany(state, action.payload.entities.scripts);
        }
    },
    extraReducers: builder => {
        builder.addCase(
            assetDeleteSuccess,
            (
                state,
                action: PayloadAction<{ scriptId: number; assetId: number }>
            ) => {
                const { assetId, scriptId } = action.payload;
                scriptsAdapter.updateOne(state, {
                    id: scriptId,
                    changes: {
                        Assets: state.entities[scriptId]?.Assets.filter(
                            el => el !== assetId
                        )
                    }
                });
            }
        );
    }
});

const scriptsReducer = scriptsSlice.reducer;

export const {
    fetchSuccess,
    createSuccess,
    deleteSuccess,
    updateSuccess
} = scriptsSlice.actions;
export const create = createAction<CreateScriptAction>("scripts/create");
export const fetch = createAction<{ openBuyId: number }>("scripts/fetch");
export const update = createAction<UpdateScriptAction>("scripts/update");
export const deleteScript = createAction<{ scriptId: number }>(
    "scripts/delete"
);

export const { selectById } = scriptsAdapter.getSelectors(
    (state: GlobalState) => state.entities.scripts
);

export default scriptsReducer;
