import {
    all,
    call,
    put,
    select,
    takeEvery,
    takeLatest
} from "redux-saga/effects";
import {
    ADD_PENDING_BID_UPDATES,
    ADD_PENDING_PUBLISHER_BID_UPDATES,
    addPendingBidUpdatesFailure,
    CREATE_NEW_CAMPAIGN,
    createNewCampaignFailure,
    EDIT_CAMPAIGN,
    editCampaignFailure,
    FETCH_CAMPAIGNS,
    FETCH_PUBLISHER_BIDS_FOR_CAMPAIGN,
    FETCH_REPORT,
    fetchCampaignsFailure,
    fetchCampaignsSuccess,
    fetchPublisherBidsForCampaign,
    fetchPublisherBidsForCampaignFailure,
    fetchPublisherBidsForCampaignSuccess,
    fetchReportFailure,
    fetchReportSuccess,
    GET_BIDS_FOR_CAMPAIGN,
    GET_DAILY_SPEND,
    GET_MEDIA_REPORT,
    GET_PLATFORM_TRAFFIC,
    GET_REGION_TRAFFIC,
    GET_VERIFIED_PUBLISHERS,
    getBidsForCampaign,
    getBidsForCampaignFailure,
    getBidsForCampaignSuccess,
    getDailySpendFailure,
    getDailySpendSuccess,
    getMediaReportFailure,
    getMediaReportSuccess,
    getPlatformTrafficFailure,
    getPlatformTrafficSuccess,
    getRegionTrafficFailure,
    getRegionTrafficSuccess,
    getVerifiedPublishersSuccess,
    REMOVE_MEDIA_FROM_APP,
    removeMediaFromAppFailure,
    REQUEST_CAMPAIGN_RESTART,
    requestCampaignRestartFailure,
    requestCampaignRestartSuccess,
    submitPendingPublisherBidUpdatesFailure,
    UPLOAD_MEDIA,
    GET_MEDIA_TEST,
    getMediaTestSuccess,
    getMediaTestFailure,
    SUBMIT_TEST_MEDIA_PURCHASE,
    submitTestMediaPurchaseRequestSuccess,
    noticeModal
} from "./actions";
import { Action } from "../types";
import { get, post } from "../Api";
import { push } from "connected-react-router";
import { errorToString } from "../util";
import { addNotification, submitEvent } from "../dashboard/actions";
import { clearModal } from "../components/Modal";
import { selectCurrentUserEmail } from "../auth/selectors";
import { isAxiosError } from "axios";

function* fetchCampaignsRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            get,
            "/dashboard/api/get_campaigns_for_advertiser_combo",
            payload
        );

        if (status === 200) {
            yield put(fetchCampaignsSuccess(data));
        } else {
            yield put(fetchCampaignsFailure("Fetch campaigns failure"));
        }
    } catch (error) {
        yield put(fetchCampaignsFailure(errorToString(error)));
    }
}

function* fetchReportRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(get, "/reporting_api/v1/bqReport", {
            start_date: payload.start_date,
            end_date: payload.end_date,
            columns: payload.columns.join(","),
            format: "json"
        });

        if (status === 200) {
            yield put(fetchReportSuccess(data.data));
        } else {
            yield put(fetchReportFailure(data.message));
        }
    } catch (error) {
        yield put(fetchReportFailure(errorToString(error)));
    }
}

function* getDailySpendRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(get, "/reporting_api/v1/bqReport", {
            start_date: payload.start_date,
            end_date: payload.end_date,
            columns: "campaign,campaign_name,date,cost",
            format: "json"
        });

        if (status === 200) {
            yield put(getDailySpendSuccess(data.data));
        } else {
            yield put(getDailySpendFailure(data.message));
        }
    } catch (error) {
        yield put(getDailySpendFailure(errorToString(error)));
    }
}

function* getMediaReportRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(get, "/reporting_api/v1/bqReport", {
            start_date: payload.start_date,
            end_date: payload.end_date,
            columns:
                "campaign,campaign_name,media,media_url,clicks,installs,cost",
            format: "json"
        });

        if (status === 200) {
            yield put(getMediaReportSuccess(data.data));
        } else {
            yield put(getMediaReportFailure(data.message));
        }
    } catch (error) {
        yield put(getMediaReportFailure(errorToString(error)));
    }
}

function* getMediaTestRequest({
    payload: { startDate, endDate, campaignId }
}: Action) {
    try {
        const { data } = yield call(
            get,
            "/dashboard/api/get_test_media_results_for_campaign",
            {
                startDate,
                endDate,
                campaignId
            }
        );
        yield put(getMediaTestSuccess(data));
    } catch (error) {
        if (isAxiosError(error)) {
            yield put(getMediaTestFailure(error.message));
        }
    }
}

function* requestCampaignRestartRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/dashboard/api/request_campaign_restart",
            payload
        );

        if (status === 200) {
            yield put(requestCampaignRestartSuccess(payload.campaign_id));
            yield put(submitEvent("advertiser_restart_campaign"));
        } else {
            yield put(requestCampaignRestartFailure(data));
        }
    } catch (error) {
        if (isAxiosError(error)) {
            yield put(requestCampaignRestartFailure(error.message));
        }
    }
}

function* createNewCampaignRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/dashboard/api/create_new_campaign",
            payload
        );

        if (status === 200) {
            yield put(push("/campaigns"));
            yield put(addNotification("Campaign created successfully"));
            yield put(submitEvent("advertiser_create_campaign"));
        } else {
            yield put(createNewCampaignFailure(data));
        }
    } catch (error) {
        yield put(createNewCampaignFailure(errorToString(error)));
    }
}

function* editCampaignRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/dashboard/api/edit_campaign",
            payload
        );

        if (status === 200) {
            yield put(push("/campaigns"));
            yield put(addNotification("Campaign edited successfully"));
        } else {
            yield put(editCampaignFailure(data));
        }
    } catch (error) {
        yield put(editCampaignFailure(errorToString(error)));
    }
}

function* getBidsForCampaignRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            get,
            "/dashboard/api/get_bids_for_campaign",
            {
                ...payload,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        );

        if (status === 200) {
            yield put(getBidsForCampaignSuccess(data));
        } else {
            yield put(getBidsForCampaignFailure(data));
        }
    } catch (error) {
        yield put(getBidsForCampaignFailure(errorToString(error)));
    }
}

function* fetchPublisherBidsForCampaignRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            get,
            "/dashboard/api/get_publisher_bids_for_campaign",
            {
                ...payload,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        );

        if (status === 200) {
            yield put(fetchPublisherBidsForCampaignSuccess(data));
        } else {
            yield put(fetchPublisherBidsForCampaignFailure(data));
        }
    } catch (error) {
        yield put(fetchPublisherBidsForCampaignFailure(errorToString(error)));
    }
}

function* getPlatformTrafficRequest() {
    try {
        const { data, status } = yield call(
            get,
            "/dashboard/api/get_platform_traffic_percent",
            {}
        );

        if (status === 200) {
            yield put(getPlatformTrafficSuccess(data));
        } else {
            yield put(getPlatformTrafficFailure(data));
        }
    } catch (error) {
        yield put(getPlatformTrafficFailure(errorToString(error)));
    }
}

function* getRegionTrafficRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            get,
            "/dashboard/api/get_region_traffic_percent",
            payload
        );

        if (status === 200) {
            yield put(getRegionTrafficSuccess(data));
        } else {
            yield put(getRegionTrafficFailure(data));
        }
    } catch (error) {
        yield put(getRegionTrafficFailure(errorToString(error)));
    }
}

function* getVerifiedPublishersRequest() {
    try {
        const { data } = yield call(
            get,
            "/dashboard/api/get_verified_publishers",
            {}
        );

        yield put(getVerifiedPublishersSuccess(data));
    } catch (error) {}
}

function* removeMediaFromAppRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/dashboard/api/remove_media_from_app",
            payload
        );

        if (status === 200) {
            yield put(clearModal());
            yield put(
                addNotification("Remove media request submitted successfully")
            );
        } else {
            yield put(removeMediaFromAppFailure(data));
        }
    } catch (error) {
        yield put(removeMediaFromAppFailure(errorToString(error)));
    }
}

function* addPendingBidUpdatesRequest({ payload }: Action) {
    try {
        const currentUser: string | undefined = yield select(
            selectCurrentUserEmail
        );

        const { data, status } = yield call(
            post,
            "/dashboard/api/add_pending_price_updates",
            { ...payload, ...(currentUser && { current_user: currentUser }) }
        );

        if (status === 200) {
            yield put(clearModal());
            yield put(
                addNotification("Bid update request submitted successfully")
            );
            yield put(getBidsForCampaign(payload.campaign_id));
        } else {
            yield put(addPendingBidUpdatesFailure(data));
        }
    } catch (error) {
        yield put(addPendingBidUpdatesFailure(errorToString(error)));
    }
}

function* submitPendingPublisherBidUpdatesRequest({ payload }: Action) {
    try {
        const currentUser: string | undefined = yield select(
            selectCurrentUserEmail
        );

        const { data, status } = yield call(
            post,
            "/dashboard/api/add_pending_publisher_price_updates",
            { ...payload, ...(currentUser && { current_user: currentUser }) }
        );

        if (status === 200) {
            yield put(clearModal());
            yield put(
                addNotification(
                    "Publisher bid update request submitted successfully"
                )
            );
            yield put(fetchPublisherBidsForCampaign(payload.campaign_id));
        } else {
            yield put(submitPendingPublisherBidUpdatesFailure(data));
        }
    } catch (error) {
        yield put(
            submitPendingPublisherBidUpdatesFailure(errorToString(error))
        );
    }
}

function* uploadMediaRequest({ payload }: Action) {
    try {
        yield call(post, "/dashboard/api/upload_advertiser_media", payload);
        yield put(clearModal());
        yield put(addNotification("New media submitted successfully"));
    } catch (error) {
        if (isAxiosError(error)) {
            window.alert(`File upload failed: ${error.toString()}`);
        }
    }
}

function* submitTestMediaPurchaseRequest({ payload }: Action) {
    try {
        yield all([
            call(post, "/dashboard/api/submit_event", {
                event_name: "advertiser_purchase_request",
                event_extra: payload
            }),
            call(
                post,
                "/dashboard/api/send_request_purchase_message_to_slack",
                payload
            )
        ]);

        yield put(submitTestMediaPurchaseRequestSuccess());
        yield put(
            noticeModal({
                title: "Purchase Requested!",
                content:
                    "Your request to purchase this asset has been received. We’ll be reviewing your request and getting back to you shortly!"
            })
        );
    } catch (error) {
        yield put(
            submitPendingPublisherBidUpdatesFailure(errorToString(error))
        );
    }
}

export default function* campaignsSaga() {
    yield all([
        takeEvery(FETCH_CAMPAIGNS.REQUEST, fetchCampaignsRequest),
        takeEvery(FETCH_REPORT.REQUEST, fetchReportRequest),
        takeEvery(GET_DAILY_SPEND.REQUEST, getDailySpendRequest),
        takeEvery(GET_MEDIA_REPORT.REQUEST, getMediaReportRequest),
        takeLatest(GET_MEDIA_TEST.REQUEST, getMediaTestRequest),
        takeLatest(
            SUBMIT_TEST_MEDIA_PURCHASE.REQUEST,
            submitTestMediaPurchaseRequest
        ),
        takeEvery(
            REQUEST_CAMPAIGN_RESTART.REQUEST,
            requestCampaignRestartRequest
        ),
        takeEvery(CREATE_NEW_CAMPAIGN.REQUEST, createNewCampaignRequest),
        takeEvery(EDIT_CAMPAIGN.REQUEST, editCampaignRequest),
        takeEvery(GET_BIDS_FOR_CAMPAIGN.REQUEST, getBidsForCampaignRequest),
        takeEvery(
            FETCH_PUBLISHER_BIDS_FOR_CAMPAIGN.REQUEST,
            fetchPublisherBidsForCampaignRequest
        ),
        takeEvery(GET_PLATFORM_TRAFFIC.REQUEST, getPlatformTrafficRequest),
        takeEvery(GET_REGION_TRAFFIC.REQUEST, getRegionTrafficRequest),
        takeEvery(
            GET_VERIFIED_PUBLISHERS.REQUEST,
            getVerifiedPublishersRequest
        ),
        takeEvery(REMOVE_MEDIA_FROM_APP.REQUEST, removeMediaFromAppRequest),
        takeEvery(ADD_PENDING_BID_UPDATES.REQUEST, addPendingBidUpdatesRequest),
        takeEvery(
            ADD_PENDING_PUBLISHER_BID_UPDATES.REQUEST,
            submitPendingPublisherBidUpdatesRequest
        ),
        takeEvery(UPLOAD_MEDIA.REQUEST, uploadMediaRequest)
    ]);
}
