import React, { useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { loginUser, LoginUserParams } from "./actions";
import { push } from "connected-react-router";
import styled from "styled-components/macro";
import { colors } from "../constants";
import { AuthButton, InputBlock } from "../components/styledComponents";
import AuthModal from "../components/ResponseModal";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Reset = styled.span`
    margin-top: 15px;
    font-size: 13px;
`;

const Link = styled.span`
    color: ${colors.primary700};
    cursor: pointer;
`;

interface Props {
    loginUser: (loginUserParams: LoginUserParams) => void;
    push: (path: string) => void;
    error: string | null;
}

const LoginUser = ({ loginUser, push, error }: Props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    return (
        <Main>
            <h2>Login</h2>
            <InputBlock
                value={email}
                placeholder="Email"
                type="email"
                onChange={e => setEmail(e.currentTarget.value)}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        loginUser({ email, password });
                    }
                }}
            />
            <InputBlock
                value={password}
                placeholder="Password"
                type="password"
                onChange={e => setPassword(e.currentTarget.value)}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        loginUser({ email, password });
                    }
                }}
            />
            <AuthButton onClick={() => loginUser({ email, password })}>
                Submit
            </AuthButton>
            <Reset>
                {"Forget you password? Reset "}
                <Link onClick={() => push("/login/reset-password")}>here</Link>
            </Reset>
            {error && <AuthModal error={error} />}
        </Main>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    error: state.auth.error
});

const mapDispatchToProps = (dispatch: any) => ({
    loginUser: (loginUserParams: LoginUserParams) =>
        dispatch(loginUser(loginUserParams)),
    push: (path: string) => dispatch(push(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginUser);
