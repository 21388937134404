import { schema } from "normalizr";

const asset = new schema.Entity("assets");
const assetList = new schema.Array(asset);

export const script = new schema.Entity(
    "scripts",
    { Assets: assetList },
    {
        processStrategy: (value, parent, key) => {
            const newScript = {
                ...value,
                openBuyId: value.OpenBuys && value.OpenBuys[0]?.id
            };
            delete newScript.OpenBuys;
            return newScript;
        }
    }
);
export const scriptList = new schema.Array(script);
