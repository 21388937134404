import React from "react";
import SimpleLoader from "./SimpleLoader";
import styled from "styled-components/macro";

const ResizedSpinner = styled(SimpleLoader)`
    width: 16px;
    height: 16px;
`;

const ButtonLoader = () => {
    return <ResizedSpinner />;
};

export default ButtonLoader;
