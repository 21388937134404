import React from "react";
import styled from "styled-components/macro";
import { rebrandColors } from "../constants";
import { Route, Switch } from "react-router";
import { User } from "./Profile";
import Users from "../users";
import ReportingAPI from "../reportingAPI";
import Footer from "../components/Footer";
import FAQ from "../faq";
import Challenge from "../challenges";

const Main = styled.div`
    margin: 66px 0px 0px 252px;
    padding: 0 40px;
    box-sizing: border-box;
    min-height: calc(100vh - 66px);
    background-color: ${rebrandColors["neutral-100"]};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const MainContent = ({ user }: { user: User }) => {
    function includeUsers() {
        if (user.is_admin) {
            return <Route path="/users" component={Users} />;
        }
    }

    return (
        <Main>
            <Switch>
                {includeUsers()}
                <Route path="/api" component={ReportingAPI} />
                <Route path="/faq" component={FAQ} />
                <Route
                    path="/challenge/:openBuyId"
                    render={({ match }) => {
                        const openBuyId = parseInt(match.params.openBuyId);
                        return <Challenge openBuyId={openBuyId} />;
                    }}
                />

                {/* <Route path="/" component={Creatives} /> */}
            </Switch>
            <Footer />
        </Main>
    );
};

export default MainContent;
