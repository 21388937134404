import React from "react";
import { AdvertiserReviewStatus } from "../../types/Creative";
import { useDispatch, useSelector } from "react-redux";
import { StatusFilterState, setFilter } from "../../ui/creatives/slice";
import { GlobalState } from "../../reducers";
import FilterDropdown from "../../components/FilterDropdown";

interface Props {
    className?: string;
}

const CreativeFilterDropdown = ({ className }: Props) => {
    const dispatch = useDispatch();
    const statuses = useSelector(
        (state: GlobalState) => state.ui.creatives.statusFilter
    );

    const handleSelectionGenerator = (type: AdvertiserReviewStatus) => {
        return () => {
            dispatch(setFilter({ ...statuses, [type]: !statuses[type] }));
        };
    };

    return (
        <FilterDropdown<AdvertiserReviewStatus, StatusFilterState>
            className={className}
            handleSelectionGenerator={handleSelectionGenerator}
            options={statuses}
            filterName="Statuses"
        />
    );
};
export default CreativeFilterDropdown;
