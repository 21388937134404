import React, { useEffect } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { User } from "../dashboard/Profile";
import { getUsersForAdmin, usersInviteModal } from "./actions";
import styled from "styled-components/macro";
import RocketLoader from "../components/RocketLoader";
import UserRow from "./UserRow";
import { container } from "../constants";
import { WhitePillSpacedButton } from "../components/styledComponents";
import { UsersModal } from "./reducer";
import InviteModal from "./InviteModal";
import EditModal from "./EditModal";

const Header = styled.div`
    display: flex;
    align-items: center;
`;

const Container = styled.div`
    padding: 15px 40px;
    ${container}
    & > * ~ * {
        margin-top: 10px;
    }
`;

interface Props {
    users: User[];
    usersLoading: boolean;
    modal: UsersModal;
    usersInviteModal: () => void;
    getUsersForAdmin: () => void;
}

const Users = ({
    users,
    usersLoading,
    modal,
    usersInviteModal,
    getUsersForAdmin
}: Props) => {
    useEffect(() => {
        getUsersForAdmin();
    }, []);

    if (usersLoading) {
        return (
            <div>
                <h2>Users</h2>
                <Container>
                    <RocketLoader />
                </Container>
            </div>
        );
    } else {
        return (
            <div>
                <Header>
                    <h2>Users</h2>
                    <div style={{ marginLeft: "auto" }}>
                        <WhitePillSpacedButton onClick={usersInviteModal}>
                            <img
                                src={"/add_icon.svg"}
                                alt="Invite New Users Button"
                            />
                            <span>Invite New Users</span>
                        </WhitePillSpacedButton>
                    </div>
                </Header>
                <Container>
                    <UserRow />
                    {users.map((user, idx) => {
                        return <UserRow key={idx} user={user} />;
                    })}
                </Container>
                {modal === UsersModal.invite && <InviteModal />}
                {modal === UsersModal.edit && <EditModal />}
            </div>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    users: state.users.users,
    usersLoading: state.users.usersLoading,
    modal: state.users.modal
});

const mapDispatchToProps = (dispatch: any) => ({
    usersInviteModal: () => dispatch(usersInviteModal()),
    getUsersForAdmin: () => dispatch(getUsersForAdmin())
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
