import React from "react";
import styled from "styled-components";
import { colors } from "../constants";
import { FlexCentered } from "./commonStyles";
import { RedPillButton } from "./styledComponents";
import withModalPortal from "./withModalPortal";

const Main = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0%;
    ${FlexCentered};
    flex-direction: column;
    background-color: ${colors.modalShade};
    font-family: Poppins;
`;

const Content = styled.div`
    max-width: 480px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: ${colors.white};
    overflow: hidden;
    font-family: Poppins;

    padding: 24px;

    & > * ~ * {
        margin-top: 20px;
    }
`;

const StepFooter = styled.div`
    min-height: 0px;
    display: flex;
    justify-content: flex-end;

    & > * ~ * {
        margin-left: 10px;
    }
`;

const ActionButton = styled(RedPillButton)`
    height: 36px;
`;

const CancelButton = styled(RedPillButton)`
    height: 36px;
    border: 1px solid ${colors.primary700};
    color: ${colors.primary700};
    background-color: ${colors.white};
`;

const Title = styled.div`
    color: ${colors.neutral1000};
    font-size: 24px;
    font-weight: 500;
`;

const Text = styled.div`
    color: ${colors.neutral900};
    font-size: 12px;
    font-weight: 400;
`;

interface Props {
    closeModal: () => void;
    onConfirm: () => void;
    title: string;
    text: string;
    buttonText: string;
    displayCancel?: boolean;
}

const ConfirmModal = ({
    closeModal,
    onConfirm,
    buttonText,
    title,
    text,
    displayCancel
}: Props) => {
    return (
        <Main onMouseDown={closeModal}>
            <Content onMouseDown={e => e.stopPropagation()}>
                <Title>{title}</Title>
                <Text>{text}</Text>
                <StepFooter>
                    {displayCancel && (
                        <CancelButton onClick={closeModal}>Cancel</CancelButton>
                    )}
                    <ActionButton onClick={onConfirm}>
                        {buttonText}
                    </ActionButton>
                </StepFooter>
            </Content>
        </Main>
    );
};
export default withModalPortal(ConfirmModal);
