import { Action } from "../types";
import { Advertiser, User } from "./Profile";
import {
    GET_ADVERTISER_USER_DATA,
    GET_ADVERTISERS_INDEX,
    GET_SUPPORT_EMAIL,
    LOGIN_AS_ADVERTISER_MODAL,
    NOTIFICATION,
    SUPPORT_MODAL
} from "./actions";
import { MODAL_CLEAR } from "../components/Modal";

export enum ProfileModal {
    clear,
    support,
    loginAsAdvertiser
}

export interface ProfileState {
    user?: User;
    advertiser?: Advertiser;
    supportEmail?: string;
    modal: ProfileModal;
    notification: string | null;
    advertisersIndex: Advertiser[];
}

const initialProfileState: ProfileState = {
    modal: ProfileModal.clear,
    notification: null,
    advertisersIndex: []
};

const reducer = (state = initialProfileState, action: Action) => {
    switch (action.type) {
        case MODAL_CLEAR:
            return { ...state, modal: ProfileModal.clear };
        case SUPPORT_MODAL:
            return { ...state, modal: ProfileModal.support };
        case LOGIN_AS_ADVERTISER_MODAL:
            return { ...state, modal: ProfileModal.loginAsAdvertiser };
        case GET_ADVERTISERS_INDEX.SUCCESS:
            return { ...state, ...action.payload };
        case NOTIFICATION.ADD:
            return { ...state, ...action.payload };
        case NOTIFICATION.REMOVE:
            return { ...state, notification: null };
        case GET_ADVERTISER_USER_DATA.SUCCESS:
            return { ...state, ...action.payload };
        case GET_ADVERTISER_USER_DATA.FAILURE:
            return { ...state, ...action.payload };
        case GET_SUPPORT_EMAIL.SUCCESS:
            return { ...state, ...action.payload };
        case GET_SUPPORT_EMAIL.FAILURE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default reducer;
