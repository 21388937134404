import React from "react";
import styled, { keyframes } from "styled-components/macro";

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const Spinner = styled.img`
    animation: ${spinAnimation} 1s infinite linear;
    width: 51px;
    height: 51px;
`;

const SimpleLoader = ({ className }: { className?: string }) => {
    return <Spinner className={className} src="/spinner-lv-r4b.png" />;
};

export default SimpleLoader;
