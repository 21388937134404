import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { get } from "../sagas";
import RuleSetList from "./components/RuleSetList";
import { colors } from "../constants";

const Main = styled.div`
    border-radius: 4px;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    color: ${colors.neutral900};
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
`;

interface Props {
    ruleSetId: number | null;
    className?: string;
    handleAddRule: (rule: string, remove?: boolean) => void;
    actionable: boolean;
}

const RuleSet = ({
    ruleSetId,
    className,
    handleAddRule,
    actionable
}: Props) => {
    const [plugRuleSetId, setPlugRuleSetId] = useState<number>();
    const getPlugRuleSetId = async () => {
        const res = await get<{ id: string; ruleSetId: number }>(
            "ChallengesStandardMediaRulesSetId",
            "GzADIFgd3zDfZAgtQF1Y"
        );
        setPlugRuleSetId(res?.ruleSetId);
    };

    useEffect(() => {
        getPlugRuleSetId();
    }, []);
    return (
        <Main className={className}>
            <Title>Feedback checklist</Title>
            {ruleSetId && (
                <RuleSetList
                    ruleSetId={ruleSetId}
                    handleAddRule={handleAddRule}
                    actionable={actionable}
                />
            )}
            {plugRuleSetId && (
                <RuleSetList
                    ruleSetId={plugRuleSetId}
                    handleAddRule={handleAddRule}
                    actionable={actionable}
                />
            )}
        </Main>
    );
};
export default RuleSet;
