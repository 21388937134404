import React from "react";
import styled from "styled-components/macro";
import { colors } from "../constants";

const Main = styled.div`
    width: 100%;
    margin-top: 40px;
    padding: 24px 0 40px;
    /* border-top: 1px solid ${colors.mediumGray}; */
    color: ${colors.neutral500};
    font-size: 14px;
    line-height: 14px;
`;

const Footer = () => {
    return <Main>© {new Date().getFullYear()} Liftoff</Main>;
};

export default Footer;
