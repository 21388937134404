import React from "react";
import { connect } from "react-redux";
import Modal, { clearModal } from "./Modal";
import styled from "styled-components/macro";
import { colors } from "../constants";
import { ModalButton } from "./styledComponents";

const Main = styled.div`
    padding: 0 30px 30px;
    display: flex;
    flex-direction: column;
    text-align: left;
`;

const Message = styled.div`
    margin-bottom: 20px;
`;

const Error = styled(Message)`
    color: ${colors.error900};
`;

interface Props {
    message?: string;
    error?: string;
    clearModal: () => void;
}

const ResponseModal = ({ message, error, clearModal }: Props) => {
    return (
        <Modal>
            <Main>
                {message && <h2>Success!</h2>}
                {message && <Message>{message}</Message>}

                {error && <h2>Oops</h2>}
                {error && <Error>{error}</Error>}
                <div style={{ marginLeft: "auto" }}>
                    <ModalButton onClick={clearModal}>Close</ModalButton>
                </div>
            </Main>
        </Modal>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal())
});

export default connect(null, mapDispatchToProps)(ResponseModal);
