import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithCustomToken,
    onAuthStateChanged
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { post } from "./Api";
import { AxiosRequestConfig } from "axios";

initializeApp({
    apiKey: "AIzaSyCRYBeb5B5J0EJQr7-631BTwu4f6p9EsKc",
    projectId: "plugcoinprod",
    authDomain: "plugcoinprod.firebaseapp.com",
    databaseURL: "https://plugcoinprod.firebaseio.com",
    storageBucket: "plugcoinprod.appspot.com"
});

const auth = getAuth();

export const setFirebaseToken = async (firebaseToken: string) => {
    await signInWithCustomToken(auth, firebaseToken).catch((error: any) => {
        if (firebaseToken) {
            const code = error.code;
            const message = error.message;

            post("/dashboard/auth/submit_event", {
                event_name: "advertiser_firebase_auth_error",
                event_extra: {
                    firebaseError: `Status: ${code}, Message: ${message}`
                }
            });
        }
    });
};

export const logoutFirebase = async () => {
    auth.signOut();
};

onAuthStateChanged(auth, function(user: any) {
    const firebaseToken = localStorage.getItem("firebaseToken");

    if (!user && firebaseToken) setFirebaseToken(firebaseToken);
});

export const useFirebaseToken = async (config: AxiosRequestConfig) => {
    const firebaseIdToken = await auth.currentUser?.getIdToken();
    if (firebaseIdToken && config.headers) {
        config.headers.set("FirebaseToken", firebaseIdToken);
    }
    return config;
};

export function onFirebaseAuthStateChanged() {
    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, user => {
            if (user) {
                resolve(user);
            } else {
                reject(new Error("Oops!"));
            }
        });
    });
}

export const storage = getStorage();

export const database = getDatabase();

export const firestore = getFirestore();
