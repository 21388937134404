import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { colors } from "../../constants";
import { Script } from "../../types/Script";

const Main = styled.div`
    flex: 1;
    font-family: Poppins;
    border-radius: 4px;
    padding: 24px;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
`;

const Header = styled.div`
    color: ${colors.neutral500};
    font-size: 12px;
    font-weight: 700;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

const Scroll = styled.div`
    flex: 1;
    overflow-y: auto;
    margin-top: 18px;
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    margin-bottom: 18px;
    color: ${colors.neutral1000};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
`;

interface Props {
    script: Script;
}

const CreativeAssignedScript = ({ script }: Props) => {
    return (
        <Main>
            <Header>SCRIPT</Header>
            <Scroll>
                <Content>
                    <Title>{script.title}</Title>
                    <Markdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                        {script.text || "There is no text in this script"}
                    </Markdown>
                </Content>
            </Scroll>
        </Main>
    );
};
export default CreativeAssignedScript;
