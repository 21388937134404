import {
    PayloadAction,
    createAction,
    createEntityAdapter,
    createSlice
} from "@reduxjs/toolkit";
import { Creative } from "../types/Creative";
import { Media } from "../types/Media";
import { NormalizedSchema } from "normalizr";
import { GlobalState } from "../reducers";

const creativesAdapter = createEntityAdapter<Creative>();

export interface CreativesMediaNormalizedEntities {
    creatives: { [id: string]: Creative };
    media: { [id: string]: Media };
}

export interface CreativesMediaNormalizedAction {
    normalized: NormalizedSchema<CreativesMediaNormalizedEntities, number[]>;
    openBuyId: number;
}

export interface PatchCreativesAction {
    creativeId: number;
    reviewAction: "reject" | "revision_request" | "approve";
    advertiserMessage: string;
}

const creativesSlice = createSlice({
    name: "creatives",
    initialState: creativesAdapter.getInitialState(),
    reducers: {
        fetchSuccess: (
            state,
            action: PayloadAction<CreativesMediaNormalizedAction>
        ) => {
            creativesAdapter.addMany(
                state,
                action.payload.normalized.result.map(
                    id => action.payload.normalized.entities.creatives[id]
                )
            );
        },
        patchSuccess: (
            state,
            action: PayloadAction<
                NormalizedSchema<CreativesMediaNormalizedEntities, number[]>
            >
        ) => {
            const updatedId = action.payload.result[0];

            creativesAdapter.updateOne(state, {
                id: updatedId,
                changes: action.payload.entities.creatives[updatedId]
            });
        }
    }
});
const creativesReducer = creativesSlice.reducer;

export const { fetchSuccess, patchSuccess } = creativesSlice.actions;
export const fetch = createAction<{ openBuyId: number }>("creatives/fetch");
export const fetchError = createAction("creatives/fetchError");
export const patch = createAction<PatchCreativesAction>("creatives/patch");

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectById
} = creativesAdapter.getSelectors(
    (state: GlobalState) => state.entities.creatives
);

export default creativesReducer;
