import React from "react";
import styled from "styled-components";
import { colors } from "../../constants";
import { FlexCentered } from "../../components/commonStyles";
import { useLocation, useRouteMatch } from "react-router";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

const Main = styled.div`
    display: flex;
    margin-top: 28px;
    justify-content: stretch;
    gap: 8px;
`;

const Step = styled.div<{ $active: boolean }>`
    border-bottom: 2px solid ${colors.neutral300};
    color: ${colors.neutral500};
    padding-bottom: 6px;
    ${FlexCentered};
    flex-grow: 1;

    user-select: none;
    cursor: pointer;

    &:hover {
        color: ${colors.neutral1000};
        border-bottom: 2px solid ${colors.neutral1000};
    }

    ${props =>
        props.$active &&
        `
		color: ${colors.neutral1000};
        border-bottom: 2px solid ${colors.neutral1000};
	`}
`;

const Name = styled.div`
    font-family: Poppins;
    font-size: 12px;
    font-weight: 700;
`;

interface Props {
    openBuyId: number;
}

const ChallengeProgressNav = ({ openBuyId }: Props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { url } = useRouteMatch();

    return (
        <Main>
            <Step
                $active={location.pathname.includes("/concepts")}
                onClick={() => dispatch(push(`${url}/concepts`))}
            >
                <Name>Concepts</Name>
            </Step>
            <Step
                $active={location.pathname.includes("/creatives")}
                onClick={() => dispatch(push(`${url}/creatives`))}
            >
                <Name>Creative Approvals</Name>
            </Step>
        </Main>
    );
};
export default ChallengeProgressNav;
