import { Action, LOCATION_CHANGE } from "../types";
import {
    ADD_BID_MODAL,
    ADD_PENDING_BID_UPDATES,
    ADD_PUBLISHER_BID_MODAL,
    BID_COUNTRIES_MODAL,
    CAMPAIGNS_RESTART_MODAL,
    CREATE_NEW_CAMPAIGN,
    EDIT_CAMPAIGN,
    FETCH_CAMPAIGNS,
    FETCH_PUBLISHER_BIDS_FOR_CAMPAIGN,
    FETCH_REPORT,
    GET_BIDS_FOR_CAMPAIGN,
    GET_DAILY_SPEND,
    GET_MEDIA_REPORT,
    GET_MEDIA_TEST,
    GET_PLATFORM_TRAFFIC,
    GET_REGION_TRAFFIC,
    GET_VERIFIED_PUBLISHERS,
    MEDIA_TEST_VIEWER_MODAL,
    MEDIA_UPLOAD_MODAL,
    MEDIA_VIEWER_MODAL,
    NOTICE_MODAL,
    REMOVE_MEDIA_FROM_APP,
    REMOVE_MEDIA_MODAL,
    REQUEST_CAMPAIGN_RESTART,
    SET_CURRENT_CAMPAIGN,
    SUBMIT_TEST_MEDIA_PURCHASE
} from "./actions";
import {
    Bid,
    Campaign,
    DailySpend,
    MediaModalInfo,
    PlatformTraffic,
    RegionTraffic,
    ReportRow,
    MediaTests,
    MediaTestModalInfo,
    NoticeInfo
} from "./Campaign";
import { MODAL_CLEAR } from "../components/Modal";
import { aggregateScheduledTestMedia } from "../util";

export enum CampaignsModal {
    clear,
    restart,
    bidCountries,
    addBid,
    mediaViewer,
    mediaTestViewer,
    removeMedia,
    notice,
    uploadMedia
}

export enum BidModalType {
    default,
    publisher
}

export interface CampaignsState {
    activeCampaigns: Campaign[];
    pausedCampaigns: Campaign[];
    campaignsLoading: boolean;
    restartLoading: boolean;
    report: ReportRow[];
    reportLoading: boolean;
    dailySpend: DailySpend[];
    mediaReport: ReportRow[];
    mediaReportLoading: boolean;
    mediaTests: MediaTests[];
    mediaTestsLoading: boolean;
    mediaTestsWeightedAverage: number | null;
    modal: CampaignsModal;
    mediaModalInfo: MediaModalInfo | null;
    mediaTestModalInfo: MediaTestModalInfo | null;
    noticeInfo: NoticeInfo | null;
    purchaseRequestLoading: Boolean;
    currentCampaign: Campaign | null;
    currentCountries: string[];
    bids: Bid[];
    bidsLoading: boolean;
    bidPrefill: Bid | null;
    bidModalType: BidModalType;
    publisherBids: Bid[];
    publisherBidsLoading: boolean;
    platformTraffic: PlatformTraffic[];
    regionTraffic: RegionTraffic[];
    verifiedPublishers: string[];
    error: string | null;
}

const initialCampaign = localStorage.getItem("currentCampaign");

const initialCampaignsState: CampaignsState = {
    activeCampaigns: [],
    pausedCampaigns: [],
    campaignsLoading: true,
    restartLoading: true,
    report: [],
    reportLoading: true,
    dailySpend: [],
    mediaReport: [],
    mediaReportLoading: true,
    mediaTests: [],
    mediaTestsLoading: true,
    mediaTestsWeightedAverage: null,
    modal: CampaignsModal.clear,
    mediaModalInfo: null,
    mediaTestModalInfo: null,
    noticeInfo: null,
    purchaseRequestLoading: false,
    currentCampaign: initialCampaign ? JSON.parse(initialCampaign) : null,
    currentCountries: [],
    bids: [],
    bidsLoading: true,
    bidPrefill: null,
    bidModalType: BidModalType.default,
    publisherBids: [],
    publisherBidsLoading: true,
    platformTraffic: [],
    regionTraffic: [],
    verifiedPublishers: [],
    error: null
};

const reducer = (
    state = initialCampaignsState,
    action: Action
): CampaignsState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return {
                ...state,
                modal: CampaignsModal.clear,
                bids: [],
                bidPrefill: null,
                currentCountries: [],
                mediaModalInfo: null,
                restartLoading: true,
                error: null
            };
        case MODAL_CLEAR:
            return {
                ...state,
                modal: CampaignsModal.clear,
                bidPrefill: null,
                currentCountries: [],
                mediaModalInfo: null,
                restartLoading: true,
                error: null
            };
        case SET_CURRENT_CAMPAIGN:
            if (action.payload.currentCampaign) {
                localStorage.setItem(
                    "currentCampaign",
                    JSON.stringify(action.payload.currentCampaign)
                );
            }
            return { ...state, ...action.payload };
        case CAMPAIGNS_RESTART_MODAL:
            return {
                ...state,
                modal: CampaignsModal.restart,
                ...action.payload
            };
        case BID_COUNTRIES_MODAL:
            return {
                ...state,
                modal: CampaignsModal.bidCountries,
                ...action.payload
            };
        case ADD_BID_MODAL:
            return {
                ...state,
                modal: CampaignsModal.addBid,
                bidModalType: BidModalType.default,
                ...action.payload
            };
        case ADD_PUBLISHER_BID_MODAL:
            return {
                ...state,
                modal: CampaignsModal.addBid,
                bidModalType: BidModalType.publisher,
                ...action.payload
            };
        case MEDIA_VIEWER_MODAL:
            return {
                ...state,
                modal: CampaignsModal.mediaViewer,
                ...action.payload
            };
        case MEDIA_TEST_VIEWER_MODAL:
            return {
                ...state,
                modal: CampaignsModal.mediaTestViewer,
                ...action.payload
            };
        case NOTICE_MODAL:
            return {
                ...state,
                modal: CampaignsModal.notice,
                ...action.payload
            };
        case REMOVE_MEDIA_MODAL:
            return {
                ...state,
                modal: CampaignsModal.removeMedia,
                ...action.payload
            };
        case MEDIA_UPLOAD_MODAL:
            return {
                ...state,
                modal: CampaignsModal.uploadMedia
            };
        case FETCH_CAMPAIGNS.SUCCESS:
            return { ...state, ...action.payload, campaignsLoading: false };
        case FETCH_CAMPAIGNS.FAILURE:
            return { ...state, ...action.payload, campaignsLoading: false };
        case FETCH_REPORT.REQUEST:
            return { ...state, report: [], reportLoading: true };
        case FETCH_REPORT.SUCCESS:
            return { ...state, ...action.payload, reportLoading: false };
        case FETCH_REPORT.FAILURE:
            return { ...state, ...action.payload, reportLoading: false };
        case GET_DAILY_SPEND.REQUEST:
            return { ...state, dailySpend: [] };
        case GET_DAILY_SPEND.SUCCESS:
            return { ...state, ...action.payload };
        case GET_DAILY_SPEND.FAILURE:
            return { ...state, ...action.payload };
        case GET_MEDIA_REPORT.REQUEST:
            return { ...state, mediaReport: [], mediaReportLoading: true };
        case GET_MEDIA_REPORT.SUCCESS:
            return { ...state, ...action.payload, mediaReportLoading: false };
        case GET_MEDIA_REPORT.FAILURE:
            return { ...state, ...action.payload, mediaReportLoading: false };
        case GET_MEDIA_TEST.REQUEST:
            return {
                ...state,
                mediaTests: [],
                mediaTestsWeightedAverage: null,
                mediaTestsLoading: true
            };
        case GET_MEDIA_TEST.SUCCESS:
            return {
                ...state,
                mediaTests: aggregateScheduledTestMedia(
                    action.payload.mediaTests
                ),
                mediaTestsWeightedAverage: action.payload.weightedAverage,
                mediaTestsLoading: false
            };
        case GET_MEDIA_TEST.FAILURE:
            return { ...state, ...action.payload, mediaTestsLoading: false };
        case SUBMIT_TEST_MEDIA_PURCHASE.REQUEST:
            return { ...state, purchaseRequestLoading: true };
        case SUBMIT_TEST_MEDIA_PURCHASE.SUCCESS:
            return { ...state, purchaseRequestLoading: false };
        case SUBMIT_TEST_MEDIA_PURCHASE.FAILURE:
            return {
                ...state,
                ...action.payload,
                purchaseRequestLoading: false
            };
        case REQUEST_CAMPAIGN_RESTART.SUCCESS:
            return {
                ...state,
                pausedCampaigns: state.pausedCampaigns.map(campaign => {
                    if (campaign.id === action.payload) {
                        return { ...campaign, pending_restart: true };
                    } else {
                        return campaign;
                    }
                }),
                restartLoading: false
            };
        case REQUEST_CAMPAIGN_RESTART.FAILURE:
            return { ...state, ...action.payload, restartLoading: false };
        case CREATE_NEW_CAMPAIGN.FAILURE:
            return { ...state, ...action.payload };
        case EDIT_CAMPAIGN.FAILURE:
            return { ...state, ...action.payload };
        case GET_BIDS_FOR_CAMPAIGN.REQUEST:
            return { ...state, bids: [], bidsLoading: true };
        case GET_BIDS_FOR_CAMPAIGN.SUCCESS:
            return { ...state, ...action.payload, bidsLoading: false };
        case GET_BIDS_FOR_CAMPAIGN.FAILURE:
            return { ...state, ...action.payload, bidsLoading: false };
        case FETCH_PUBLISHER_BIDS_FOR_CAMPAIGN.REQUEST:
            return { ...state, publisherBids: [], publisherBidsLoading: true };
        case FETCH_PUBLISHER_BIDS_FOR_CAMPAIGN.SUCCESS:
            return { ...state, ...action.payload, publisherBidsLoading: false };
        case FETCH_PUBLISHER_BIDS_FOR_CAMPAIGN.FAILURE:
            return { ...state, ...action.payload, publisherBidsLoading: false };
        case GET_PLATFORM_TRAFFIC.SUCCESS:
            return { ...state, ...action.payload };
        case GET_PLATFORM_TRAFFIC.FAILURE:
            return { ...state, ...action.payload };
        case GET_REGION_TRAFFIC.SUCCESS:
            return { ...state, ...action.payload };
        case GET_REGION_TRAFFIC.FAILURE:
            return { ...state, ...action.payload };
        case GET_VERIFIED_PUBLISHERS.SUCCESS:
            return { ...state, ...action.payload };
        case REMOVE_MEDIA_FROM_APP.FAILURE:
            return { ...state, ...action.payload };
        case ADD_PENDING_BID_UPDATES.FAILURE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default reducer;
