import styled from "styled-components/macro";
import { colors } from "../constants";

export const Button = styled.button<{ disabled?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: none;
    font-size: 14px;
    /* line-height: 14px; */
    transition: background-color 0.2s;
    user-select: none;
    outline: none !important;
    box-sizing: border-box;
    cursor: pointer;
    opacity: ${props => props.disabled && 0.3};
    cursor: ${props => props.disabled && "default"};
`;

export const OutlineButton = styled(Button)<{ textColor?: string }>`
    background-color: ${colors.white};
    border: ${colors.mediumTeal} solid 1px;
    color: ${props => props.textColor || colors.primary700};
    border-radius: 6px;
    transition: 0.1s ease-in;
    cursor: pointer;

    @media (hover: hover) {
        :hover {
            background-color: ${colors.lightTeal};
            border: ${colors.primary900} solid 1px;
        }
    }

    &:disabled {
        color: ${colors.primaryGray};
        cursor: default;
    }
`;
