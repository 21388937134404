import React from "react";
import { connect } from "react-redux";
import { Action } from "../types";
import styled from "styled-components/macro";

const Parent = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Child = styled.div<{ small?: boolean }>`
    width: ${props => (props.small ? "400px" : "40vw")};
    min-width: 400px;
    border-radius: 6px;
    background-color: white;
`;

const Modal = ({
    clearModal,
    children,
    small
}: {
    clearModal: () => void;
    children: any;
    small?: boolean | undefined;
}) => {
    return (
        <Parent onClick={clearModal}>
            <Child small={small} onClick={e => e.stopPropagation()}>
                {children}
            </Child>
        </Parent>
    );
};

export const MODAL_CLEAR = "MODAL_CLEAR";

export const clearModal = (): Action => ({
    type: MODAL_CLEAR,
    payload: null
});

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal())
});

export default connect(null, mapDispatchToProps)(Modal);
