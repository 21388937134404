import React from "react";
import styled from "styled-components/macro";
import { rebrandColors } from "../constants";
import { Advertiser } from "../dashboard/Profile";
import AccountMenu from "./AccountMenu";

const Main = styled.div`
    position: fixed;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 66px;
    border-bottom: 1px solid ${rebrandColors["primary-300"]};
    background-color: ${rebrandColors["neutral-0"]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    box-sizing: border-box;
`;

const Icon = styled.img`
    user-select: none;
    height: 42px;
`;

const ProductIcon = styled.img`
    margin-left: 22px;
    width: 32px;
    height: 32px;
`;

const Name = styled.div`
    font-family: Poppins;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: black;
    margin-left: 11px;
    margin-top: 4px;
    user-select: none;
    letter-spacing: -0.0002em;
`;

const LeftContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

const Navbar = ({ advertiser }: { advertiser?: Advertiser }) => {
    return (
        <Main>
            <LeftContainer>
                <Icon src={"/rebrand/logo-lv-r2.svg"} />
                <ProductIcon src={"/rebrand/lv-influence.svg"} />
                <Name>Influence</Name>
            </LeftContainer>
            <AccountMenu advertiser={advertiser} />
        </Main>
    );
};

export default Navbar;
