import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { isLoggedIn } from "../util";
import { Route, Switch } from "react-router";
import styled from "styled-components/macro";
import Navbar from "../components/Navbar";
import LoginUser from "./LoginUser";
import SetPassword from "./SetPassword";
import ResetPassword from "./ResetPassword";
import { colors, container } from "../constants";
import Footer from "../components/Footer";

const Main = styled.div`
    margin-top: 66px;
    padding: 0 40px;
    box-sizing: border-box;
    min-height: calc(100vh - 66px);
    background-color: ${colors.neutral100};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const Form = styled.div`
    width: 400px;
    padding: 0 30px 40px;
    box-sizing: border-box;
    text-align: center;
    ${container}
`;

const Auth = ({ push }: { push: (route: string) => void }) => {
    if (isLoggedIn()) {
        push("/");
    }

    return (
        <>
            <Navbar />
            <Main>
                <div style={{ height: 40 }} />
                <Form>
                    <Switch>
                        <Route
                            path="/login/reset-password"
                            component={ResetPassword}
                        />
                        <Route
                            path="/login/set-password"
                            component={SetPassword}
                        />
                        <Route path="/login" component={LoginUser} />
                    </Switch>
                </Form>
                <Footer />
            </Main>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    push: (route: string) => dispatch(push(route))
});

export default connect(null, mapDispatchToProps)(Auth);
