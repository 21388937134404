import {
    PayloadAction,
    createEntityAdapter,
    createSlice
} from "@reduxjs/toolkit";
import { Media } from "../types/Media";
import {
    CreativesMediaNormalizedAction,
    fetchSuccess
} from "../creatives/slice";
import { GlobalState } from "../reducers";

const mediaAdapter = createEntityAdapter<Media>();

const mediaSlice = createSlice({
    name: "media",
    initialState: mediaAdapter.getInitialState(),
    reducers: {},
    extraReducers: builder => {
        builder.addCase(
            fetchSuccess,
            (state, action: PayloadAction<CreativesMediaNormalizedAction>) => {
                mediaAdapter.addMany(
                    state,
                    action.payload.normalized.entities.media
                );
            }
        );
    }
});

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectById
} = mediaAdapter.getSelectors((state: GlobalState) => state.entities.media);

const mediaReducer = mediaSlice.reducer;

export default mediaReducer;
