import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "../components/commonStyles";
import { ReactComponent as OpenInNew } from "../components/icons/open_in_new.svg";
import { colors } from "../constants";
import { RedPillButton } from "../components/styledComponents";
import ConceptItem from "./components/ConceptItem";
import ConceptModal from "./components/ConceptModal";
import { useDispatch, useSelector } from "react-redux";
import { fetch } from "./slice";
import { GlobalState } from "../reducers";
import { selectById } from "../challenges/slice";
import SimpleLoader from "../components/SimpleLoader";
import ConceptEmptyCard from "./components/ConceptEmptyCard";
import ConfirmModal from "../components/ConfirmModal";

const InfoBar = styled.div`
    ${FlexVerticallyCentered};
    margin-top: 32px;
`;

const Count = styled.div`
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
`;

const SampleLink = styled.div`
    margin-left: auto;
    ${FlexVerticallyCentered};
    color: ${colors.primary700};
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;

    & > * ~ * {
        margin-left: 6px;
    }
`;

const ActionButton = styled(RedPillButton)`
    height: 36px;
    margin-left: 32px;
    cursor: pointer;
`;

const AddIcon = styled.img`
    margin-right: 6px;
`;

const List = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    & > * ~ * {
        margin-top: 18px;
    }
`;

const Loading = styled.div`
    flex: 1;
    ${FlexCentered}
    flex-direction: column;
`;

const LoadingText = styled.div`
    margin-top: 24px;

    color: ${colors.neutral700};
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
`;

const EmptyCard = styled.div``;

interface Props {
    openBuyId: number;
}

const NUM_CONCEPT_LIMIT = 5;

const Scripts = ({ openBuyId }: Props) => {
    const [editModalOpen, toggleEditModalOpen] = useState<number | boolean>(
        false
    );
    const [limitModal, toggleLimitModal] = useState(false);
    const dispatch = useDispatch();
    const challenge = useSelector((state: GlobalState) =>
        selectById(state, openBuyId)
    );

    const handleActionClick = () => {
        if (
            challenge?.scripts?.length &&
            challenge.scripts.length > NUM_CONCEPT_LIMIT
        ) {
            toggleLimitModal(true);
        } else {
            toggleEditModalOpen(true);
        }
    };

    useEffect(() => {
        dispatch(fetch({ openBuyId }));
    }, [openBuyId]);

    if (!challenge) return null;

    return (
        <>
            <InfoBar>
                <Count>
                    {challenge.scripts?.length} concept
                    {challenge.scripts?.length === 1 ? "" : "s"}
                </Count>
                <SampleLink>
                    <div>View Sample Concept</div>
                    <OpenInNew />
                </SampleLink>
                <ActionButton onClick={handleActionClick}>
                    <AddIcon src={"/white_add_icon.svg"} />
                    <div>Add New Concept</div>
                </ActionButton>
            </InfoBar>
            {challenge.scripts ? (
                <List>
                    {challenge.scripts.length === 0 ? (
                        <ConceptEmptyCard />
                    ) : (
                        challenge.scripts.map(scriptId => (
                            <ConceptItem
                                key={scriptId}
                                scriptId={scriptId}
                                onEdit={() => toggleEditModalOpen(scriptId)}
                            />
                        ))
                    )}
                </List>
            ) : (
                <Loading>
                    <SimpleLoader />
                    <LoadingText>Loading concepts..</LoadingText>
                </Loading>
            )}

            {limitModal && (
                <ConfirmModal
                    text="You are allowed a maximum of 5 concepts per challenge. This limit is in place in order to avoid overwhelming creators"
                    title="You have reached the limit of 5 concepts"
                    buttonText="Okay"
                    onConfirm={() => toggleLimitModal(false)}
                    closeModal={() => toggleLimitModal(false)}
                />
            )}

            {editModalOpen && (
                <ConceptModal
                    closeModal={() => toggleEditModalOpen(false)}
                    openBuyId={openBuyId}
                    scriptId={
                        typeof editModalOpen === "number"
                            ? editModalOpen
                            : undefined
                    }
                />
            )}
        </>
    );
};
export default Scripts;
