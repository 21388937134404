import isUrl from "is-url";
import { serialize } from "remark-slate";
import { Descendant, Editor } from "slate";
import CustomEditor from "./CustomEditor";

export const serializeContent = (content: Descendant[]): string => {
    const newLine = "  \n";
    const serializedArr = content.map(v => {
        // Adding the custom nodeTypes led to the double-parsing bug below.
        //@ts-ignore
        return serialize(v, { nodeTypes: { paragraph: "rule_item" } });
    });
    const serializedString = serializedArr.join("\n");
    const stringWithNewLines = serializedString.replace(
        new RegExp(/\n|<br>/g),
        newLine
    );

    // This is to workaround the `"` being double-parsed to `&amp;quot;` and can't be parsed by third party unescape libraries.
    const unescapeHTMLEntitiesString = stringWithNewLines.replace(
        /&amp;(lt|gt|quot|amp|#39);/g,
        function(a: string, b: "lt" | "gt" | "quot" | "amp" | "#39") {
            return {
                lt: "<",
                gt: ">",
                quot: '"',
                amp: "&",
                "#39": "'"
            }[b];
        }
    );

    return unescapeHTMLEntitiesString;
};

export const isContentEmpty = (markdownStr: string): boolean => {
    const newLine = "  \n";
    return markdownStr.replaceAll(newLine, "").trim().length === 0;
};

export const withInlines = (editor: Editor) => {
    const { insertData, insertText, isInline, isVoid } = editor;

    editor.isInline = (element: any) =>
        element.type === "link" ||
        element.type === "rule_item" ||
        isInline(element);

    editor.isVoid = element => {
        return element.type === "rule_item" ? true : isVoid(element);
    };

    editor.insertText = (text: any) => {
        if (text && isUrl(text)) {
            CustomEditor.wrapLink(editor, text);
        } else {
            insertText(text);
        }
    };

    editor.insertData = (data: any) => {
        const text = data.getData("text/plain");

        if (text && isUrl(text)) {
            CustomEditor.wrapLink(editor, text);
        } else {
            insertData(data);
        }
    };

    return editor;
};
