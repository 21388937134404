import {
    PayloadAction,
    createAction,
    createEntityAdapter,
    createSlice
} from "@reduxjs/toolkit";
import { GlobalState } from "../reducers";
import {
    ScriptAssetNormalizedAction,
    fetchSuccess as scriptsFetchSuccess,
    createSuccess as scriptsCreateSuccess,
    updateSuccess as scriptsUpdateSuccess,
    ScriptAssetNormalizedEntities
} from "../scripts/slice";
import { Asset } from "../types/challenge/Asset";
import { NormalizedSchema } from "normalizr";

const assetsAdapter = createEntityAdapter<Asset>();

const assetsSlice = createSlice({
    name: "assets",
    initialState: assetsAdapter.getInitialState(),
    reducers: {
        deleteSuccess: (
            state,
            action: PayloadAction<{ assetId: number; scriptId: number }>
        ) => {
            assetsAdapter.removeOne(state, action.payload.assetId);
        }
    },
    extraReducers: builder => {
        builder
            .addCase(
                scriptsFetchSuccess,
                (
                    state,
                    action: PayloadAction<ScriptAssetNormalizedAction<number[]>>
                ) => {
                    if (action.payload.normalized.result.length)
                        assetsAdapter.addMany(
                            state,
                            action.payload.normalized.entities.assets
                        );
                }
            )
            .addCase(
                scriptsCreateSuccess,
                (
                    state,
                    action: PayloadAction<ScriptAssetNormalizedAction<number>>
                ) => {
                    assetsAdapter.addMany(
                        state,
                        action.payload.normalized.entities.assets
                    );
                }
            )
            .addCase(
                scriptsUpdateSuccess,
                (
                    state,
                    action: PayloadAction<
                        NormalizedSchema<
                            ScriptAssetNormalizedEntities,
                            number[]
                        >
                    >
                ) => {
                    assetsAdapter.addMany(
                        state,
                        action.payload.entities.assets
                    );
                }
            );
    }
});

const assetsReducer = assetsSlice.reducer;

export const { deleteSuccess } = assetsSlice.actions;
export const deleteAsset = createAction<{ assetId: number; scriptId: number }>(
    "assets/delete"
);

export const { selectAll, selectById } = assetsAdapter.getSelectors(
    (state: GlobalState) => state.entities.assets
);

export default assetsReducer;
