import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    DELETE_USER,
    deleteUserFailure,
    deleteUserSuccess,
    EDIT_USER,
    editUserFailure,
    editUserSuccess,
    GET_USERS_FOR_ADMIN,
    getUsersForAdminFailure,
    getUsersForAdminSuccess,
    INVITE_NEW_USER,
    inviteNewUserFailure,
    inviteNewUserSuccess
} from "./actions";
import { get, post } from "../Api";
import { Action } from "../types";
import { clearModal } from "../components/Modal";
import { isAxiosError } from "axios";

function* getUsersForAdminRequest() {
    try {
        const { data, status } = yield call(
            get,
            "/dashboard/api/get_users_for_admin",
            {}
        );

        if (status === 200) {
            yield put(getUsersForAdminSuccess(data));
        } else {
            yield put(getUsersForAdminFailure(data));
        }
    } catch (error) {
        if (isAxiosError(error)) {
            yield put(getUsersForAdminFailure(error.message));
        }
    }
}

function* inviteNewUserRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/dashboard/api/create_user_for_admin",
            payload
        );

        if (status === 200) {
            yield put(inviteNewUserSuccess(data));
            yield put(clearModal());
        } else {
            yield put(inviteNewUserFailure(data));
        }
    } catch (error) {
        if (isAxiosError(error)) {
            yield put(inviteNewUserFailure(error.message));
        }
    }
}

function* editUserRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/dashboard/api/edit_user_for_admin",
            payload
        );

        if (status === 200) {
            yield put(editUserSuccess(data));
            yield put(clearModal());
        } else {
            yield put(editUserFailure(data));
        }
    } catch (error) {
        if (isAxiosError(error)) {
            yield put(editUserFailure(error.message));
        }
    }
}

function* deleteUserRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/dashboard/api/delete_user_for_admin",
            payload
        );

        if (status === 200) {
            yield put(deleteUserSuccess(payload.advertiser_user_id));
            yield put(clearModal());
        } else {
            yield put(deleteUserFailure(data));
        }
    } catch (error) {
        if (isAxiosError(error)) {
            yield put(deleteUserFailure(error.message));
        }
    }
}

export default function* usersSaga() {
    yield all([
        takeEvery(GET_USERS_FOR_ADMIN.REQUEST, getUsersForAdminRequest),
        takeEvery(INVITE_NEW_USER.REQUEST, inviteNewUserRequest),
        takeEvery(EDIT_USER.REQUEST, editUserRequest),
        takeEvery(DELETE_USER.REQUEST, deleteUserRequest)
    ]);
}
