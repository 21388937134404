import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as ConceptIcon } from "../../components/icons/concept_icon.svg";
import { ReactComponent as AttachedIcon } from "../../components/icons/attached_icon.svg";
import { ReactComponent as EditIcon } from "../../components/icons/edit_icon.svg";
import { ReactComponent as DeleteIcon } from "../../components/icons/delete_icon.svg";
import { colors } from "../../constants";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "../../components/commonStyles";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { deleteScript, selectById } from "../slice";
import MessagePreview from "../../creatives/components/MessagePreview";
import ConfirmModal from "../../components/ConfirmModal";

const Main = styled.div`
    padding: 26px 36px;
    background-color: ${colors.white};
    border-radius: 20px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.08));
    ${FlexVerticallyCentered};
`;

const Icon = styled.div`
    width: 72px;
    height: 72px;
    border: 1px solid ${colors.secondaryColor};
    border-radius: 36px;
    ${FlexCentered};

    color: ${colors.secondaryColor};
`;

const Info = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 45px;

    & > * ~ * {
        margin-top: 12px;
    }
`;

const ConceptTitle = styled.div`
    color: ${colors.primary700};
    font-size: 24px;
    font-weight: 500;
`;

const Description = styled(MessagePreview)`
    background-color: white;
    @media (max-width: 1120px) {
        width: 100%;
    }

    color: ${colors.neutral700};
    font-size: 18px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding: 0;
`;

const AttachedCount = styled.div`
    ${FlexVerticallyCentered};
    color: ${colors.neutral700};
    font-weight: 400;

    & > * ~ * {
        margin-left: 12px;
    }
`;

const Actions = styled.div`
    margin-left: 35px;
    ${FlexVerticallyCentered};

    & > * ~ * {
        margin-left: 55px;
    }

    & > svg {
        cursor: pointer;
        user-select: none;
    }

    padding: 0 24px;
`;

interface Props {
    scriptId: number;
    onEdit: () => void;
}

const ConceptItem = ({ scriptId, onEdit }: Props) => {
    const [deleteModal, toggleDeleteModal] = useState(false);
    const dispatch = useDispatch();
    const script = useSelector((state: GlobalState) =>
        selectById(state, scriptId)
    );

    const handleDelete = () => {
        dispatch(deleteScript({ scriptId }));
        toggleDeleteModal(false);
    };

    if (!script) return null;

    return (
        <Main>
            <Icon>
                <ConceptIcon />
            </Icon>
            <Info>
                <ConceptTitle>{script.title}</ConceptTitle>
                <Description text={script.text} />
                <AttachedCount>
                    <AttachedIcon />
                    <div>
                        {script.Assets.length} attached file
                        {script.Assets.length === 1 ? "" : "s"}
                    </div>
                </AttachedCount>
            </Info>
            <Actions>
                <EditIcon onClick={onEdit} />
                <DeleteIcon onClick={() => toggleDeleteModal(true)} />
            </Actions>
            {deleteModal && (
                <ConfirmModal
                    closeModal={() => toggleDeleteModal(false)}
                    title="Delete Concept"
                    text="Are you sure you want to delete this concept? You will lose
                    any changes you have made."
                    onConfirm={handleDelete}
                    buttonText="Delete"
                    displayCancel
                />
            )}
        </Main>
    );
};
export default ConceptItem;
