import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import { BlankLink, RedButton } from "../../components/styledComponents";
import { colors } from "../../constants";
import { Advertiser } from "../../dashboard/Profile";

const Main = styled.div`
    position: fixed;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    background-image: linear-gradient(
        10deg,
        ${colors.brightRed} 30%,
        ${colors.brightPink} 70%
    );
`;

const HideDiv = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 500px) {
        display: none;
    }
`;

const IconContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
`;

const Divider = styled.div`
    width: 2px;
    height: 32px;
    background-color: white;
    opacity: 0.5;
    margin: 0 32px;
`;

const Name = styled.div`
    font-family: "Dosis", sans-serif;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    color: white;
    user-select: none;
`;

const LeftContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

const InitialIcon = styled.div`
    width: 40px;
    height: 40px;
    background-color: black;
    border-radius: 100%;
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Button = styled(RedButton)`
    max-width: 150px;
    width: 100%;
    padding: 10px 0;
    border: 0.1px solid ${colors.neutral300};
    border-radius: 7px;
    margin-right: 10px;
`;
interface Props {
    advertiser?: Advertiser;
    title?: String;
}

const ExternalNavbar = ({ advertiser, title }: Props) => {
    const history = useHistory();
    return (
        <Main>
            <LeftContainer>
                <BlankLink href="https://dashboard.jetfuel.com">
                    <IconContainer>
                        <img src={"/logo.svg"} alt="Dashboard Logo" />
                    </IconContainer>
                </BlankLink>
                <HideDiv>
                    <Divider />
                    <Name>Jetfuel Dashboard</Name>
                </HideDiv>
            </LeftContainer>
            <IconContainer>
                {!advertiser || (
                    <InitialIcon>
                        <div>{advertiser.company_name[0]}</div>
                    </InitialIcon>
                )}
            </IconContainer>
            <Button onClick={() => history.push("/login")}>Login</Button>
        </Main>
    );
};

export default ExternalNavbar;
