import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { getSupportEmail } from "./actions";
// @ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components/macro";
import Modal, { clearModal } from "../components/Modal";
import {
    CopyButton,
    CopyInput,
    ModalButton
} from "../components/styledComponents";
import RocketLoader from "../components/RocketLoader";

const Main = styled.div`
    padding: 0 30px;
    display: flex;
    flex-direction: column;
`;

const EmailContainer = styled.div`
    display: flex;
    margin-bottom: 30px;
`;

const Description = styled.div`
    margin-bottom: 15px;
`;

const ButtonContainer = styled.div`
    margin: 0 0 30px auto;
`;

interface Props {
    supportEmail?: string;
    clearModal: () => void;
    getSupportEmail: () => void;
}

const SupportModal = ({ supportEmail, clearModal, getSupportEmail }: Props) => {
    useEffect(() => {
        getSupportEmail();
    }, []);

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 2000);
        }
    }, [copied]);

    if (supportEmail) {
        return (
            <Modal>
                <Main>
                    <h2>Support</h2>
                    <Description>Email your account manager:</Description>
                    <EmailContainer>
                        <CopyInput value={supportEmail} readOnly />
                        <CopyToClipboard
                            text={supportEmail}
                            onCopy={() => setCopied(true)}
                        >
                            <CopyButton>
                                {copied ? "Copied!" : "Copy"}
                            </CopyButton>
                        </CopyToClipboard>
                    </EmailContainer>
                    <ButtonContainer>
                        <ModalButton onClick={clearModal}>Close</ModalButton>
                    </ButtonContainer>
                </Main>
            </Modal>
        );
    } else {
        return (
            <Modal>
                <div style={{ padding: "30px" }}>
                    <RocketLoader />
                </div>
            </Modal>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    supportEmail: state.profile.supportEmail
});

const mapDispatchToProps = (dispatch: any) => ({
    clearModal: () => dispatch(clearModal()),
    getSupportEmail: () => dispatch(getSupportEmail())
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportModal);
