import React, { useState } from "react";
import styled from "styled-components/macro";
import { FlexVerticallyCentered } from "../../components/commonStyles";
import { colors } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { getSelectionStateForRule } from "../selector";
import { updateChecklist } from "../../ui/rules/slice";

const Main = styled.div<{ $selected?: boolean }>`
    padding: 5px 6px;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    font-size: 14px;

    &:hover {
        border-radius: 3px;
        border-top: 1px solid ${colors.neutral300};
        border-bottom: 1px solid ${colors.neutral300};
    }

    ${props =>
        props.$selected === false &&
        `
            border-radius: 3px;
            background-color: ${colors.error100};
            color: ${colors.error900};
        `}

    ${props =>
        props.$selected === true &&
        `
            color: ${colors.neutral500};
        `}
`;

const Icon = styled.div`
    height: 28px;
    ${FlexVerticallyCentered};
`;

const Title = styled.span``;

const Text = styled.p<{ $selected?: boolean }>`
    margin-block-end: 0px;
    padding: 3px 0;

    ${props =>
        props.$selected === false &&
        `
            text-decoration: line-through;
        `}
`;

const ActionBox = styled.div<{ $selected?: boolean; $actionable: boolean }>`
    margin-left: auto;
    display: none;
    align-items: center;

    & > * ~ * {
        margin-left: 12px;
    }

    ${props =>
        props.$selected === undefined &&
        props.$actionable &&
        `
            display: flex;
        `}
`;

const ActionButton = styled.img`
    cursor: pointer;
    user-select: none;
`;

interface Props {
    icon: string;
    title: string;
    text: string;
    handleAddRule: (text: string, remove?: boolean) => void;
    actionable: boolean;
}

const RuleRow = ({ icon, title, text, handleAddRule, actionable }: Props) => {
    const dispatch = useDispatch();
    const selected = useSelector((state: GlobalState) =>
        getSelectionStateForRule(state, `${title} ${text}`.trim())
    );

    const renderIcon = () => {
        switch (selected) {
            case true:
                return "✅";
            case false:
                return "❌";
            default:
                return "🔲";
        }
    };

    return (
        <Main $selected={selected}>
            <Icon>{renderIcon()}</Icon>
            <Text $selected={selected}>
                <Title>{title}</Title> {text}
            </Text>
            <ActionBox $selected={selected} $actionable={actionable}>
                <ActionButton
                    src="/approvals/check_button.svg"
                    onClick={() => {
                        dispatch(
                            updateChecklist({
                                [`${title} ${text}`.trim()]: true
                            })
                        );
                    }}
                />
                <ActionButton
                    src="/approvals/close_button.svg"
                    onClick={() => {
                        handleAddRule(`${title} ${text}`.trim());
                        dispatch(
                            updateChecklist({
                                [`${title} ${text}`.trim()]: false
                            })
                        );
                    }}
                />
            </ActionBox>
        </Main>
    );
};
export default RuleRow;
