import isUrl from "is-url";
import {
    Editor,
    Element as SlateElement,
    Text,
    Range,
    Transforms,
    Node,
    Path
} from "slate";
import { CustomElement, ElementNode } from "./types";
import { ReactEditor } from "slate-react";
import { Rule } from "../../types/challenge/Rule";

const CustomEditor = {
    isMarkActive(editor: Editor, format: string) {
        const marks = Editor.marks(editor);
        switch (format) {
            case "bold":
                return marks ? marks[format] === true : false;
            case "italic":
                return marks ? marks[format] === true : false;
            default:
                return false;
        }
    },
    isLinkActive(editor: Editor) {
        // @ts-ignore
        const [link] = Editor.nodes(editor, {
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === "link"
        });
        return !!link;
    },
    isRuleActive(editor: Editor) {
        const [rule] = Editor.nodes(editor, {
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === "rule_item"
        });
        return !!rule;
    },
    getLink(editor: Editor) {
        const [link] = Array.from(
            Editor.nodes(editor, {
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n.type === "link"
            })
        );
        return link;
    },
    getRule(editor: Editor) {
        const [rule] = Array.from(
            Editor.nodes(editor, {
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n.type === "rule_item"
            })
        );
        return rule;
    },
    toggleMark(editor: Editor, format: string) {
        const isActive = this.isMarkActive(editor, format);

        if (isActive) {
            Editor.removeMark(editor, format);
        } else {
            Editor.addMark(editor, format, true);
        }
    },
    wrapLink(editor: Editor, url: string, text?: string) {
        if (isUrl(url)) {
            if (this.isLinkActive(editor)) {
                this.unwrapLink(editor);
            }

            const { selection } = editor;
            const isCollapsed = selection && Range.isCollapsed(selection);
            const link: CustomElement = {
                type: "link",
                link: url,
                children: isCollapsed ? [{ text: text || url }] : []
            };

            if (isCollapsed) {
                Transforms.insertNodes(editor, link);
            } else {
                Transforms.wrapNodes(editor, link, { split: true });
                Transforms.collapse(editor, { edge: "end" });
            }
        }
    },
    unwrapLink(editor: Editor) {
        Transforms.unwrapNodes(editor, {
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === "link"
        });
    },
    insertLink(editor: Editor, url: string, text?: string) {
        if (editor.selection) {
            this.wrapLink(editor, url, text);
        }
    },
    insertRule(editor: Editor, rule: string) {
        const newRule: CustomElement = {
            type: "rule_item",
            rule,
            children: [{ text: `❌ ~~${rule}~~` }]
        };
        Editor.insertBreak(editor);
        Transforms.insertNodes(editor, newRule);
        Transforms.move(editor, { distance: 1 });
        Editor.insertBreak(editor);

        ReactEditor.focus(editor);
    },
    removeSelectionOnClick(editor: Editor) {
        Transforms.delete(editor);
    },
    getSelectedLink(editor: Editor) {
        const ancestor = Editor.above(editor);
        if (
            ancestor &&
            Editor.isInline(editor, ancestor[0]) &&
            ancestor[0].type === "link"
        ) {
            return ancestor[0].link;
        }
        return null;
    },
    isListActive(editor: Editor, format: string) {
        const { selection } = editor;
        if (!selection) return false;

        const [match] = Array.from(
            Editor.nodes(editor, {
                at: Editor.unhangRange(editor, selection),
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n.type === format
            })
        );
        return !!match;
    },
    getNodeByType(editor: Editor, type: string): ElementNode | null {
        const { selection } = editor;
        if (!selection) return null;

        const match = Array.from(
            Editor.nodes(editor, {
                at: Editor.unhangRange(editor, selection),
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n.type === type
            })
        ).flat();
        return SlateElement.isElement(match[0]) && Path.isPath(match[1])
            ? { node: match[0], path: match[1] }
            : null;
    },
    checkNode(node: any) {
        if (!node) return false;

        return Node.isNode(node.node) && Path.isPath(node.path);
    },
    getNode(editor: Editor, path: Path): ElementNode | null {
        const node = Editor.node(editor, path);
        return node && SlateElement.isElement(node[0]) && Path.isPath(node[1])
            ? { node: node[0], path: node[1] }
            : null;
    },
    getNextNode(editor: Editor, path: Path): ElementNode | null {
        const nextNode = Editor.next(editor, { at: path });
        return nextNode &&
            SlateElement.isElement(nextNode[0]) &&
            Path.isPath(nextNode[1])
            ? { node: nextNode[0], path: nextNode[1] }
            : null;
    },
    getListType(editor: Editor): "ul_list" | "ol_list" | false {
        const { selection } = editor;
        if (!selection) return false;

        const [match] = Array.from(
            Editor.nodes(editor, {
                at: Editor.unhangRange(editor, selection),
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    (n.type === "ol_list" || n.type === "ul_list")
            })
        );
        if (
            match &&
            SlateElement.isElement(match[0]) &&
            (match[0].type === "ul_list" || match[0].type === "ol_list")
        ) {
            return match[0].type;
        }
        return false;
    },
    toggleList(editor: Editor, format: "ul_list" | "ol_list") {
        const isActive = this.isListActive(editor, format);
        const isList = format === "ol_list" || "ul_list";
        const currentListType = this.getListType(editor);

        Transforms.unwrapNodes(editor, {
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                (n.type === "ul_list" || n.type === "ol_list"),
            split: true
        });

        if (isActive || currentListType !== format) {
            Transforms.unwrapNodes(editor, {
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n.type === "list_item"
            });
        }
        let newProperties: Partial<SlateElement>;

        newProperties = {
            type: "paragraph"
        };

        Transforms.setNodes<SlateElement>(editor, newProperties);
        if (!isActive && isList) {
            const paragraph: CustomElement = {
                type: "list_item",
                children: []
            };
            const block: CustomElement = {
                type: format,
                children: []
            };
            Transforms.wrapNodes(editor, block);
            Transforms.wrapNodes(editor, paragraph);
        }
    },
    deleteListItem(
        editor: Editor,
        list: ElementNode,
        listItem: ElementNode,
        element: ElementNode,
        listType: "ul_list" | "ol_list"
    ) {
        const shouldListBeRemoved = list.node.children.length === 1;

        if (shouldListBeRemoved) {
            Transforms.insertNodes(editor, element.node, {
                at: Path.next(list.path)
            });
            Transforms.removeNodes(editor, {
                at: list.path
            });
        } else {
            CustomEditor.toggleList(editor, listType);
        }
    },
    getListNodes(editor: Editor) {
        const listType = this.getListType(editor);
        const currentListItem = this.getNodeByType(editor, "list_item");
        const currentElement =
            this.getNodeByType(editor, "paragraph") ||
            this.getNodeByType(editor, "link");
        const currentList = listType && this.getNodeByType(editor, listType);
        if (
            listType &&
            currentListItem &&
            currentElement &&
            currentList &&
            this.checkNode(currentListItem) &&
            this.checkNode(currentElement) &&
            this.checkNode(currentList)
        ) {
            return { currentList, currentListItem, currentElement, listType };
        } else {
            return null;
        }
    },
    getNextPath(path: Path): Path {
        const nextPath = path.map((val, idx, arr) => {
            if (idx === arr.length - 1) {
                return val + 1;
            } else {
                return val;
            }
        });
        return nextPath;
    },
    combineAdjacentLists(editor: Editor, path: Path) {
        Transforms.mergeNodes(editor, { at: path });
    }
};

export default CustomEditor;
