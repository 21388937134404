import { Action } from "../types";
import { User } from "../dashboard/Profile";
import { MODAL_CLEAR } from "../components/Modal";
import {
    DELETE_USER,
    EDIT_USER,
    GET_USERS_FOR_ADMIN,
    INVITE_NEW_USER,
    USERS_EDIT_MODAL,
    USERS_INVITE_MODAL
} from "./actions";

export enum UsersModal {
    clear,
    invite,
    edit
}

export interface UsersState {
    users: User[];
    usersLoading: boolean;
    modal: UsersModal;
    currentUser: User | null;
}

const initialUsersState: UsersState = {
    users: [],
    usersLoading: true,
    modal: UsersModal.clear,
    currentUser: null
};

const reducer = (state = initialUsersState, action: Action) => {
    switch (action.type) {
        case MODAL_CLEAR:
            return { ...state, modal: UsersModal.clear, currentUser: null };
        case USERS_INVITE_MODAL:
            return { ...state, modal: UsersModal.invite };
        case USERS_EDIT_MODAL:
            return { ...state, modal: UsersModal.edit, ...action.payload };
        case GET_USERS_FOR_ADMIN.SUCCESS:
            return { ...state, ...action.payload, usersLoading: false };
        case GET_USERS_FOR_ADMIN.FAILURE:
            return { ...state, ...action.payload, usersLoading: false };
        case INVITE_NEW_USER.SUCCESS:
            return { ...state, users: [...state.users, action.payload] };
        case INVITE_NEW_USER.FAILURE:
            return { ...state, ...action.payload };
        case EDIT_USER.SUCCESS:
            return {
                ...state,
                users: state.users.map(user => {
                    return user.id === action.payload.id
                        ? action.payload
                        : user;
                })
            };
        case EDIT_USER.FAILURE:
            return { ...state, ...action.payload };
        case DELETE_USER.SUCCESS:
            return {
                ...state,
                users: state.users.filter(user => user.id !== action.payload)
            };
        case DELETE_USER.FAILURE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default reducer;
