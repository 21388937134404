import React, { useEffect, useRef } from "react";
import styled from "styled-components/macro";
import {
    FlexCentered,
    FlexVerticallyCentered
} from "../../components/commonStyles";
import withModalPortal from "../../components/withModalPortal";
import { colors } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { selectById } from "../slice";
import CreativeStatusIcon from "./CreativeStatusIcon";
import CreativeIconRow from "./CreativeIconRow";
import { ReactComponent as CloseIcon } from "../../components/icons/close_icon.svg";
import { push } from "connected-react-router";
import CreativeMediaPlayer from "./CreativeMediaPlayer";
import CreativeMessageForm, { MessageFormRef } from "./CreativeMessageForm";
import { clearChecklist } from "../../ui/rules/slice";
import CreativeAssignedScript from "./CreativeAssignedScript";
import { useRouteMatch } from "react-router";

const Main = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0%;
    ${FlexCentered};
    flex-direction: column;
    background-color: ${colors.modalShade};
    font-family: Poppins;
`;

const ModalWrapper = styled.div`
    max-width: 1080px;
    width: 100%;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: fit-content(1em) fit-content(1em) auto;
    grid-auto-rows: fit-content(1em);
    overflow: hidden;
    margin: 60px 0;
`;

const Header = styled.div`
    grid-column: 1 / 4;
    padding: 8px;
    background-color: ${colors.primary100};
    border-radius: 4px;
    ${FlexVerticallyCentered};
`;

const HeaderTitle = styled.div`
    font-size: 14px;
    margin-right: 10px;
`;

const HeaderRightSection = styled.div`
    margin-left: auto;
    font-size: 10px;
    ${FlexVerticallyCentered};

    & > * ~ * {
        margin-left: 10px;
    }
`;

const Close = styled(CloseIcon)`
    margin-left: 16px;
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const MediaPlayer = styled.div`
    grid-row: 2 / 4;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: hidden;
`;

const LayoutCreativeMessageForm = styled(CreativeMessageForm)`
    grid-row: 2 / 4;
    grid-column: 2 / 4;
    box-sizing: border-box;
`;

interface Props {
    creativeId: number;
}

const CreativeModal = ({ creativeId }: Props) => {
    const dispatch = useDispatch();
    const match = useRouteMatch<{ openBuyId: string }>();
    const creative = useSelector((state: GlobalState) =>
        selectById(state, creativeId)
    );
    const messageFormRef = useRef<MessageFormRef>(null);

    useEffect(() => {
        return () => {
            dispatch(clearChecklist());
        };
    }, []);

    if (!creative) return null;

    return (
        <Main
            onMouseDown={() =>
                dispatch(push(`/challenge/${match.params.openBuyId}/creatives`))
            }
        >
            <ModalWrapper onMouseDown={e => e.stopPropagation()}>
                <Header>
                    <HeaderTitle>{creative.openBuy.name}</HeaderTitle>
                    <CreativeStatusIcon
                        status={creative.advertiserReviewStatus}
                        latestEventTime={creative.events[0].createdAt}
                    />
                    <HeaderRightSection>
                        <CreativeIconRow
                            imgSrc={creative.campaign.imageUrl}
                            text={creative.campaign.name}
                        />
                        <CreativeIconRow
                            imgSrc={
                                creative.socialAccount.profilePhotoUrl ||
                                "/account_circle.svg"
                            }
                            text={"@" + creative.socialAccount.username}
                        />
                        <Close
                            onClick={() =>
                                dispatch(
                                    push(
                                        `/challenge/${match.params.openBuyId}/creatives`
                                    )
                                )
                            }
                        />
                    </HeaderRightSection>
                </Header>
                <MediaPlayer>
                    <CreativeMediaPlayer mediaId={creative.media} />
                    {creative.script && (
                        <CreativeAssignedScript script={creative.script} />
                    )}
                </MediaPlayer>

                <LayoutCreativeMessageForm
                    ref={messageFormRef}
                    ruleSetId={creative.openBuy.ruleSetId}
                    creativeId={creative.id}
                    status={creative.advertiserReviewStatus}
                />
            </ModalWrapper>
        </Main>
    );
};
export default withModalPortal(CreativeModal);
