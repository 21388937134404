import React, { ReactNode } from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useLocation } from "react-router";
import LinkContainer from "./LinkContainer";
import LinkText from "./LinkText";

const IconContainer = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
        width: 24px;
        height: 24px;
    }
`;

interface Props {
    Icon: ReactNode;
    to: string;
    text: string;
    className?: string;
}

const SidebarLink = ({ Icon, to, text, className }: Props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    return (
        <LinkContainer
            className={className}
            onClick={() => dispatch(push(to))}
            active={location.pathname === to}
        >
            <IconContainer>{Icon}</IconContainer>
            <LinkText>{text}</LinkText>
        </LinkContainer>
    );
};
export default SidebarLink;
