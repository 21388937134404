import { Action } from "../types";
import {
    Bid,
    Campaign,
    DailySpend,
    MediaModalInfo,
    MediaTestModalInfo,
    MediaTests,
    NoticeInfo,
    PlatformTraffic,
    RegionTraffic,
    ReportRow
} from "./Campaign";

export const FETCH_CAMPAIGNS = {
    REQUEST: "FETCH_CAMPAIGNS_REQUEST",
    SUCCESS: "FETCH_CAMPAIGNS_SUCCESS",
    FAILURE: "FETCH_CAMPAIGNS_FAILURE"
};

export interface FetchCampaignsParams {
    start_date: string;
    end_date: string;
}

export const fetchCampaigns = (
    fetchCampaignsParams: FetchCampaignsParams
): Action => ({
    type: FETCH_CAMPAIGNS.REQUEST,
    payload: fetchCampaignsParams
});

export const fetchCampaignsSuccess = (campaigns: any): Action => ({
    type: FETCH_CAMPAIGNS.SUCCESS,
    payload: campaigns
});

export const fetchCampaignsFailure = (error: string): Action => ({
    type: FETCH_CAMPAIGNS.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const FETCH_REPORT = {
    REQUEST: "FETCH_REPORT_REQUEST",
    SUCCESS: "FETCH_REPORT_SUCCESS",
    FAILURE: "FETCH_REPORT_FAILURE"
};

export interface FetchReportParams {
    start_date: string;
    end_date: string;
    columns: Array<
        | "date"
        | "campaign_name"
        | "campaign"
        | "publisher"
        | "media"
        | "media_url"
        | "country"
        | "platform"
        | "clicks"
        | "installs"
        | "cost"
        | "conversion_rate"
        | "average_cpa"
        | "average_cpc"
    >;
}

export const fetchReport = (params: FetchReportParams): Action => ({
    type: FETCH_REPORT.REQUEST,
    payload: params
});

export const fetchReportSuccess = (report: ReportRow[]): Action => ({
    type: FETCH_REPORT.SUCCESS,
    payload: { report }
});

export const fetchReportFailure = (error: string): Action => ({
    type: FETCH_REPORT.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const GET_DAILY_SPEND = {
    REQUEST: "GET_DAILY_SPEND_REQUEST",
    SUCCESS: "GET_DAILY_SPEND_SUCCESS",
    FAILURE: "GET_DAILY_SPEND_FAILURE"
};

export interface GetDailySpendParams {
    start_date: string;
    end_date: string;
}

export const getDailySpend = (params: GetDailySpendParams): Action => ({
    type: GET_DAILY_SPEND.REQUEST,
    payload: params
});

export const getDailySpendSuccess = (dailySpend: DailySpend[]): Action => ({
    type: GET_DAILY_SPEND.SUCCESS,
    payload: { dailySpend }
});

export const getDailySpendFailure = (error: string): Action => ({
    type: GET_DAILY_SPEND.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const GET_MEDIA_REPORT = {
    REQUEST: "GET_MEDIA_REPORT_REQUEST",
    SUCCESS: "GET_MEDIA_REPORT_SUCCESS",
    FAILURE: "GET_MEDIA_REPORT_FAILURE"
};

export interface GetMediaParams {
    start_date: string;
    end_date: string;
}

export const getMediaReport = (params: GetMediaParams): Action => ({
    type: GET_MEDIA_REPORT.REQUEST,
    payload: params
});

export const getMediaReportSuccess = (mediaReport: ReportRow[]): Action => ({
    type: GET_MEDIA_REPORT.SUCCESS,
    payload: { mediaReport }
});

export const getMediaReportFailure = (error: string): Action => ({
    type: GET_MEDIA_REPORT.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const GET_MEDIA_TEST = {
    REQUEST: "GET_MEDIA_TEST_REQUEST",
    SUCCESS: "GET_MEDIA_TEST_SUCCESS",
    FAILURE: "GET_MEDIA_TEST_FAILURE"
};

export interface GetMediaTestParams {
    startDate: string;
    endDate: string;
    campaignId: number;
}

export const getMediaTest = (params: GetMediaTestParams): Action => ({
    type: GET_MEDIA_TEST.REQUEST,
    payload: params
});

interface MediaTestResults {
    medias: MediaTests[];
    weightedAverage: number;
}

export const getMediaTestSuccess = (payload: MediaTestResults): Action => ({
    type: GET_MEDIA_TEST.SUCCESS,
    payload: {
        mediaTests: payload.medias,
        weightedAverage: payload.weightedAverage
    }
});

export const getMediaTestFailure = (error: string): Action => ({
    type: GET_MEDIA_TEST.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const REMOVE_MEDIA_FROM_APP = {
    REQUEST: "REMOVE_MEDIA_FROM_APP_REQUEST",
    SUCCESS: "REMOVE_MEDIA_FROM_APP_SUCCESS",
    FAILURE: "REMOVE_MEDIA_FROM_APP_FAILURE"
};

export const removeMediaFromApp = (media_id: number): Action => ({
    type: REMOVE_MEDIA_FROM_APP.REQUEST,
    payload: { media_id }
});

export const removeMediaFromAppFailure = (error: string): Action => ({
    type: REMOVE_MEDIA_FROM_APP.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const REQUEST_CAMPAIGN_RESTART = {
    REQUEST: "REQUEST_CAMPAIGN_RESTART_REQUEST",
    SUCCESS: "REQUEST_CAMPAIGN_RESTART_SUCCESS",
    FAILURE: "REQUEST_CAMPAIGN_RESTART_FAILURE"
};

export const requestCampaignRestart = (campaign_id: number): Action => ({
    type: REQUEST_CAMPAIGN_RESTART.REQUEST,
    payload: { campaign_id }
});

export const requestCampaignRestartSuccess = (campaign_id: number): Action => ({
    type: REQUEST_CAMPAIGN_RESTART.SUCCESS,
    payload: campaign_id
});

export const requestCampaignRestartFailure = (error: string): Action => ({
    type: REQUEST_CAMPAIGN_RESTART.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const CREATE_NEW_CAMPAIGN = {
    REQUEST: "CREATE_NEW_CAMPAIGN_REQUEST",
    SUCCESS: "CREATE_NEW_CAMPAIGN_SUCCESS",
    FAILURE: "CREATE_NEW_CAMPAIGN_FAILURE"
};

export interface CreateCampaignParams {
    reward_type: "install";
    balance: 100000;
    link_service: string;
    ios_app_id?: string;
    android_app_id?: string;
    ios_tracking_link?: string;
    android_tracking_link?: string;
}

export const createNewCampaign = (params: CreateCampaignParams): Action => ({
    type: CREATE_NEW_CAMPAIGN.REQUEST,
    payload: params
});

export const createNewCampaignFailure = (error: string): Action => ({
    type: CREATE_NEW_CAMPAIGN.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const EDIT_CAMPAIGN = {
    REQUEST: "EDIT_CAMPAIGN_REQUEST",
    SUCCESS: "EDIT_CAMPAIGN_SUCCESS",
    FAILURE: "EDIT_CAMPAIGN_FAILURE"
};

export interface EditCampaignParams {
    campaign_id: number;
    link_service?: string;
    ios_app_id?: string;
    android_app_id?: string;
    ios_tracking_link?: string;
    android_tracking_link?: string;
}

export const editCampaign = (params: EditCampaignParams): Action => ({
    type: EDIT_CAMPAIGN.REQUEST,
    payload: params
});

export const editCampaignFailure = (error: string): Action => ({
    type: EDIT_CAMPAIGN.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const GET_BIDS_FOR_CAMPAIGN = {
    REQUEST: "GET_BIDS_FOR_CAMPAIGN_REQUEST",
    SUCCESS: "GET_BIDS_FOR_CAMPAIGN_SUCCESS",
    FAILURE: "GET_BIDS_FOR_CAMPAIGN_FAILURE"
};

export const getBidsForCampaign = (campaign_id: number): Action => ({
    type: GET_BIDS_FOR_CAMPAIGN.REQUEST,
    payload: { campaign_id }
});

export const getBidsForCampaignSuccess = (bids: Bid[]): Action => ({
    type: GET_BIDS_FOR_CAMPAIGN.SUCCESS,
    payload: { bids }
});

export const getBidsForCampaignFailure = (error: string): Action => ({
    type: GET_BIDS_FOR_CAMPAIGN.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const FETCH_PUBLISHER_BIDS_FOR_CAMPAIGN = {
    REQUEST: "FETCH_PUBLISHER_BIDS_FOR_CAMPAIGN_REQUEST",
    SUCCESS: "FETCH_PUBLISHER_BIDS_FOR_CAMPAIGN_SUCCESS",
    FAILURE: "FETCH_PUBLISHER_BIDS_FOR_CAMPAIGN_FAILURE"
};

export const fetchPublisherBidsForCampaign = (campaign_id: number): Action => ({
    type: FETCH_PUBLISHER_BIDS_FOR_CAMPAIGN.REQUEST,
    payload: { campaign_id }
});

export const fetchPublisherBidsForCampaignSuccess = (
    publisherBids: Bid[]
): Action => ({
    type: FETCH_PUBLISHER_BIDS_FOR_CAMPAIGN.SUCCESS,
    payload: { publisherBids }
});

export const fetchPublisherBidsForCampaignFailure = (
    error: string
): Action => ({
    type: FETCH_PUBLISHER_BIDS_FOR_CAMPAIGN.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const GET_PLATFORM_TRAFFIC = {
    REQUEST: "GET_PLATFORM_TRAFFIC_REQUEST",
    SUCCESS: "GET_PLATFORM_TRAFFIC_SUCCESS",
    FAILURE: "GET_PLATFORM_TRAFFIC_FAILURE"
};

export const getPlatformTraffic = (): Action => ({
    type: GET_PLATFORM_TRAFFIC.REQUEST,
    payload: null
});

export const getPlatformTrafficSuccess = (
    platformTraffic: PlatformTraffic[]
): Action => ({
    type: GET_PLATFORM_TRAFFIC.SUCCESS,
    payload: { platformTraffic }
});

export const getPlatformTrafficFailure = (error: string): Action => ({
    type: GET_PLATFORM_TRAFFIC.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const GET_REGION_TRAFFIC = {
    REQUEST: "GET_REGION_TRAFFIC_REQUEST",
    SUCCESS: "GET_REGION_TRAFFIC_SUCCESS",
    FAILURE: "GET_REGION_TRAFFIC_FAILURE"
};

export interface RegionTrafficParams {
    ios?: true;
    android?: true;
}

export const getRegionTraffic = (params: RegionTrafficParams): Action => ({
    type: GET_REGION_TRAFFIC.REQUEST,
    payload: params
});

export const getRegionTrafficSuccess = (
    regionTraffic: RegionTraffic[]
): Action => ({
    type: GET_REGION_TRAFFIC.SUCCESS,
    payload: { regionTraffic }
});

export const getRegionTrafficFailure = (error: string): Action => ({
    type: GET_REGION_TRAFFIC.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const GET_VERIFIED_PUBLISHERS = {
    REQUEST: "GET_VERIFIED_PUBLISHERS_REQUEST",
    SUCCESS: "GET_VERIFIED_PUBLISHERS_SUCCESS",
    FAILURE: "GET_VERIFIED_PUBLISHERS_FAILURE"
};

export const getVerifiedPublishers = (): Action => ({
    type: GET_VERIFIED_PUBLISHERS.REQUEST,
    payload: null
});

export const getVerifiedPublishersSuccess = (
    verifiedPublishers: string[]
): Action => ({
    type: GET_VERIFIED_PUBLISHERS.SUCCESS,
    payload: { verifiedPublishers }
});

// -------------------------------------------------------------------

export const ADD_PENDING_BID_UPDATES = {
    REQUEST: "ADD_PENDING_BID_UPDATES_REQUEST",
    SUCCESS: "ADD_PENDING_BID_UPDATES_SUCCESS",
    FAILURE: "ADD_PENDING_BID_UPDATES_FAILURE"
};

export interface AddBidsParams {
    campaign_id: number;
    prices_to_update: string;
}

export const addPendingBidUpdates = (params: AddBidsParams): Action => ({
    type: ADD_PENDING_BID_UPDATES.REQUEST,
    payload: params
});

export const addPendingBidUpdatesFailure = (error: string): Action => ({
    type: ADD_PENDING_BID_UPDATES.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const ADD_PENDING_PUBLISHER_BID_UPDATES = {
    REQUEST: "ADD_PENDING_PUBLISHER_BID_UPDATES_REQUEST",
    SUCCESS: "ADD_PENDING_PUBLISHER_BID_UPDATES_SUCCESS",
    FAILURE: "ADD_PENDING_PUBLISHER_BID_UPDATES_FAILURE"
};

export const submitPendingPublisherBidUpdates = (
    params: AddBidsParams
): Action => ({
    type: ADD_PENDING_PUBLISHER_BID_UPDATES.REQUEST,
    payload: params
});

export const submitPendingPublisherBidUpdatesFailure = (
    error: string
): Action => ({
    type: ADD_PENDING_PUBLISHER_BID_UPDATES.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const UPLOAD_MEDIA = {
    REQUEST: "UPLOAD_MEDIA_REQUEST",
    SUCCESS: "UPLOAD_MEDIA_SUCCESS",
    FAILURE: "UPLOAD_MEDIA_FAILURE"
};

export interface UploadMediaParams {
    campaign_id: number;
    media: Array<{
        key: string;
        type: string;
    }>;
}

export const uploadMedia = (payload: UploadMediaParams): Action => ({
    type: UPLOAD_MEDIA.REQUEST,
    payload
});

export const uploadMediaSuccess = (): Action => ({
    type: UPLOAD_MEDIA.SUCCESS,
    payload: null
});

export const uploadMediaFailure = (): Action => ({
    type: UPLOAD_MEDIA.FAILURE,
    payload: null
});

// -------------------------------------------------------------------

export const SUBMIT_TEST_MEDIA_PURCHASE = {
    REQUEST: "SUBMIT_TEST_MEDIA_PURCHASE_REQUEST",
    SUCCESS: "SUBMIT_TEST_MEDIA_PURCHASE_SUCCESS",
    FAILURE: "SUBMIT_TEST_MEDIA_PURCHASE_FAILURE"
};

export interface TestMediaPurchaseRequestParams {
    media_id: number;
    campaign_id: number;
    campaign_name: string;
    account_management_owner: string | null;
    company_name: string;
}

export const submitTestMediaPurchaseRequest = (
    payload: TestMediaPurchaseRequestParams
) => ({
    type: SUBMIT_TEST_MEDIA_PURCHASE.REQUEST,
    payload
});

export const submitTestMediaPurchaseRequestSuccess = (): Action => ({
    type: SUBMIT_TEST_MEDIA_PURCHASE.SUCCESS,
    payload: null
});

// -------------------------------------------------------------------

export const SET_CURRENT_CAMPAIGN = "SET_CURRENT_CAMPAIGN";

export const setCurrentCampaign = (currentCampaign: Campaign): Action => ({
    type: SET_CURRENT_CAMPAIGN,
    payload: { currentCampaign }
});

// -------------------------------------------------------------------

export const CAMPAIGNS_RESTART_MODAL = "CAMPAIGNS_RESTART_MODAL";

export const campaignsRestartModal = (currentCampaign: Campaign): Action => ({
    type: CAMPAIGNS_RESTART_MODAL,
    payload: { currentCampaign }
});

// -------------------------------------------------------------------

export const BID_COUNTRIES_MODAL = "BID_COUNTRIES_MODAL";

export const bidCountriesModal = (currentCountries: string[]): Action => ({
    type: BID_COUNTRIES_MODAL,
    payload: { currentCountries }
});

// -------------------------------------------------------------------

export const ADD_BID_MODAL = "ADD_BID_MODAL";

export const addBidModal = (bidPrefill?: Bid): Action => ({
    type: ADD_BID_MODAL,
    payload: { bidPrefill }
});

// -------------------------------------------------------------------

export const ADD_PUBLISHER_BID_MODAL = "ADD_PUBLISHER_BID_MODAL";

export const addPublisherBidModal = (bidPrefill?: Bid): Action => ({
    type: ADD_PUBLISHER_BID_MODAL,
    payload: { bidPrefill }
});

// -------------------------------------------------------------------

export const MEDIA_VIEWER_MODAL = "MEDIA_VIEWER_MODAL";

export const mediaViewerModal = (mediaModalInfo: MediaModalInfo): Action => ({
    type: MEDIA_VIEWER_MODAL,
    payload: { mediaModalInfo }
});

// -------------------------------------------------------------------

export const REMOVE_MEDIA_MODAL = "REMOVE_MEDIA_MODAL";

export const removeMediaModal = (mediaModalInfo: MediaModalInfo): Action => ({
    type: REMOVE_MEDIA_MODAL,
    payload: { mediaModalInfo }
});

// -------------------------------------------------------------------

export const MEDIA_UPLOAD_MODAL = "MEDIA_UPLOAD_MODAL";

export const mediaUploadModal = (): Action => ({
    type: MEDIA_UPLOAD_MODAL,
    payload: null
});

// -------------------------------------------------------------------

export const MEDIA_TEST_VIEWER_MODAL = "MEDIA_TEST_VIEWER_MODAL";

export const mediaTestViewerModal = (
    mediaTestModalInfo: MediaTestModalInfo
): Action => ({
    type: MEDIA_TEST_VIEWER_MODAL,
    payload: { mediaTestModalInfo }
});

// -------------------------------------------------------------------

export const NOTICE_MODAL = "NOTICE_MODAL";

export const noticeModal = (noticeInfo: NoticeInfo): Action => ({
    type: NOTICE_MODAL,
    payload: { noticeInfo }
});
