import React from "react";
import { connect } from "react-redux";
import isPropValid from "@emotion/is-prop-valid";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import Logout from "./Logout";
import Auth from "./auth";
import Dashboard from "./dashboard";
import ExternalMediaApprovals from "./external/ExternalMediaApprovals";

const AppRouter = ({ history }: { history: any }) => {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route
                    path="/temp/:token/media-approvals"
                    exact
                    render={({ match }) => (
                        <ExternalMediaApprovals token={match.params.token} />
                    )}
                />
                <Route path="/logout" component={Logout} />
                <Route path="/login" component={Auth} />
                <Route path="/" component={Dashboard} />
            </Switch>
        </ConnectedRouter>
    );
};

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(null, mapDispatchToProps)(AppRouter);
