import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface RulesUIState {
    [ruleName: string]: boolean | undefined;
}

const initialState: RulesUIState = {};

const rulesUISlice = createSlice({
    name: "rulesUI",
    initialState,
    reducers: {
        updateChecklist(
            state,
            action: PayloadAction<{ [ruleName: string]: boolean | undefined }>
        ) {
            return { ...state, ...action.payload };
        },
        clearChecklist(state) {
            return {};
        }
    }
});

export const { updateChecklist, clearChecklist } = rulesUISlice.actions;

const rulesUIReducer = rulesUISlice.reducer;
export default rulesUIReducer;
