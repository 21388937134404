import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { fetch } from "./slice";
import CreativeCard from "./components/CreativeCard";
import CreativeModal from "./components/CreativeModal";
import CreativeFilterDropdown from "./components/CreativeFilterDropdown";
import { getFilteredCreatives } from "./selectors";
import { GlobalState } from "../reducers";
import { Shimmer } from "../components/SkeletonLoader";

const Main = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-family: Poppins;
    font-size: 36px;
    font-weight: 500;
    margin-top: 36px;
    margin-bottom: 20px;
    line-height: 54px;
`;

const FiltersWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    & > * ~ * {
        margin-left: 20px;
    }
`;

const MediaShimmer = styled.div`
    ${Shimmer}

    border-radius: 20px;
    &:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        padding-bottom: calc(16 / 9 * 100%);
    }
`;

const List = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 18px;
`;

interface Props {
    creativeId?: number;
    openBuyId: number;
}

const Creatives = ({ creativeId, openBuyId }: Props) => {
    const dispatch = useDispatch();
    const allCreatives = useSelector((state: GlobalState) =>
        getFilteredCreatives(state, openBuyId)
    );
    const creativesLoading = useSelector(
        (state: GlobalState) => state.ui.creatives.creativesLoading
    );

    useEffect(() => {
        dispatch(fetch({ openBuyId }));
    }, []);

    return (
        <Main>
            <Title>Creatives</Title>
            <FiltersWrapper>
                {/* <CreativeChallengeDropdown /> */}
                <CreativeFilterDropdown />
            </FiltersWrapper>
            <List>
                {creativesLoading
                    ? Array(10)
                          .fill("")
                          .map((e, i) => <MediaShimmer key={i} />)
                    : allCreatives.map(creative => (
                          <CreativeCard creative={creative} key={creative.id} />
                      ))}
            </List>
            {creativeId && <CreativeModal creativeId={creativeId} />}
        </Main>
    );
};
export default Creatives;
