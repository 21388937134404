import { colors } from "./constants";
import { MediaTests } from "./campaigns/Campaign";

export const getUrlParameter = (name: string) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const isLoggedIn = () => {
    return localStorage.getItem("token") && localStorage.getItem("userid");
};

export const formatNumber = (num: number | string) => {
    if (typeof num === "number") {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
        return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
};

export const download = (content: any, fileName: string, mimeType: string) => {
    const a = document.createElement("a");
    mimeType = mimeType || "application/octet-stream";

    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(
            new Blob([content], {
                type: mimeType
            }),
            fileName
        );
    } else if (URL && "download" in a) {
        a.href = URL.createObjectURL(
            new Blob([content], {
                type: mimeType
            })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else {
        window.location.href =
            "data:application/octet-stream," + encodeURIComponent(content);
    }
};

export const errorToString = (error: any) => {
    return error.response && error.response.data
        ? error.response.data
        : error.message;
};

export const makeId = (length: number) => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * characters.length)
        );
    }
    return result;
};

export const CPMtoBuckets = (cpm: number | null) => {
    if (!cpm) return "Pending";

    if (cpm < 0.1) {
        return "$0-0.1";
    } else if (cpm < 0.25) {
        return "$0.10-0.25";
    } else {
        for (let i = 0.5; i <= 2; i += 0.25) {
            if (cpm < i) {
                return `$${i - 0.25}-${i}`;
            }
        }
        return `$2.00+`;
    }
};

export const indicatorColor = (cpm: number | null) => {
    if (!cpm) return "#000000";

    if (cpm < 0.25) {
        return colors.indicatorRed;
    } else if (cpm < 1) {
        return colors.indicatorYellow;
    }
    return colors.indicatorGreen;
};

export const aggregateScheduledTestMedia = (rawMediaTests: MediaTests[]) => {
    const results: MediaTests[] = [];
    const mediaTests: { [id: number]: MediaTests } = {};
    rawMediaTests.forEach((media: MediaTests) => {
        media.groupCount = 1;
        if (!media.cpm) {
            mediaTests[media.mediaId] = mediaTests[media.mediaId]
                ? {
                      ...mediaTests[media.mediaId],
                      accountTheme: null,
                      platform: null,
                      runDate: null,
                      groupCount: mediaTests[media.mediaId].groupCount + 1
                  }
                : {
                      ...media,
                      cpm: null
                  };
        } else {
            results.push(media);
        }
    });
    const sortedResults = Object.values(mediaTests).concat(results);

    return sortedResults;
};

export const coinToDollars = (coin: number, asNumber?: boolean) => {
    const roundeven = (x: number) => {
        return (
            Math.sign(x) *
            (Math.abs(x) + 4.503599627370496e15 - 4.503599627370496e15)
        );
    };
    let ans = (roundeven(coin * 5) / 100).toFixed(2);
    return asNumber ? ans : `$${ans}`;
};
