import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { Advertiser } from "./Profile";
import styled from "styled-components/macro";
import Modal, { clearModal } from "../components/Modal";
import { ModalButton } from "../components/styledComponents";
import { getAdvertisersIndex, loginAsAdvertiser } from "./actions";
import CustomSearchSelect from "../components/CustomSearchSelect";
import { colors } from "../constants";

const Main = styled.div`
    padding: 0 30px;
    display: flex;
    flex-direction: column;
`;

const Description = styled.div`
    margin-bottom: 15px;
`;

const ButtonContainer = styled.div`
    margin: 15px 0 30px auto;
`;

const Option = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        background: ${colors.neutral100};
    }
`;

const EmptyMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 8px;
`;

interface Props {
    advertiser?: Advertiser;
    advertisersIndex: Advertiser[];
    loginAsAdvertiser: (advertiserId: number) => void;
    getAdvertisersIndex: () => void;
    clearModal: () => void;
}

const LoginAsAdvertiserModal = ({
    advertiser,
    advertisersIndex,
    loginAsAdvertiser,
    getAdvertisersIndex,
    clearModal
}: Props) => {
    useEffect(() => {
        getAdvertisersIndex();
    }, []);

    const [searchString, setSearchString] = useState("");
    const [selectedAdvertiser, setSelectedAdvertiser] = useState<Advertiser>();

    const menuItems = advertisersIndex
        .filter(advertiserObject => {
            if (searchString.match(/^-{0,1}\d+$/)) {
                return advertiserObject.id.toString().includes(searchString);
            } else {
                return (
                    advertiserObject.company_name
                        .toLowerCase()
                        .includes(searchString.toLowerCase()) || !searchString
                );
            }
        })
        .sort((obj1, obj2) => {
            if (searchString.match(/^-{0,1}\d+$/)) {
                return obj1.id - obj2.id;
            } else {
                return 0;
            }
        })
        .slice(0, 5)
        .map(advertiserObject => {
            return (
                <Option
                    key={advertiserObject.id}
                    onClick={() => setSelectedAdvertiser(advertiserObject)}
                >
                    {advertiserObject.company_name}
                </Option>
            );
        });

    if (advertiser && advertiser.id === 1) {
        return (
            <Modal>
                <Main>
                    <h2>Login As Advertiser</h2>
                    <Description>INTERNAL USE ONLY</Description>
                    <CustomSearchSelect
                        selected={
                            selectedAdvertiser &&
                            selectedAdvertiser.company_name
                        }
                        searchString={searchString}
                        setSearchString={setSearchString}
                        placeholder={"Select an advertiser"}
                    >
                        {menuItems.length > 0 ? (
                            menuItems
                        ) : (
                            <EmptyMenu>No advertisers found</EmptyMenu>
                        )}
                    </CustomSearchSelect>
                    <ButtonContainer>
                        <ModalButton
                            onClick={() =>
                                selectedAdvertiser &&
                                loginAsAdvertiser(selectedAdvertiser.id)
                            }
                        >
                            Submit
                        </ModalButton>
                    </ButtonContainer>
                </Main>
            </Modal>
        );
    } else {
        clearModal();
        return null;
    }
};

const mapStateToProps = (state: GlobalState) => ({
    advertiser: state.profile.advertiser,
    advertisersIndex: state.profile.advertisersIndex
});

const mapDispatchToProps = (dispatch: any) => ({
    loginAsAdvertiser: (advertiserId: number) =>
        dispatch(loginAsAdvertiser(advertiserId)),
    getAdvertisersIndex: () => dispatch(getAdvertisersIndex()),
    clearModal: () => dispatch(clearModal())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginAsAdvertiserModal);
