import { Action } from "../types";
import { Advertiser, User } from "./Profile";

export const GET_ADVERTISER_USER_DATA = {
    REQUEST: "GET_ADVERTISER_USER_DATA_REQUEST",
    SUCCESS: "GET_ADVERTISER_USER_DATA_SUCCESS",
    FAILURE: "GET_ADVERTISER_USER_DATA_FAILURE"
};

export const getAdvertiserUserData = (): Action => ({
    type: GET_ADVERTISER_USER_DATA.REQUEST,
    payload: null
});

export const getAdvertiserUserDataSuccess = (payload: {
    user: User;
    advertiser: Advertiser;
}): Action => ({
    type: GET_ADVERTISER_USER_DATA.SUCCESS,
    payload
});

export const getAdvertiserUserDataFailure = (error: string): Action => ({
    type: GET_ADVERTISER_USER_DATA.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const GET_SUPPORT_EMAIL = {
    REQUEST: "GET_SUPPORT_EMAIL_REQUEST",
    SUCCESS: "GET_SUPPORT_EMAIL_SUCCESS",
    FAILURE: "GET_SUPPORT_EMAIL_FAILURE"
};

export const getSupportEmail = (): Action => ({
    type: GET_SUPPORT_EMAIL.REQUEST,
    payload: null
});

export const getSupportEmailSuccess = (supportEmail: string): Action => ({
    type: GET_SUPPORT_EMAIL.SUCCESS,
    payload: { supportEmail }
});

export const getSupportEmailFailure = (error: string): Action => ({
    type: GET_SUPPORT_EMAIL.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const SUBMIT_EVENT = "SUBMIT_EVENT";

export const submitEvent = (event_name: string): Action => ({
    type: SUBMIT_EVENT,
    payload: { event_name }
});

// -------------------------------------------------------------------

export const SUPPORT_MODAL = "SUPPORT_MODAL";

export const supportModal = (): Action => ({
    type: SUPPORT_MODAL,
    payload: null
});

// -------------------------------------------------------------------

export const GET_ADVERTISERS_INDEX = {
    REQUEST: "GET_ADVERTISERS_INDEX_REQUEST",
    SUCCESS: "GET_ADVERTISERS_INDEX_SUCCESS",
    FAILURE: "GET_ADVERTISERS_INDEX_FAILURE"
};

export const getAdvertisersIndex = (): Action => ({
    type: GET_ADVERTISERS_INDEX.REQUEST,
    payload: null
});

export const getAdvertisersIndexSuccess = (
    advertisersIndex: Advertiser[]
): Action => ({
    type: GET_ADVERTISERS_INDEX.SUCCESS,
    payload: { advertisersIndex }
});

// -------------------------------------------------------------------

export const LOGIN_AS_ADVERTISER = "LOGIN_AS_ADVERTISER";

export const loginAsAdvertiser = (advertiser_id: number): Action => ({
    type: LOGIN_AS_ADVERTISER,
    payload: { advertiser_id }
});

// -------------------------------------------------------------------

export const LOGIN_AS_ADVERTISER_MODAL = "LOGIN_AS_ADVERTISER_MODAL";

export const loginAsAdvertiserModal = (): Action => ({
    type: LOGIN_AS_ADVERTISER_MODAL,
    payload: null
});

// -------------------------------------------------------------------

export const NOTIFICATION = {
    ADD: "NOTIFICATION_ADD",
    REMOVE: "NOTIFICATION_REMOVE"
};

export const addNotification = (notification: string): Action => ({
    type: NOTIFICATION.ADD,
    payload: { notification }
});

export const removeNotification = (): Action => ({
    type: NOTIFICATION.REMOVE,
    payload: null
});

// -------------------------------------------------------------------

export const LOG_OUT = "LOG_OUT";

export const logout = (): Action => ({
    type: LOG_OUT,
    payload: null
});
