import React from "react";
import styled, { css } from "styled-components/macro";
import MarkBtn from "./toolbar-btns/MarkBtn";
import BlockBtn from "./toolbar-btns/BlockBtn";
import { LinkMark } from "./toolbar-btns/DropdownBtn";
import { ReactComponent as BoldIcon } from "../icons/bold-icon.svg";
import { ReactComponent as ItalicIcon } from "../icons/italic-icon.svg";
import { ReactComponent as UlIcon } from "../icons/unordered-list-icon.svg";
import { ReactComponent as OlIcon } from "../icons/ordered-list-icon.svg";
import Tab from "../Tab";
import { colors } from "../../constants";

const Wrapper = styled.div<{ toolbarStyles: any }>`
    display: flex;
    ${props => css`
        ${props.toolbarStyles}
    `}
`;
const ToolsWrap = styled.div<{ showPreview: boolean }>`
    display: ${props => (props.showPreview ? "none" : "flex")};
    align-items: center;
    padding: 4px;
    border-radius: 8px;
    background-color: white;
    width: fit-content;
    box-shadow: rgba(189, 190, 198, 0.3) 0px 6px 12px 0px;
`;
const PreviewTabs = styled.div`
    display: flex;
    background-color: ${colors.white};
    margin-left: auto;
    border: ${colors.mediumTeal} solid 1px;
    border-radius: 8px;
    overflow: hidden;
    height: 42px;
    width: 150px;
`;
const PreviewTab = styled(Tab)`
    padding: 8px 12px;
    display: grid;
    place-items: center;
    @media (max-width: 960px) {
        border-bottom: initial;
    }
`;
// const Tab = styled.div``;
interface Props {
    className?: string;
    toolbarStyles?: any;
    shouldShowPreviewBtn: boolean;
    showPreview: boolean;
    togglePreview: () => void;
}

const Toolbar = ({
    className,
    toolbarStyles,
    togglePreview,
    shouldShowPreviewBtn,
    showPreview
}: Props) => {
    return (
        <Wrapper className={className} toolbarStyles={toolbarStyles}>
            <ToolsWrap showPreview={showPreview}>
                <MarkBtn format="bold">
                    <BoldIcon style={{ transform: "translateX(1px)" }} />
                </MarkBtn>
                <MarkBtn format="italic">
                    <ItalicIcon />
                </MarkBtn>
                <LinkMark />
                <BlockBtn format="ul_list">
                    <UlIcon />
                </BlockBtn>
                <BlockBtn format="ol_list">
                    <OlIcon />
                </BlockBtn>
            </ToolsWrap>
            {shouldShowPreviewBtn ? (
                <PreviewTabs>
                    <PreviewTab
                        onClick={togglePreview}
                        active={!showPreview}
                        name="Editor"
                    />
                    <PreviewTab
                        onClick={togglePreview}
                        active={showPreview}
                        name="Preview"
                    />
                </PreviewTabs>
            ) : null}
        </Wrapper>
    );
};

export default Toolbar;
