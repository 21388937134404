import React from "react";
import styled from "styled-components/macro";
import { VerticallyCentered } from "./styledComponents";
import { colors, container } from "../constants";

interface styleProps {
    height: number;
}

const Main = styled.div`
    position: relative;
`;

const Button = styled(VerticallyCentered)<styleProps>`
    position: relative;
    background-color: ${colors.neutral100};
    height: ${props => props.height}px;
    padding: 0 16px;
    border-radius: 14px;
    cursor: pointer;
    user-select: none;
`;

const Placeholder = styled.span`
    color: ${colors.darkGray};
`;

const DownCaret = styled.img`
    margin-left: auto;
`;

const UpCaret = styled.img`
    margin-left: auto;
    transform: rotate(0.5turn);
`;

const Menu = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 7px);
    padding: 12px 0;
    border-radius: 6px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    user-select: none;
    z-index: 1;
    ${container}
`;

const Input = styled.input`
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    box-sizing: border-box;
    font-size: 13px;
    font-family: "San Francisco", -apple-system, BlinkMacSystemFont,
        "Helvetica Neue", "Helvetica", sans-serif;
`;

interface Props {
    selected?: string;
    height?: number;
    children: any;
    searchString: string;
    setSearchString: (searchString: string) => void;
    placeholder?: string;
}

interface State {
    showMenu: boolean;
}

class CustomSearchSelect extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showMenu: false
        };

        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    showMenu() {
        this.setState({ showMenu: true }, () => {
            document.addEventListener("click", this.closeMenu);
        });
    }

    closeMenu() {
        this.setState({ showMenu: false }, () => {
            document.removeEventListener("click", this.closeMenu);
            this.props.setSearchString("");
        });
    }

    renderContent() {
        if (this.state.showMenu) {
            return (
                <Input
                    autoFocus={true}
                    value={this.props.searchString}
                    onChange={(e: any) =>
                        this.props.setSearchString(e.currentTarget.value)
                    }
                />
            );
        }

        if (this.props.selected) {
            return this.props.selected;
        } else {
            return (
                <Placeholder>
                    {this.props.placeholder || "Select an option"}
                </Placeholder>
            );
        }
    }

    render() {
        const height = this.props.height || 40;

        return (
            <Main>
                <Button onClick={this.showMenu} height={height}>
                    {this.renderContent()}
                    {this.state.showMenu ? (
                        <UpCaret src={"/caret.svg"} />
                    ) : (
                        <DownCaret src={"/caret.svg"} />
                    )}
                </Button>
                {this.state.showMenu && <Menu>{this.props.children}</Menu>}
            </Main>
        );
    }
}

export default CustomSearchSelect;
