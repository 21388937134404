import { Action } from "../types";
import { User } from "../dashboard/Profile";

export const GET_USERS_FOR_ADMIN = {
    REQUEST: "GET_USERS_FOR_ADMIN_REQUEST",
    SUCCESS: "GET_USERS_FOR_ADMIN_SUCCESS",
    FAILURE: "GET_USERS_FOR_ADMIN_FAILURE"
};

export const getUsersForAdmin = (): Action => ({
    type: GET_USERS_FOR_ADMIN.REQUEST,
    payload: null
});

export const getUsersForAdminSuccess = (users: User[]): Action => ({
    type: GET_USERS_FOR_ADMIN.SUCCESS,
    payload: { users }
});

export const getUsersForAdminFailure = (error: string): Action => ({
    type: GET_USERS_FOR_ADMIN.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const INVITE_NEW_USER = {
    REQUEST: "INVITE_NEW_USER_REQUEST",
    SUCCESS: "INVITE_NEW_USER_SUCCESS",
    FAILURE: "INVITE_NEW_USER_FAILURE"
};

export interface InviteNewUserParams {
    firstname: string;
    lastname: string;
    email: string;
    is_admin: boolean;
}

export const inviteNewUser = (params: InviteNewUserParams): Action => ({
    type: INVITE_NEW_USER.REQUEST,
    payload: params
});

export const inviteNewUserSuccess = (newUser: User): Action => ({
    type: INVITE_NEW_USER.SUCCESS,
    payload: newUser
});

export const inviteNewUserFailure = (error: string): Action => ({
    type: INVITE_NEW_USER.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const EDIT_USER = {
    REQUEST: "EDIT_USER_REQUEST",
    SUCCESS: "EDIT_USER_SUCCESS",
    FAILURE: "EDIT_USER_FAILURE"
};

export interface EditUserParams {
    advertiser_user_id: number;
    is_admin: boolean;
}

export const editUser = (params: EditUserParams): Action => ({
    type: EDIT_USER.REQUEST,
    payload: params
});

export const editUserSuccess = (editedUser: User): Action => ({
    type: EDIT_USER.SUCCESS,
    payload: editedUser
});

export const editUserFailure = (error: string): Action => ({
    type: EDIT_USER.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const DELETE_USER = {
    REQUEST: "DELETE_USER_REQUEST",
    SUCCESS: "DELETE_USER_SUCCESS",
    FAILURE: "DELETE_USER_FAILURE"
};

export interface DeleteUserParams {
    advertiser_user_id: number;
}

export const deleteUser = (params: DeleteUserParams): Action => ({
    type: DELETE_USER.REQUEST,
    payload: params
});

export const deleteUserSuccess = (id: number): Action => ({
    type: DELETE_USER.SUCCESS,
    payload: id
});

export const deleteUserFailure = (error: string): Action => ({
    type: DELETE_USER.FAILURE,
    payload: { error }
});

// -------------------------------------------------------------------

export const USERS_INVITE_MODAL = "USERS_INVITE_MODAL";

export const usersInviteModal = (): Action => ({
    type: USERS_INVITE_MODAL,
    payload: null
});

// -------------------------------------------------------------------

export const USERS_EDIT_MODAL = "USERS_EDIT_MODAL";

export const usersEditModal = (currentUser: User): Action => ({
    type: USERS_EDIT_MODAL,
    payload: { currentUser }
});
