import {
    PayloadAction,
    createAction,
    createEntityAdapter,
    createSlice
} from "@reduxjs/toolkit";
import { Challenge } from "../types/challenge/Challenge";
import { GlobalState } from "../reducers";
import * as scripts from "../scripts/slice";
import * as creatives from "../creatives/slice";

const challengesAdapter = createEntityAdapter<Challenge>();

const challengesSlice = createSlice({
    name: "challenges",
    initialState: challengesAdapter.getInitialState(),
    reducers: {
        fetchSuccess: (state, action: PayloadAction<Challenge[]>) => {
            challengesAdapter.setAll(state, action.payload);
        }
    },
    extraReducers: builder => {
        builder
            .addCase(
                scripts.fetchSuccess,
                (
                    state,
                    action: PayloadAction<
                        scripts.ScriptAssetNormalizedAction<number[]>
                    >
                ) => {
                    challengesAdapter.updateOne(state, {
                        id: action.payload.openBuyId,
                        changes: {
                            id: action.payload.openBuyId,
                            scripts: action.payload.normalized.result
                        }
                    });
                }
            )
            .addCase(
                creatives.fetchSuccess,
                (
                    state,
                    action: PayloadAction<
                        creatives.CreativesMediaNormalizedAction
                    >
                ) => {
                    challengesAdapter.updateOne(state, {
                        id: action.payload.openBuyId,
                        changes: {
                            id: action.payload.openBuyId,
                            creatives: action.payload.normalized.result
                        }
                    });
                }
            )
            .addCase(
                scripts.createSuccess,
                (
                    state,
                    action: PayloadAction<
                        scripts.ScriptAssetNormalizedAction<number>
                    >
                ) => {
                    const openBuyId = action.payload.openBuyId;
                    const existingScripts = state.entities[openBuyId]?.scripts;
                    if (existingScripts)
                        challengesAdapter.updateOne(state, {
                            id: openBuyId,
                            changes: {
                                id: openBuyId,
                                scripts: [
                                    ...existingScripts,
                                    action.payload.normalized.result
                                ]
                            }
                        });
                }
            );
    }
});

const challengesReducer = challengesSlice.reducer;

export const { fetchSuccess } = challengesSlice.actions;
export const fetch = createAction<{ onRootPage?: boolean }>("challenges/fetch");

export const { selectAll, selectById } = challengesAdapter.getSelectors(
    (state: GlobalState) => state.entities.challenges
);

export default challengesReducer;
