import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";
import createSagaMiddleware from "@redux-saga/core";
import rootSaga from "./sagas";

// https://github.com/supasate/connected-react-router
export const history = createBrowserHistory();

// https://redux-saga.js.org/docs/introduction/BeginnerTutorial.html
const sagaMiddleware = createSagaMiddleware();

export default function configureStore(preloadedState: any) {
    const store = createStore(
        createRootReducer(history),
        // preloadedState,
        applyMiddleware(routerMiddleware(history), sagaMiddleware)
    );
    sagaMiddleware.run(rootSaga);
    return store;
}
