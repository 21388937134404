import * as serviceWorker from "./serviceWorker";
import configureStore from "./configureStore";
import { render } from "react-dom";
import App from "./App";
import React from "react";

const store = configureStore(null);
(window as any).store = store;
render(<App store={store} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
