import React, { useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { ResetEnum } from "./reducer";
import { sendResetPassword, SendResetPasswordParams } from "./actions";
import styled from "styled-components/macro";
import { AuthButton, InputBlock } from "../components/styledComponents";
import AuthModal from "../components/ResponseModal";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface Props {
    sendResetPassword: (
        sendResetPasswordParams: SendResetPasswordParams
    ) => void;
    resetStatus: ResetEnum;
}

const ResetPassword = ({ sendResetPassword, resetStatus }: Props) => {
    const [email, setEmail] = useState("");

    return (
        <Main>
            <h2>Reset your password</h2>
            <InputBlock
                value={email}
                placeholder="Email"
                type="email"
                onChange={e => setEmail(e.currentTarget.value)}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        sendResetPassword({ email });
                    }
                }}
            />
            <AuthButton onClick={() => sendResetPassword({ email })}>
                Submit
            </AuthButton>
            {resetStatus === ResetEnum.failure && (
                <AuthModal error={"Reset failed. Please refresh."} />
            )}
            {resetStatus === ResetEnum.success && (
                <AuthModal
                    message={"Check your inbox for further instructions."}
                />
            )}
        </Main>
    );
};

const mapStateToProps = (state: GlobalState) => ({
    resetStatus: state.auth.resetStatus
});

const mapDispatchToProps = (dispatch: any) => ({
    sendResetPassword: (sendResetPasswordParams: SendResetPasswordParams) => {
        dispatch(sendResetPassword(sendResetPasswordParams));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
