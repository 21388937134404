import React, { MutableRefObject, ReactNode, useEffect, useRef } from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div<{
    position: string;
    isOpen: boolean;
    zIndex: number | string;
}>`
    display: ${props => (props.isOpen ? "flex" : "none")};
    position: absolute;
    z-index: ${props => props.zIndex};
    box-shadow: -0.5px 0.6px 0.8px hsl(0deg 0% 79% / 0.26),
        -1.2px 1.4px 1.8px -1.2px hsl(0deg 0% 79% / 0.23),
        -3.1px 3.8px 4.7px -2.4px hsl(0deg 0% 79% / 0.19),
        -8.1px 9.9px 12.4px -3.5px hsl(0deg 0% 79% / 0.15);
    ${props => props.position}
`;
interface Props {
    containerRef?: any;
    close: () => void;
    isOpen: boolean;
    position: string;
    children: ReactNode;
    className?: string;
    controlRef: any;
    persist?: boolean;
    zIndex?: string | number;
}

const Tooltip = ({
    close,
    isOpen,
    position,
    children,
    className,
    controlRef,
    zIndex = "auto"
}: Props) => {
    const ref = useRef() as MutableRefObject<HTMLDivElement>;
    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (
                ref.current &&
                !ref.current.contains(event.target as Node) &&
                !controlRef.current.contains(event.target as Node)
            ) {
                close();
            }
        };
        if (isOpen) {
            document.addEventListener("click", handleClickOutside, true);
        }
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [ref, controlRef, close, isOpen]);
    if (isOpen) {
        return (
            <Wrapper
                className={className}
                ref={ref}
                isOpen={isOpen}
                position={position}
                zIndex={zIndex}
            >
                {children}
            </Wrapper>
        );
    }
    return null;
};

export default Tooltip;
