export const colors = {
    linkBlue: "#0090FF",
    secondaryColor: "#0079F5",
    lightGray: "#FAFAFA",
    primaryGray: "#909A9C",
    mediumGray: "#E8E8E8",
    darkGray: "#94979B",
    brightRed: "#FF2056",
    brightRedHover: "#E72856",
    brightRedTransparent: "#FF2056B3",
    brightPink: "#FF438F",
    indicatorGreen: "#1AD264",
    indicatorYellow: "#EFCD1D",
    secondaryGreen: "#009330",
    tertiaryGreen: "#4DE77F",
    indicatorRed: "#FF295D",
    lightTeal: "#f8fbfc",
    mediumTeal: "#E0EEF2",
    black: "#000000",
    white: "#FFFFFF",
    modalShade: "rgba(0, 0, 0, 0.7)",
    neutral1000: "#00081C",
    neutral900: "#273143",
    neutral700: "#505A6E",
    neutral500: "#9AA9C3",
    neutral300: "#D2DBE8",
    neutral100: "#F9FAFC",
    neutral0: "#FFFFFF",
    primary900: "#4952D0",
    primary700: "#555FFF",
    primary500: "#9CAEFC",
    primary300: "#DCE6FF",
    primary100: "#F0F4FF",
    error100: "#FEE8F1",
    error900: "#F91974",
    success900: "#00BF56",
    success700: "#00DE49",
    warning900: "#FF760D",
    warning100: "#FFF1E6",
    pending900: "#C0A200",
    pending100: "#FFF0A1"
};

export const rebrandColors = {
    "neutral-1000": "#00081C",
    "neutral-900": "#273143",
    "neutral-700": "#505A6E",
    "neutral-500": "#9AA9C3",
    "neutral-300": "#D2DBE8",
    "neutral-100": "#F9FAFC",
    "neutral-0": "#FFFFFF",
    "primary-900": "#4952D0",
    "primary-700": "#555FFF",
    "primary-500": "#9CAEFC",
    "primary-300": "#DCE6FF",
    "primary-100": "#F0F4FF",
    "error-900": "#F91974"
};

export const container = `
    background-color: white;
    border-radius: 6px;
    border: 1px solid ${colors.neutral300};
`;
