import React, { useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { User } from "../dashboard/Profile";
import {
    deleteUser,
    DeleteUserParams,
    editUser,
    EditUserParams
} from "./actions";
import styled from "styled-components/macro";
import Modal, { clearModal } from "../components/Modal";
import { ClearModalButton, ModalButton } from "../components/styledComponents";

const Main = styled.div`
    padding: 0 30px;
    display: flex;
    flex-direction: column;
`;

const Name = styled.div`
    margin-left: 15px;
    margin-bottom: 15px;
`;

const Label = styled.label`
    font-size: 14px;
    margin-left: 15px;
    user-select: none;
`;

const Checkbox = styled.input`
    margin-left: 6px;
`;

const ButtonContainer = styled.div`
    margin: 15px 0 30px auto;
    display: flex;
    align-items: center;
`;

interface Props {
    currentUser: User | null;
    editUser: (params: EditUserParams) => void;
    deleteUser: (params: DeleteUserParams) => void;
    clearModal: () => void;
}

const EditModal = ({
    currentUser,
    editUser,
    deleteUser,
    clearModal
}: Props) => {
    const [admin, setAdmin] = useState(!!currentUser && currentUser.is_admin);
    const [deleteScreen, setDeleteScreen] = useState(false);

    function renderName(user: User) {
        if (user.firstname && user.lastname) {
            return `${user.firstname} ${user.lastname}`;
        } else if (user.firstname) {
            return user.firstname;
        } else if (user.lastname) {
            return user.lastname;
        } else {
            return user.email;
        }
    }

    if (currentUser && deleteScreen) {
        return (
            <Modal>
                <Main>
                    <h2>Delete User?</h2>
                    <div>
                        Are you sure you want to delete this user? This can't be
                        undone.
                    </div>
                    <ButtonContainer>
                        <ClearModalButton
                            onClick={() => setDeleteScreen(false)}
                        >
                            Cancel
                        </ClearModalButton>
                        <ModalButton
                            onClick={() =>
                                deleteUser({
                                    advertiser_user_id: currentUser.id
                                })
                            }
                        >
                            Delete
                        </ModalButton>
                    </ButtonContainer>
                </Main>
            </Modal>
        );
    } else if (currentUser) {
        return (
            <Modal>
                <Main>
                    <h2>Edit User</h2>
                    <Name>{renderName(currentUser)}</Name>
                    <Label>
                        Make admin?
                        <Checkbox
                            type="checkbox"
                            checked={admin}
                            onChange={() =>
                                admin ? setAdmin(false) : setAdmin(true)
                            }
                        />
                    </Label>
                    <ButtonContainer>
                        {!currentUser.is_admin && (
                            <ClearModalButton
                                onClick={() => setDeleteScreen(true)}
                            >
                                Delete
                            </ClearModalButton>
                        )}
                        <ModalButton
                            onClick={() => {
                                editUser({
                                    advertiser_user_id: currentUser.id,
                                    is_admin: admin
                                });
                            }}
                        >
                            Update
                        </ModalButton>
                    </ButtonContainer>
                </Main>
            </Modal>
        );
    } else {
        clearModal();
        return null;
    }
};

const mapStateToProps = (state: GlobalState) => ({
    currentUser: state.users.currentUser
});

const mapDispatchToProps = (dispatch: any) => ({
    editUser: (params: EditUserParams) => dispatch(editUser(params)),
    deleteUser: (params: DeleteUserParams) => dispatch(deleteUser(params)),
    clearModal: () => dispatch(clearModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModal);
