import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../constants";
import { FlexCentered } from "../../components/commonStyles";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { deleteAsset, selectById } from "../slice";
import ConfirmModal from "../../components/ConfirmModal";

const Main = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 18px;
`;

const Video = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const MediaPreview = styled.div`
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.08));

    &:before {
        content: "";
        display: block;
        height: 0;
        width: 0;
        padding-bottom: calc(16 / 9 * 100%);
    }
`;

const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const RemoveContainer = styled.div`
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: ${colors.neutral0};
    width: 26px;
    height: 26px;
    border-radius: 18px;
    user-select: none;
    cursor: pointer;

    ${FlexCentered};
`;

const RemoveIcon = styled.img``;

interface Props {
    assetId: number;
    scriptId: number;
}

const UploadedAssetItem = ({ assetId, scriptId }: Props) => {
    const dispatch = useDispatch();
    const [deleteModal, toggleDeleteModal] = useState(false);
    const asset = useSelector((state: GlobalState) =>
        selectById(state, assetId)
    );

    const handleDelete = () => {
        dispatch(deleteAsset({ assetId, scriptId }));
        toggleDeleteModal(false);
    };

    if (!asset) return null;

    return (
        <MediaPreview key={asset.url}>
            {asset.type === "video" ? (
                <Video src={asset.url} controls />
            ) : (
                <Image src={asset.url} />
            )}
            <RemoveContainer onClick={() => toggleDeleteModal(true)}>
                <RemoveIcon src="/close.svg" />
            </RemoveContainer>
            {deleteModal && (
                <ConfirmModal
                    closeModal={() => toggleDeleteModal(false)}
                    title="Delete Asset"
                    text="Are you sure you want to delete this asset?"
                    onConfirm={handleDelete}
                    buttonText="Delete"
                    displayCancel
                />
            )}
        </MediaPreview>
    );
};
export default UploadedAssetItem;
