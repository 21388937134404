import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { GlobalState } from "../../reducers";
import { getAllMessageEvents } from "../selectors";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { colors } from "../../constants";
import { FlexCentered } from "../../components/commonStyles";
import moment from "moment";
import { Event } from "../../types/Event";

const Main = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 20px 24px;
`;

const MessageList = styled.div`
    display: flex;
    flex-direction: column;

    & > * ~ * {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid ${colors.neutral300};
    }
`;

const Message = styled.div`
    display: flex;
    align-items: flex-start;
    font-family: Poppins;
    gap: 10px;
`;

const Icon = styled.div<{ $isAdvertiser: boolean }>`
    ${FlexCentered};
    width: 23px;
    height: 23px;
    overflow: hidden;
    border-radius: 50%;
    background-color: ${props =>
        props.$isAdvertiser ? colors.indicatorGreen : colors.primary700};
    color: ${colors.neutral0};

    font-size: 10px;
    font-weight: 500;
`;

const MessageHeader = styled.div`
    font-size: 12px;
    line-height: 23px;
    font-weight: 700;
    color: ${colors.neutral500};
    margin-bottom: 6.5px;
    display: flex;
    justify-content: space-between;
`;

const TimeStamp = styled.div`
    font-size: 10px;
    font-weight: 500;
`;

const MessageBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    p {
        margin-block-end: 8px;
    }
`;

interface Props {
    creativeId: number;
}

const CreativeRevisionHistory = ({ creativeId }: Props) => {
    const messages = useSelector((state: GlobalState) =>
        getAllMessageEvents(state, creativeId)
    );

    if (!messages) return null;

    const renderEvent = (message: Event) => {
        switch (message.eventName) {
            case "open_buy_submission_advertiser_revision_request":
                return {
                    isAdvertiser: true,
                    message: message.eventExtra.advertiserMessage
                };
            case "open_buy_submission_advertiser_review_request":
                return {
                    isAdvertiser: false,
                    message: message.eventExtra.advertiserReviewRequest
                };
            case "open_buy_submission_advertiser_rejection":
                return {
                    isAdvertiser: true,
                    message: message.eventExtra.advertiserMessage
                };
            case "open_buy_submission_advertiser_approval":
                return {
                    isAdvertiser: true,
                    message:
                        "Approved on " + moment(message.createdAt).format("LL")
                };
        }
    };

    return (
        <Main>
            <MessageList>
                {messages.map(message => {
                    const isAdvertiser = renderEvent(message).isAdvertiser;
                    const body = renderEvent(message).message;

                    return (
                        <Message key={message.id}>
                            <Icon $isAdvertiser={isAdvertiser}>
                                {isAdvertiser ? "A" : "LI"}
                            </Icon>
                            <MessageBody>
                                <MessageHeader>
                                    {isAdvertiser
                                        ? "YOUR MESSAGE"
                                        : "MESSAGE FROM LIFTOFF INFLUENCE"}

                                    <TimeStamp>
                                        {moment(message.createdAt).fromNow()}
                                    </TimeStamp>
                                </MessageHeader>
                                <Markdown
                                    linkTarget="_blank"
                                    remarkPlugins={[remarkGfm]}
                                >
                                    {body || "No Feedback"}
                                </Markdown>
                            </MessageBody>
                        </Message>
                    );
                })}
            </MessageList>
        </Main>
    );
};
export default CreativeRevisionHistory;
