import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { colors } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../reducers";
import { fetch, selectById } from "../slice";
import RuleRow from "./RuleRow";

const Main = styled.div`
    font-family: Poppins;
`;

interface Props {
    ruleSetId: number;
    handleAddRule: (rule: string, remove?: boolean) => void;
    actionable: boolean;
}

const RuleSetList = ({ ruleSetId, handleAddRule, actionable }: Props) => {
    const dispatch = useDispatch();
    const ruleSet = useSelector((state: GlobalState) =>
        selectById(state, ruleSetId)
    );

    useEffect(() => {
        if (!ruleSet) {
            dispatch(fetch(ruleSetId));
        }
    }, []);

    if (!ruleSet) return null;
    const dos = ruleSet.Rules.filter(rule => rule.icon === "check");
    const donts = ruleSet.Rules.filter(rule => rule.icon === "x");
    const generals = ruleSet.Rules.filter(
        rule => rule.icon !== "x" && rule.icon !== "check"
    );

    return (
        <Main>
            {dos.length !== 0 &&
                dos.map(rule => (
                    <RuleRow
                        handleAddRule={handleAddRule}
                        key={rule.id}
                        icon="✅"
                        title={""}
                        text={rule.rule}
                        actionable={actionable}
                    />
                ))}
            {donts.length !== 0 &&
                donts.map(rule => (
                    <RuleRow
                        handleAddRule={handleAddRule}
                        key={rule.id}
                        icon="🚫"
                        title="DO NOT"
                        text={rule.rule}
                        actionable={actionable}
                    />
                ))}
            {generals.length !== 0 &&
                generals.map(rule => (
                    <RuleRow
                        handleAddRule={handleAddRule}
                        key={rule.id}
                        icon="👍"
                        title={`${rule.title} - `}
                        text={rule.rule}
                        actionable={actionable}
                    />
                ))}
        </Main>
    );
};
export default RuleSetList;
