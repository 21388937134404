import { push } from "connected-react-router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutFirebase } from "./FirebaseConfig";

export const logoutFunctions = () => {
    localStorage.clear();
    logoutFirebase();
};

const Logout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        logoutFunctions();
        dispatch(push("/login"));
    }, []);

    return null;
};

export default Logout;
