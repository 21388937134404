import { Action } from "../types";
import { LOGIN_USER, SEND_RESET_PASSWORD, SET_PASSWORD } from "./actions";
import { MODAL_CLEAR } from "../components/Modal";

export enum ResetEnum {
    pending,
    success,
    failure
}

export interface AuthState {
    userid: number | null;
    error: string | null;
    resetStatus: ResetEnum;
}

const initialAuthState = {
    userid: null,
    error: null,
    resetStatus: ResetEnum.pending
};

const reducer = (state: AuthState = initialAuthState, action: Action) => {
    switch (action.type) {
        case MODAL_CLEAR:
            return { ...state, error: null, resetStatus: ResetEnum.pending };
        case LOGIN_USER.SUCCESS:
            return { ...state, ...action.payload };
        case LOGIN_USER.FAILURE:
            return { ...state, ...action.payload };
        case SET_PASSWORD.SUCCESS:
            return { ...state, ...action.payload };
        case SET_PASSWORD.FAILURE:
            return { ...state, ...action.payload };
        case SEND_RESET_PASSWORD.SUCCESS:
            return { ...state, resetStatus: ResetEnum.success };
        case SEND_RESET_PASSWORD.FAILURE:
            return { ...state, resetStatus: ResetEnum.failure };
        default:
            return state;
    }
};

export default reducer;
