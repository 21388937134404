import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    GET_ADVERTISER_USER_DATA,
    GET_ADVERTISERS_INDEX,
    GET_SUPPORT_EMAIL,
    getAdvertisersIndexSuccess,
    getAdvertiserUserDataFailure,
    getAdvertiserUserDataSuccess,
    getSupportEmailFailure,
    getSupportEmailSuccess,
    LOG_OUT,
    LOGIN_AS_ADVERTISER,
    SUBMIT_EVENT
} from "./actions";
import { push } from "connected-react-router";
import { setToken, get, post } from "../Api";
import { Action } from "../types";
import { onFirebaseAuthStateChanged } from "../FirebaseConfig";
import { logoutFunctions } from "../Logout";
import { User } from "./Profile";

function* getAdvertiserUserDataRequest() {
    try {
        setToken();

        // This is a workaround to allow users to be authenticated without having firebaseToken set
        const firebaseToken = localStorage.getItem("firebaseToken");
        if (firebaseToken !== "null") {
            yield call(onFirebaseAuthStateChanged);
        }

        const { data, status } = yield call(
            get,
            "/dashboard/api/get_advertiser_user_data",
            {}
        );

        if (status === 200) {
            if (data.user === null) {
                (data.user as User) = {
                    advertiser_id: data.advertiser.id,
                    email: "",
                    firstname: "",
                    lastname: "",
                    has_claimed: false, 
                    is_admin: true,
                    id: 0,
                };
            }
            yield put(getAdvertiserUserDataSuccess(data));
        } else {
            yield put(getAdvertiserUserDataFailure("Fetch profile failure"));
        }
    } catch (error) {
        yield put(getAdvertiserUserDataFailure("Fetch profile failure"));
    }
}

function* getSupportEmailRequest() {
    try {
        const { data, status } = yield call(
            get,
            "/dashboard/api/get_support_email",
            {}
        );

        if (status === 200) {
            yield put(getSupportEmailSuccess(data));
        } else {
            yield put(getSupportEmailFailure("Fetch support email failure"));
        }
    } catch (error) {
        yield put(getSupportEmailFailure("Fetch support email failure"));
    }
}

function* submitEventRequest({ payload }: Action) {
    try {
        yield call(post, "/dashboard/api/submit_event", payload);
    } catch (error) {}
}

function* loginAsAdvertiserRequest({ payload }: Action) {
    try {
        const { data, status } = yield call(
            post,
            "/dashboard/api/login_as_advertiser",
            payload
        );

        if (status === 200) {
            localStorage.clear();

            localStorage.setItem("token", data.token);
            localStorage.setItem("firebaseToken", "firebase won't work");
            localStorage.setItem("userid", data.advertiser_user_id);

            window.location.reload();
        } else {
            window.alert("Something went wrong. Please refresh.");
        }
    } catch (error) {
        window.alert("Something went wrong. Please refresh.");
    }
}

function* getAdvertisersIndexRequest() {
    try {
        const { data, status } = yield call(
            get,
            "/dashboard/api/get_advertisers_index",
            {}
        );

        if (status === 200) {
            yield put(getAdvertisersIndexSuccess(data));
        } else {
            window.alert("Something went wrong. Please refresh.");
        }
    } catch (error) {
        window.alert("Something went wrong. Please refresh.");
    }
}

function* logout() {
    yield call(logoutFunctions);

    yield put(push("/login"));
}

export default function* profileSaga() {
    yield all([
        takeEvery(
            GET_ADVERTISER_USER_DATA.REQUEST,
            getAdvertiserUserDataRequest
        ),
        takeEvery(GET_SUPPORT_EMAIL.REQUEST, getSupportEmailRequest),
        takeEvery(SUBMIT_EVENT, submitEventRequest),
        takeEvery(LOG_OUT, logout),
        takeEvery(LOGIN_AS_ADVERTISER, loginAsAdvertiserRequest),
        takeEvery(GET_ADVERTISERS_INDEX.REQUEST, getAdvertisersIndexRequest)
    ]);
}
