import { all, call, put, takeLeading } from "redux-saga/effects";
import { get } from "../Api";
import { fetch, fetchSuccess } from "./slice";
import { PayloadAction } from "@reduxjs/toolkit";

function* fetchRuleSetRequest(action: PayloadAction<number>) {
    try {
        const { data } = yield call(
            get,
            `/dashboard/api/ruleSet/${action.payload}`,
            {}
        );

        yield put(fetchSuccess(data.data));
    } catch (error) {}
}

export default function* ruleSetsSaga() {
    yield all([takeLeading(fetch, fetchRuleSetRequest)]);
}
