import React from "react";
import styled from "styled-components";
import { colors } from "../constants";
import { FlexVerticallyCentered } from "../components/commonStyles";
import { useSelector } from "react-redux";
import { GlobalState } from "../reducers";
import { selectById } from "./slice";
import ChallengeStatusBadge from "./components/ChallengeStatusBadge";
import Scripts from "../scripts";
import ChallengeProgressNav from "./components/ChallengeProgressNav";
import { Route, Switch } from "react-router";
import Creatives from "../creatives";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 46px;
    font-family: Poppins;
    flex: 1;
`;

const Header = styled.div`
    ${FlexVerticallyCentered}

    & > * ~ * {
        margin-left: 16px;
    }
`;

const Title = styled.div`
    color: ${colors.neutral1000};
    font-size: 36px;
    font-weight: 500;
`;

interface Props {
    openBuyId: number;
}

const Challenge = ({ openBuyId }: Props) => {
    const challengeInfo = useSelector((state: GlobalState) =>
        selectById(state, openBuyId)
    );

    if (!challengeInfo) return null;
    return (
        <Main>
            <Header>
                <Title>{challengeInfo.name}</Title>
                <ChallengeStatusBadge status={challengeInfo.status} />
            </Header>
            <ChallengeProgressNav openBuyId={openBuyId} />
            <Switch>
                <Route
                    path="/challenge/:openBuyId/creatives/:creativeId?"
                    render={({ match }) => {
                        const creativeId = match.params.creativeId
                            ? parseInt(match.params.creativeId)
                            : undefined;
                        return (
                            <Creatives
                                creativeId={creativeId}
                                openBuyId={openBuyId}
                            />
                        );
                    }}
                />
                <Route
                    path="/challenge/:openBuyId/concepts"
                    render={() => <Scripts openBuyId={openBuyId} />}
                />
                <Route
                    exact
                    path="/challenge/:openBuyId"
                    render={() => <Scripts openBuyId={openBuyId} />}
                />
            </Switch>
        </Main>
    );
};
export default Challenge;
