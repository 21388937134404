import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../reducers";
import { getAdvertiserAPIToken } from "./actions";
// @ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components/macro";
import { colors, container } from "../constants";
import { CopyButton, CopyInput } from "../components/styledComponents";
import RocketLoader from "../components/RocketLoader";

const Container = styled.div`
    padding: 25px 20px 30px;
    ${container}
`;

const Title = styled.div`
    margin-bottom: 15px;
`;

const TokenContainer = styled.div`
    display: flex;
    margin-bottom: 30px;
`;

const Description = styled.div`
    margin-bottom: 15px;
    font-size: 13px;
`;

const Link = styled.a`
    font-size: 13px;
    color: ${colors.linkBlue};
`;

interface Props {
    token?: string;
    getAdvertiserAPIToken: () => void;
}

const ReportingAPI = ({ token, getAdvertiserAPIToken }: Props) => {
    useEffect(() => {
        getAdvertiserAPIToken();
    });

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 2000);
        }
    }, [copied]);

    if (token) {
        return (
            <div>
                <h2>API</h2>
                <Container>
                    <Title>Token</Title>
                    <TokenContainer>
                        <CopyInput value={token} readOnly />
                        <CopyToClipboard
                            text={token}
                            onCopy={() => setCopied(true)}
                        >
                            <CopyButton>
                                {copied ? "Copied!" : "Copy"}
                            </CopyButton>
                        </CopyToClipboard>
                    </TokenContainer>

                    <Title>Docs</Title>
                    <Description>
                        To get started, please reference our docs here:
                    </Description>
                    <Link
                        href="https://medium.com/@jjmaxwell4/jetfuel-api-integration-f6a5f9b8067b"
                        target="_blank"
                    >
                        JetFuel API Integration
                    </Link>
                </Container>
            </div>
        );
    } else {
        return (
            <div>
                <h2>API</h2>
                <Container>
                    <RocketLoader />
                </Container>
            </div>
        );
    }
};

const mapStateToProps = (state: GlobalState) => ({
    token: state.api.token
});

const mapDispatchToProps = (dispatch: any) => ({
    getAdvertiserAPIToken: () => dispatch(getAdvertiserAPIToken())
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportingAPI);
