import React from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styled from "styled-components/macro";
import { colors } from "../../constants";

const ScriptTextWrap = styled.div`
    background-color: ${colors.neutral100};
    padding: 20px 16px;
    border-radius: 8px;
    overflow-y: auto;
    text-align: initial;
    overflow-y: auto;
    p {
        margin-bottom: 8px;
    }
`;
const MessagePreview = ({
    text,
    className
}: {
    text?: string;
    className?: string;
}) => {
    return (
        <ScriptTextWrap className={className}>
            <Markdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                {text || "There are no text in this message"}
            </Markdown>
        </ScriptTextWrap>
    );
};

export default MessagePreview;
